import { FETCH_LOCATION, FETCH_LOCATION_FROM_SURVEY } from "../actions/camera_inspection.action"

export default function (state = [], action) {
    switch (action.type) {
    case FETCH_LOCATION:
      return [action.payload.data]
    }
    return state
}

export function LocationFromSurvey(state = [], action) {
  switch (action.type) {
  case FETCH_LOCATION_FROM_SURVEY:
    return [action.payload.data]
  }
  return state
}