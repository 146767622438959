import React, { Component, useRef } from "react";
import { connect } from "react-redux";
import {
  fetchAllQuestionsByCategory,
  fetchCategories,
  fetchInspectionDays,
  fetchLocation,
  fetchSurveyDateList,
  loadSurveyDataByEmpId,
  saveSurveyData,
} from "../../../actions/camera_inspection.action";

import auth from "../../authentificate/auth";
import CameraPMForm from "./PMForm";
import Swal from "sweetalert2";
import { change} from "redux-form";
import moment from "moment";
import { STANDARD_DATETIME_FORMAT } from "../../../constants/app_constants";
import Header from "../../header.jsx";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
export default class CameraPMInspection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryId: -1,
      locationId: 0,
      catId: -1,
      questionsList: [],
      user: auth.getLoggedUserDetailsFromLocal(),
      editorState: "<p></p>",
      editorState2: "",
      err: {},
      questValidation: [],
      count: 0,
      display: false,
      InspectionDays: 0,
      questionId: [],
      selectedDate: null,
      addNew: false,
      handleReset: false,
      location: null,
      category:null,
      datetime: null
    };
  }

  async componentDidMount() {
    await this.props.loadCategories();
    await this.props.loadLocation();
    // await this.props.loadDateList(this.state.user?.UserName);

  }

  handleFilterLocationChange = async (doc) => {
    const { categoryId, selectedDate, user, locationId } = this.state;
    const { surveyData } = this.props;
    this.props.loadDateList(
      this.state.user?.UserName,
      doc.value,
      categoryId || null
    );
    this.props.changeFormField("camera_pm_inspection_form", "Date", {
      label: null,
      value: null,
    });
    if (locationId == 0) {
      await this.props.loadQuestions(
        this.state.categoryId,
        this.state.user.EmployeeId,
        doc.value
      );
    }
    let sd = [];
    if (categoryId == 0) {
      sd =
        surveyData?.length > 0 &&
        surveyData.filter((item) => item.locationId == doc.value);
    } else {
      sd =
        surveyData?.length > 0 &&
        surveyData?.filter(
          (item) => item.catId == categoryId && item.locationId == doc.value
        );
    }
    const html = sd && sd[0]?.questions[0]?.Comment;
    this.setState({
      questionsList: sd || [],
      editorState: html || "<p></p>",
      addNew: false,
    });
    // console.log(sd, "inside location");
    if (doc && doc.value) {
      this.state.err.locationErr = "";
      if (selectedDate != null) {
        await this.props.loadSurveyData(
          user?.EmployeeId,
          doc.value,
          categoryId,
          selectedDate
        );
        this.setState({ questionsList: this.props.surveyData });
      }
      this.setState({
        locationId: doc.value,
        location:doc,
        err: this.state.err,
        handleReset: false,
        selectedDate: null,
      });
    } else {
      this.setState({ locationId: 0, location:null });
    }
  };

  handleFilterCategoryChange = async (doc) => {
    if (this.state.locationId == 0) {
      this.state.err.locationErr = "Please select location";
      this.setState({ err: this.state.err, categoryId: doc.value });
      return 0;
    }
    this.props.changeFormField("camera_pm_inspection_form", "Date", {
      label: null,
      value: null,
    });
    await this.props.loadQuestions(
      doc.value,
      this.state.user.EmployeeId,
      this.state.locationId
    );
    const { surveyData, questionList } = this.props;
    const { locationId, selectedDate, user } = this.state;
    this.props.loadDateList(
      this.state.user?.UserName,
      locationId || null,
      doc.value
    );

    let sd = [];
    if (doc.value == 0) {
      sd =
        surveyData?.length > 0 &&
        surveyData.filter((item) => item.locationId == locationId);
    } else {
      sd =
        surveyData?.length > 0 &&
        surveyData.filter(
          (item) => item.catId == doc.value && item.locationId == locationId
        );
    }
    const html = sd && sd[0]?.questions[0]?.Comment;
    // console.log(sd, "handle category----");
    this.setState({
      questionsList: sd || [],
      editorState: html || "<p></p>",
      InspectionDays: doc.InspectionDays,
      addNew: false,
      handleReset: false,
      selectedDate: null,
    });
    if (doc && doc.value) {
      this.state.err.categoryErr = "";
      const c = this.state.count + 1;
      this.setState({ categoryId: doc.value, err: this.state.err, count: c, category: doc });
      if (selectedDate != null) {
        await this.props.loadSurveyData(
          user?.EmployeeId,
          locationId,
          doc.value,
          selectedDate
        );
        this.setState({ questionsList: this.props.surveyData });
      }
    } else {
      this.setState({ categoryId: doc.value, count: this.state.count + 1, category: null });
    }
  };
  validateData = (catId, questId, answer) => {
    // this.state.questValidation.push({ catId, answer });
    const { questionsList, locationId } = this.state;
    // this.setState({handleReset:true})
    console.log(questionsList);
    const data = [];
    let fid = [];
    if (questionsList) {
      for (let item of questionsList) {
        const d = [];
        for (let quest of item.questions) {
          if (quest.QuestionId == questId && item.catId == catId) {
            quest.Answer = answer;
            d.push(quest);
          } else d.push(quest);
        }
        data.push({
          catId: item.catId,
          locationId: item.locationId,
          parentCat: item.parentCat,
          questions: d,
        });
      }
    }

    if (answer == 0)
      document.getElementById(`comment_${questId}`).style.display = "block";
    else if (answer == 1) {
      fid = this.state.questionId.filter((item) => item != questId);
      this.setState({ questionId: fid });
      document.getElementById(`comment_${questId}`).style.display = "none";
    }
    console.log(data, "validate data..........");
    this.setState({
      questValidation: this.state.questValidation,
      handleReset: true,
      questionsList: data,
    });
  };

  handleFilterDateChange = async (d) => {
    const { categoryId, locationId, user } = this.state;
    // console.log(d.label)
    let errors = {},
      isErr = false;
    if (locationId == 0) {
      isErr = true;
      errors.locationErr = "Please Select the location";
    }
    if (categoryId == -1) {
      isErr = true;
      errors.categoryErr = "Please Select the category";
    }
    this.setState({ err: errors });

    if (isErr) return 0;
    // console.log(d.label, '=============');
    await this.props.loadSurveyData(
      user?.EmployeeId,
      locationId,
      categoryId,
      d && d.label ? d.label : null
    );
    const { surveyData } = this.props;
    const html = surveyData && surveyData[0]?.questions[0]?.Comment;
    this.setState({
      selectedDate: d && d.label ? d.label : null,
      datetime: d,
      questionsList: surveyData,
      editorState: html || "<p></p>",
      handleReset: false,
    });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    const { questionList } = this.props;
    let { user, locationId, categoryId, editorState, err, editorState2 } =
      this.state;
    let errors = {},
      QEId = [],
      isErr = false;
    if (locationId == 0) {
      isErr = true;
      errors.locationErr = "Please Select the location";
    }
    if (categoryId == -1) {
      isErr = true;
      errors.categoryErr = "Please Select the category";
    }
    this.setState({ err: errors });

    if (isErr) return 0;

    let category = [],
      catArr = [],
      selCat = [],
      payload = null,
      willInspecShow = true;
    questionList.map((item) => {
      let quesAns = [];
      item.questions?.map((quest) => {
        let answer = `answer_${quest.Id}`;
        let comment = `comment_${quest.Id}`;
        let ans = event.target.elements[answer].value;
        let cmnt = event.target.elements[comment].value;
        if (ans && ans == 0 && cmnt == "") {
          QEId.push(quest.Id);
        }
        if (ans) {
          quesAns.push({
            questionId: quest.Id,
            answer: ans,
            questionComment: cmnt,
          });
          selCat.push(item.Id);
        } else {
          willInspecShow = false;
        }
        catArr.push(item.Id);
      });
      if (quesAns.length >= 0)
        category.push({ categoryId: item.Id, questions: quesAns });
    });
    this.setState({ handleReset: true });
    if (QEId.length > 0) {
      this.setState({ questionId: QEId });
      return;
    }

    catArr = [...new Set(catArr)];
    selCat = [...new Set(selCat)];

    if (catArr.every((item) => selCat.includes(item))) {
      payload = {
        Action: event.target.elements.action.value,
        UserName: user?.UserName,
        WorkEmail: user?.WorkEmail,
        SupervisorWorkEmail: this.props.supervisorData?.supervisorWorkEmail,
        CategoryId: categoryId,
        EmployeeId: user?.EmployeeId,
        LocationId: locationId,
        CreatedBy: user?.EmployeeId,
        UpdatedBy: user?.EmployeeId,
        Comment: editorState2 || editorState,
        Questions: category,
      };
      const response = await fetchInspectionDays(locationId, categoryId);
      if (willInspecShow && response.data != 0) {
        payload["IsSurveyCompleted"] = 1;
        payload["NextInspectionDate"] = moment(new Date(), "DD-MM-YYYY")
          .add(response.data, "days")
          .format(STANDARD_DATETIME_FORMAT);
        this.props.saveSurveyData(payload);
        Swal.fire(
          "Data saved successfully",
          `Next Re-Inpection date is ${payload["NextInspectionDate"]}`
        ).then(() => window.location.reload())
      
      } else {
        payload["IsSurveyCompleted"] = 0;
        payload["NextInspectionDate"] = null;
        this.props.saveSurveyData(payload);
        Swal.fire("Success", "Data saved successfully!").then((v) =>
          window.location.reload()
        );
      }
    }
    catArr = catArr.filter((item) => !selCat.includes(item));
    this.setState({ questValidation: catArr });
  };

  onEditorStateChange = (e) => {
    this.setState({
      editorState2: e,
    });
  };
  clearForm = () => {
    // this.state.questionsList.map((categ) => {
    //   categ.questions.map((quest) => {
    //     let yes = `yes_${quest.Id}`;
    //     let no = `no_${quest.Id}`;
    //     let comment = `comment_${quest.Id}`;
    //     const y = document.getElementById(yes);
    //     const n = document.getElementById(no);
    //     const c = document.getElementById(comment);
    //     if (y) y.checked = false;
    //     if (n) n.checked = true;
    //     if (c) {
    //       c.innerHTML = ''
    //       c.style.display = "block";
    //     }
    //   });
    // });
    // this.props.dispatch(
    //   change("camera_pm_inspection_form", "Location", { label: "", value: "" })
    // );
    // this.props.dispatch(
    //   change("camera_pm_inspection_form", "Categories", {
    //     label: "",
    //     value: "",
    //   })
    // );
    if (this.state.selectedDate) {
      this.props.changeFormField("camera_pm_inspection_form", "Date", {
        label: null,
        value: null,
      });
      this.handleFilterDateChange({ label: null, value: null });
    }
    this.setState({ questionsList: [], addNew: true, handleReset: false });
    // this.props.questionList.map((categ) => {
    //   categ.questions.map((quest) => {
    //     console.log(quest,'quest============')
    //     let yes = `yes_${quest.Id}`;
    //     let no = `no_${quest.Id}`;
    //     let comment = `comment_${quest.Id}`;
    //     const y = document.getElementById(yes);
    //     const n = document.getElementById(no);
    //     const c = document.getElementById(comment);
    //     if (y) y.checked = false;
    //     if (n) n.checked = true;
    //     console.log(n.checked, y.checked)
    //     if (c && n.checked) {
    //       c.innerHTML = ''
    //       c.style.display = "block";
    //     }
    //   });
    // });
    //  this.props.dispatch(
    //   change("camera_pm_inspection_form", "Date", { label: null, value: null })
    // );
  };

  render() {
    const { categoriesOptions, locationOptions, questionList, dateOptions } =
      this.props;
    console.log(questionList, "questionList");
    return (
      <>
        <Header />
        <Navbar bg="secondary" expand="lg">
          <Navbar.Brand href="/hr">
            <b>CCPD Camera Inspection Survey</b>
          </Navbar.Brand>
          <Navbar />
          <Nav className="mr-auto nav-link-wrap">
            <Link to="/hr" className="ml-2 mr-2">
              <span style={{ color: "black" }}>
                <b>
                  <u>Home</u>
                </b>
              </span>
            </Link>
          </Nav>
        </Navbar>
      <br/>
        <CameraPMForm
          categoriesOptions={categoriesOptions}
          locationOptions={locationOptions}
          questionList={questionList}
          handleFilterCategoryChange={this.handleFilterCategoryChange}
          handleFilterLocationChange={this.handleFilterLocationChange}
          handleSubmit={this.handleSubmit}
          editorState={this.state.editorState}
          onEditorStateChange={this.onEditorStateChange}
          err={this.state.err}
          validateData={this.validateData}
          questValidation={this.state.questValidation}
          surveyData={this.state.questionsList}
          editorRef={this.editorRef}
          questionVaildation={this.state.questionId}
          handleFilterDateChange={this.handleFilterDateChange}
          dateOptions={dateOptions}
          selectedDate={this.state.selectedDate}
          clearForm={this.clearForm}
          addNew={this.state.addNew}
          location={this.state.location}
          category={this.state.category}
          datetime={this.state.datetime}
          handleReset={this.state.handleReset}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoriesOptions: state.categoryList[0],
    locationOptions: state.locationList[0],
    questionList: state.questionsByCat[0]?.data,
    surveyData: state.surveyData[0],
    dateOptions: state.surveyDataList[0],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadCategories: () => dispatch(fetchCategories()),
    loadLocation: () => dispatch(fetchLocation()),
    loadQuestions: (id, EmployeeId, locationId) =>
      dispatch(fetchAllQuestionsByCategory(id, EmployeeId, locationId)),
    saveSurveyData: (payload) => dispatch(saveSurveyData(payload)),
    loadSurveyData: (id, locId, catId, date) =>
      dispatch(loadSurveyDataByEmpId(id, locId, catId, date)),
    changeFormField: (form, field, value) =>
      dispatch(change(form, field, value)),
    loadDateList: (username, locId, catId) =>
      dispatch(fetchSurveyDateList(username, locId, catId)),
  };
};

CameraPMInspection = connect(
  mapStateToProps,
  mapDispatchToProps
)(CameraPMInspection);
