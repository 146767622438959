import React from "react";
import { Nav, Navbar, Container, Row, Col, Table } from "react-bootstrap";
import Header from "../header.jsx";
import { Link } from "react-router-dom";
import { tempInActiveLeaveEmployeesList } from "../../actions";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import { ExportToCsv } from "export-to-csv";
import * as jsPDF from "jspdf";
import moment from "moment/moment.js";
export default class StaffSummaryListing extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      data: [],
      page: 0,
      rowperpage: 20,
      total: 0,
      orderBy: 0,
      orderDirection: "desc",
    };
  }

  generateData = (amount) => {
    var result = [];

    for (var i = 0; i < this.state.data.length; i += 1) {
      const row = this.state.data[i];
      result.push({
        Title: row.Title || "",
        FirstName: row.FirstName,
        LastName: row.LastName || "",
        CellPhone: row.CellPhone || "",
        SwornOfficer: row.SwornOfficer || "",
        Bureau: row.BureauDesc || "",
        Division: row.DivisionDesc || "",
        Unit: row.UnitDesc && row.UnitDesc?.replace("$","") || "",

      });
    }
    return result;
  };

  exportPDF = () => {
    var doc = new jsPDF({ orientation: "landscape" });
    var col = [
      "Title",
      "First Name",
      "Last Name",
      "Cell Phone",
      "Sworn Officer",
      "Bureau",
      "Division",
      "Unit"
    ];
    var rows = [];

    this.state.data.forEach((row) => {
      var temp = [
         row.Title || "",
         row.FirstName,
         row.LastName || "",
         row.CellPhone || "",
         row.SwornOfficer || "",
         row.BureauDesc || "",
         row.DivisionDesc || "",
         row.UnitDesc && row.UnitDesc?.replace("$","") || ""
      ]
      rows.push(temp);
    });

    doc.autoTable(col, rows, { startY: 10 });
    doc.save(moment(new Date().getTime()) + ".pdf");

  };

  downloadCSV = () => {
    const data = this.state.data;
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      filename: moment(new Date().getTime())+".csv",
      showLabels: true,
      showTitle: true,
      title: "Temporary Inactive Status –",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const mapped = data.map((row) => {

      return {
        Title: row.Title || "",
        FirstName: row.FirstName,
        LastName: row.LastName || "",
        CellPhone: row.CellPhone || "",
        SwornOfficer: row.SwornOfficer || "",
        Bureau: row.BureauDesc || "",
        Division: row.DivisionDesc || "",
        Unit: row.UnitDesc && row.UnitDesc?.replace("$","") || "",
      };
    });
    const exporter = new ExportToCsv(options);
    exporter.generateCsv(mapped);
  };
  getTableColumns = () => {
    const columns = [
      {
        title: "Title",
        dbColumn: "CCPD_Employees.Title",
        field: "CCPD_Employees_Title",
        dataType: "string",
        render: ({ Title }) => {
          return <span>{`${Title || ""}`}</span>;
        },
      },
      {
        title: "First Name",
        dbColumn: "CCPD_Employees.FirstName",
        field: "CCPD_Employees_FirstName",
        dataType: "string",
        render: ({ FirstName }) => {
          return <span>{`${FirstName || ""}`}</span>;
        },
      },
      {
        title: "Last Name",
        dbColumn: "CCPD_Employees.LastName",
        field: "CCPD_Employees_LastName",
        dataType: "string",
        render: ({ LastName }) => {
          return <span>{`${LastName || ""}`}</span>;
        },
      },
      {
        title: "Phone",
        dbColumn: "CCPD_Employees.CellPhone",
        field: "CCPD_Employees_CellPhone",
        dataType: "string",
        render: ({ CellPhone }) => {
          return <span>{`${CellPhone || ""}`}</span>;
        },
      },
      {
        title: "Sworn Officer",
        dbColumn: "CCPD_Employees.SwornOfficer",
        field: "CCPD_Employees_SwornOfficer",
        dataType: "string",
        render: ({ SwornOfficer }) => {
          return (
            <span>{`${SwornOfficer && SwornOfficer ? "Yes" : "No"}`}</span>
          );
        },
      },
      {
        title: "Bureau",
        dbColumn: "CCPD_Employees.BureauDesc",
        field: "CCPD_Employees_BureauDesc",
        dataType: "string",
        render: ({ BureauDesc }) => {
          return <span>{`${BureauDesc && BureauDesc?.replace("$", "") || ""}` }</span>;
        },
      },
      {
        title: "Division",
        dbColumn: "CCPD_Employees.DivisionDesc",
        field: "CCPD_Employees_DivisionDesc",
        dataType: "string",
        render: ({ DivisionDesc }) => {
          return (
            <span>{`${DivisionDesc && DivisionDesc?.replace("$", "") || ""}` }</span>
          );
        },
      },
      {
        title: "Unit",
        dbColumn: "CCPD_Employees.UnitDesc",
        field: "CCPD_Employees_UnitDesc",
        dataType: "string",
        render: ({ UnitDesc }) => {
          return (
            <span>{`${(UnitDesc && UnitDesc?.replace("$", "")) || ""}  `}</span>
          );
        },
      },
    ];
    return columns;
  };

  getHiddenTableColumns = () => {
    const columns = [];
    return columns;
  };

  tableTitle = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <i
          className="fa fa-arrow-left btn"
          onClick={() => this.props.history.goBack()}
        ></i>
        <h5 style={{ textTransform: "capitalize", marginBottom:"22px" }}>
          Temporary Inactive Status –
          <strong>
            {this.props.match.params && this.props.match.params?.reason}
          </strong>{" "}
          Leave Reason (Sworn & Non Sworn)
        </h5>
      </div>
    );
  };

  loadPreviousPage = (data) => {
    const page = this.state.page - 1;
    this.setState({ page }, () => {
      this.tableRef.current.onQueryChange();
    });
  };
  loadNextPage = (data) => {
    const page = this.state.page + 1;
    this.setState({ page }, () => {
      this.tableRef.current.onQueryChange();
    });
  };

  render() {
    return (
      <>
        <Header />
        <Navbar bg="secondary" expand="lg">
          <Navbar.Brand href="/dashboard-reports/Listing">
            <b>Dashboard</b>
          </Navbar.Brand>
          <Navbar />
          <Nav className="nav-link-wrap">
            <Link to="/dashboard-reports/Listing" className="ml-2 mr-2">
              <span style={{ color: "black" }}>
                <b>
                  <u>Temporary Inactive Status-Leave</u>
                </b>
              </span>
            </Link>
          </Nav>
        </Navbar>
        <br />
       <Container fluid style={{height:"100vh"}}>
       <MaterialTable
          title={this.tableTitle()}
          tableRef={this.tableRef}
          columns={this.getTableColumns()}
          data={(query) =>
            new Promise((resolve, reject) => {
              const payload = {
                page: this.state.page,
                rowperpage: this.state.rowperpage,
                orderBy: this.state.orderBy,
                orderDirection: this.state.orderDirection,
                reason:
                  this.props.match.params && this.props.match.params?.reason,
                searchText: query?.search,
              };
              tempInActiveLeaveEmployeesList(payload)
                .then((res) => {
                  const { data, total } = res;
                  this.setState(
                    { data: data.data, total: data.total },
                    () => {}
                  );
                  resolve({
                    data: data.data,
                    page: this.state.page,
                    totalCount: data.total,
                  });
                })
                .catch((err) => {
                  reject({ data: [], page: 0, totalCount: 0 });
                });
            })
          }
          onOrderChange={(orderBy, orderDirection) => {
            this.setState({ orderBy, orderDirection });
          }}
          options={{
            pageSize:
              this.state.rowperpage > this.state.total
                ? this.state.total
                : this.state.rowperpage,
            pageSizeOptions: [20, 50, 100],
            paginationPosition: "both",
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: "#01579b",
              color: "#FFF",
            },
            filtering: false,
            sorting: false,
            initialPage: 0,
            exportButton: true,
            exportAllData: true,
            exportCsv: (data, columns) => this.downloadCSV(),
            exportPdf: (data, columns) => this.exportPDF(),
            debounceInterval: 700,
            search: true,
          }}
          components={{
            Pagination: (props) => {
              return (
                <div style={{ float: "right" }}>
                  <TablePagination
                    count={this.state.total}
                    rowsPerPage={this.state.rowperpage}
                    rowsPerPageOptions={[20, 50, 100]}
                    page={this.state.page}
                    backIconButtonProps={{
                      "aria-label": "Previous Page",
                      onClick: this.loadPreviousPage,
                    }}
                    nextIconButtonProps={{
                      "aria-label": "Next Page",
                      onClick: this.loadNextPage,
                    }}
                    onChangeRowsPerPage={(value) =>
                      this.setState({ rowperpage: value.target.value }, () => {
                        this.tableRef.current.onQueryChange();
                      })
                    }
                  />
                </div>
              );
            },
          }}
        />
       </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { dashboardReports } = state;

  return {
    employeeList: dashboardReports.employeeList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStaffSummaryEmployeeListing: (title) =>
      dispatch(fetchStaffSummaryEmployeeListing(title)),
  };
};

StaffSummaryListing = connect(
  mapStateToProps,
  mapDispatchToProps
)(StaffSummaryListing);
