import _ from "lodash";
import {
  STAFF_SUMMARY_REPORT,
  TEMP_INACTIVE_REPORT,
  REPORT_EMOLOYEE_LIST,
  FETCH_INACTIVE_STATUS_COUNT
} from "../actions";
const initialState = {
  staffSummary: [],
  tempinactiveLeave: [],
  employeeList: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case STAFF_SUMMARY_REPORT:
      return { ...state, staffSummary: action.payload.data };
  }
  switch (action.type) {
    case TEMP_INACTIVE_REPORT:
      return { ...state, tempinactiveLeave: action.payload.data };
  }
  switch (action.type) {
    case REPORT_EMOLOYEE_LIST:
      return { ...state, employeeList: action.payload.data };
  }
  switch (action.type) {
    case FETCH_INACTIVE_STATUS_COUNT:
      return { ...state, inactiveStatusCount: action.payload.data };
  }
  return state;
}
