/**
 * list.js file
 */
import React from "react";
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import {Nav, Navbar, NavDropdown, Form, Button, ListGroup} from 'react-bootstrap';
import Select from 'react-select';
import Search from './search.js';

class List extends React.Component{
	constructor(props){
		super(props);
	}

	handleQuery(query){
		//this.setState({ query });
	}

	handleFilter(result){
		//this.setState({ result });
	}

	render(){

		return(
			<div>
			<Navbar bg="secondary" expand="lg">
        <Navbar.Brand href="/policy"><b>Policy Summary</b></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/policy">
						<span style={{color:'black'}}><b><u>Home</u></b></span>
						</Nav.Link>
          </Nav>
          <Form inline>
					  <span className="badge badge-pill badge-dark">Policy Search</span>
					  <Search onQuery={this.handleQuery.bind(this)}
						onFilter={this.handleFilter.bind(this)}/>
          </Form>
         </Navbar.Collapse>
       </Navbar>
			</div>
			)
	}
}

export default (List);
