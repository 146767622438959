import {
  EMPLOYEE_ACCURAL_BY_ID_GET_ERROR,
  EMPLOYEE_ACCURAL_BY_ID_GET_REQUEST,
  EMPLOYEE_ACCURAL_BY_ID_GET_SUCCESS,
  EMPLOYEE_SHIFT_TIME_GET_ERROR,
  EMPLOYEE_SHIFT_TIME_GET_REQUEST,
  EMPLOYEE_SHIFT_TIME_GET_SUCCESS,
  LEAVE_ACCURAL_URL_GET_ERROR,
  LEAVE_ACCURAL_URL_GET_REQUEST,
  LEAVE_ACCURAL_URL_GET_SUCCESS,
  GET_PAY_DATE
} from "../actions/index";

const initialState = {
  leaveBalanceLoading: false,
  leaveBalance: [],
  leaveBalanceError: "",

  employeeAccuralsLoading: false,
  employeeAccurals: [],
  employeeAccuralsError: "",

  employeeShiftTimeLoading: false,
  employeeShiftTime: [],
  employeeShiftTimeError: "",

  payDate: [],
};

const actionMap = {
  [LEAVE_ACCURAL_URL_GET_REQUEST]: (state, action) => {
    return {
      ...state,
      leaveBalanceLoading: true,
      leaveBalance: [],
      leaveBalanceError: "",
    };
  },
  [LEAVE_ACCURAL_URL_GET_SUCCESS]: (state, action) => {
    return {
      ...state,
      leaveBalanceLoading: false,
      leaveBalance: action.payload,
      leaveBalanceError: "",
    };
  },
  [LEAVE_ACCURAL_URL_GET_ERROR]: (state, action) => {
    return {
      ...state,
      leaveBalanceLoading: false,
      leaveBalance: [],
      leaveBalanceError: action.error,
    };
  },
  [EMPLOYEE_ACCURAL_BY_ID_GET_REQUEST]: (state, action) => {
    return {
      ...state,
      employeeAccuralsLoading: true,
      employeeAccurals: [],
      employeeAccuralsError: "",
    };
  },
  [EMPLOYEE_ACCURAL_BY_ID_GET_SUCCESS]: (state, action) => {
    return {
      ...state,
      employeeAccuralsLoading: false,
      employeeAccurals: action.payload,
      employeeAccuralsError: "",
    };
  },
  [EMPLOYEE_ACCURAL_BY_ID_GET_ERROR]: (state, action) => {
    return {
      ...state,
      employeeAccuralsLoading: false,
      employeeAccurals: [],
      employeeAccuralsError: action.error,
    };
  },
  [EMPLOYEE_SHIFT_TIME_GET_REQUEST]: (state, action) => {
    return {
      ...state,
      employeeShiftTimeLoading: true,
      employeeShiftTime: [],
      employeeShiftTimeError: "",
    };
  },
  [EMPLOYEE_SHIFT_TIME_GET_SUCCESS]: (state, action) => {
    return {
      ...state,
      employeeShiftTimeLoading: false,
      employeeShiftTime: action.payload,
      employeeShiftTimeError: "",
    };
  },
  [EMPLOYEE_SHIFT_TIME_GET_ERROR]: (state, action) => {
    return {
      ...state,
      employeeShiftTimeLoading: false,
      employeeShiftTime: [],
      employeeShiftTimeError: action.error,
    };
  },
  [GET_PAY_DATE]: (state, action) => {
    return {
      ...state,
      payDate: action.payload,
    };
  },
};

export default (state = initialState, action) => {
  if (action && action.type) {
    const fn = actionMap[action.type];
    return fn ? fn(state, action) : state;
  }
  return state;
};
