import React, { Component } from "react";
import { Card, Container, Row, Col, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { change, Field, reduxForm } from "redux-form";
import Header from "../../header.jsx";
import "./camera.style.css";
import Select2 from "../../common/Select2.jsx";
import { Accordion, Icon, TextArea } from "semantic-ui-react";
import auth from "../../authentificate/auth";
import { Editor } from "@tinymce/tinymce-react";
import moment from "moment";

const styles = {
  editor: {
    border: "1px solid gray",
    minHeight: "6em",
  },
};
export const checkQuestionComment = (surveyData, quest) => {
  let comment = "",
    isExist = false;
  // console.log(surveyData, "checkQuestionComment", comment);
  surveyData &&
    surveyData.map((ele) =>
      ele.questions.map((item) => {
        if (item.QuestionId == quest.Id && item.QuestionComment != "") {
          comment = item.QuestionComment;
          isExist = true;
          return item.QuestionComment;
        } else return false;
      })
    );
  // this.setState({comment})
  return comment;
};

class CameraPMForm extends Component {
  constructor(props) {
    super(props);

    this.textInput = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this);
    this.state = {
      activeIndex: 0,
      user: auth.getLoggedUserDetailsFromLocal(),
      comment: "",
    };
  }
  focusTextInput() {
    this.textInput.current.focus();
  }
  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  showQuestionComment = (surveyData, quest, questionVaildation) => {
    let isExist = false;
    // console.log( "============JK", surveyData);
    // if(!addNew){
    if (surveyData) {
      for (let ele of surveyData) {
        for (let item of ele.questions) {
          if (item.Answer == 0 && item.QuestionId == quest.Id) {
            isExist = true;
            const d = document.getElementById(`comment_${item.QuestionId}`);
            if (d) d.style.display = "block";
          } else if (item.Answer == 1 && item.QuestionId == quest.Id) {
            const d = document.getElementById(`comment_${quest.Id}`);
            if (d) d.style.display = "none";
          }
        }
      }
    }
    // }
    // else if(addNew && surveyData.length == 0){
    //   const no = `no_${quest.Id}`
    //   const id = `comment_${quest.Id}`;
    //   const noAns = document.getElementById(no);
    //   const a = document.getElementById(id);
    //   if(noAns) noAns.checked = true
    //   if (a) {
    //     a.style.display = "block";
    //   }
    // }

    if (surveyData && surveyData?.length == 0 && quest) {
      // const id = `comment_${quest.Id}`;
      // const a = document.getElementById(id);
      // if (a) {
      //   a.style.display = "none";
      // }
      // console.log(a, id , '=====id2')
    }
    if (
      surveyData &&
      surveyData?.length == 0 &&
      quest &&
      questionVaildation.length == 0
    ) {
      // const id = `comment_${quest.Id}`;
      // const a = document.getElementById(id);
      // if (a) {
      //   a.style.display = "none";
      // }
      // console.log(a, id , '=====id')
    }
    if (
      surveyData &&
      surveyData?.length == 0 &&
      quest &&
      questionVaildation.includes(quest.Id)
    ) {
      const id = `comment_${quest.Id}`;
      const a = document.getElementById(id);
      if (a) {
        a.style.display = "block";
      }
      // console.log(a, id , '=====id')
    }
    if (
      surveyData &&
      surveyData?.length > 0 &&
      quest &&
      questionVaildation.includes(quest.Id)
    ) {
      const id = `comment_${quest.Id}`;
      const a = document.getElementById(id);
      if (a) {
        a.style.display = "block";
      }
      console.log(a, id, "=====id");
    }
    // console.log(quest.Id, surveyData , '===========')
    //  return isExist
  };

  showCommentBox = (questionList, surveyData) => {
    console.log(surveyData, "inside showComment", surveyData);
    if (
      surveyData &&
      surveyData?.length == 0 &&
      questionList &&
      questionList.length > 0
    ) {
      document.getElementById("PMFORM").reset();
      console.log(questionList, "Inside");
      questionList.map((categ) => {
        categ.questions.map((quest) => {
          let yes = `yes_${quest.Id}`;
          let noCheck1 = `no_${quest.Id}`;
          let comment = `comment_${quest.Id}`;
          const yesCheckbox = document.getElementById(yes);
          const noCheckbox = document.getElementById(noCheck1);
          const ccommentBox = document.getElementById(comment);
          if (yesCheckbox) yesCheckbox.checked = false;
          if (noCheckbox) {
            noCheckbox.checked = true;
            if (ccommentBox) {
              // ccommentBox.value = "";

              ccommentBox.style.display = "block";
            }
          }
        });
      });
    }
  };
  makeChecked = (surveyData, quest, v) => {
    return (
      surveyData &&
      surveyData?.find((ele) =>
        ele.questions.find((item) => {
          if (item.QuestionId == quest.Id && item.Answer == v) return true;
          else return false;
        })
      )
    );
  };
  render() {
    const {
      locationOptions,
      handleFilterLocationChange,
      categoriesOptions,
      handleFilterCategoryChange,
      questionList,
      handleSubmit,
      onEditorStateChange,
      editorState,
      err,
      questValidation,
      surveyData,
      validateData,
      questionVaildation,
      dateOptions,
      handleFilterDateChange,
      selectedDate,
      clearForm,
      addNew,
      handleReset,
      location,
      category,
      datetime
    } = this.props;
    const { activeIndex, user } = this.state;
    let isDateAvl = selectedDate == null ? true : false;
    let iscmp =
      surveyData && surveyData[0]?.questions[0]?.IsSurveyCompleted !== 1
        ? true
        : false;
    let hidebutton = iscmp ? iscmp : isDateAvl;
    console.log(handleReset, surveyData);
    if(location != null){
      this.props.dispatch(change("camera_pm_inspection_form",'Location', location))
    }
    if(category != null){
      this.props.dispatch(change("camera_pm_inspection_form",'Categories', category))
    }
    if(datetime != null){
      this.props.dispatch(change("camera_pm_inspection_form",'Date', datetime))
    }
    return (
      <>
        {/* <Header /> */}
        <Container fluid className="py-4 bg-light">
          <Card className="card-style">
            <Card.Body>
              <Row>
                <Col md="9" style={{ margin: "auto" }}>
                  <Card className="card-style bg-dark-color">
                    <Card.Body>
                      <Row>
                        <Col className="mb-3">
                          <h6>Name</h6>
                          <p>{user?.LastName || "" + " " + user?.FirstName}</p>
                        </Col>
                        <Col className="mb-3">
                          <h6>Username</h6>
                          <p>{user?.UserName}</p>
                        </Col>
                        <Col className="mb-3">
                          <h6>Position Title</h6>
                          <p>{user?.TitleName}</p>
                        </Col>
                        <Col className="mb-3">
                          <h6>Email</h6>
                          <p>{user?.WorkEmail}</p>
                        </Col>
                        <Col className="mb-3">
                          <h6>Phone</h6>
                          <p>{user?.CellPhone}</p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="9" style={{ margin: "auto" }}>
                  <br />
                  <Row>
                    <Col xs="8" md="10">
                      <h5 className="mb-3">
                        <b>&nbsp;&nbsp;Camera PM and Inspection</b>
                      </h5>
                    </Col>
                    <Col xs="4" md="2">
                      {surveyData && surveyData[0]?.questions[0] && (
                        <Button variant="secondary" onClick={clearForm}>
                          &nbsp;&nbsp;Add New&nbsp;&nbsp;
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <br />
                  <form onSubmit={handleSubmit} id="PMFORM">
                    <div className="display-flex row">
                      <Field
                        id="Location"
                        name="Location"
                        label="Location"
                        placeholder="Select Location"
                        component={Select2}
                        options={locationOptions}
                        wrapperClassName={
                          dateOptions?.length > 0
                            ? "col-md-4  rm-radius"
                            : "col-md-6  rm-radius"
                        }
                        isClearable={true}
                        handleChange={handleFilterLocationChange}
                        className={dateOptions?.length > 0 ? "rm-pl" : ""}
                      />

                      <Field
                        id="Categories"
                        name="Categories"
                        label="Categories"
                        placeholder="Select Categories"
                        component={Select2}
                        options={categoriesOptions}
                        wrapperClassName={
                          dateOptions?.length > 0
                            ? "col-md-4  rm-radius"
                            : "col-md-6  rm-radius"
                        }
                        isClearable={true}
                        handleChange={handleFilterCategoryChange}
                      />
                      {dateOptions?.length > 0 && (
                        <Field
                          id="Date"
                          name="Date"
                          label="Select Date"
                          placeholder="Select Date"
                          component={Select2}
                          options={dateOptions}
                          wrapperClassName="col-xs-12 col-md-4 rm-radius"
                          isClearable={true}
                          handleChange={handleFilterDateChange}
                          className="rm-pr"
                        />
                      )}
                      <input
                        type="hidden"
                        name="action"
                        value={
                          !addNew && surveyData && surveyData.length > 0
                            ? "update"
                            : "insert"
                        }
                      />
                    </div>
                    <Row>
                      <Col md="4">
                        {" "}
                        <p style={{ color: "red" }}>
                          {(err && err.locationErr) || ""}
                        </p>
                      </Col>
                      <Col md="4">
                        {" "}
                        <p style={{ color: "red" }}>
                          {(err && err.categoryErr) || ""}
                        </p>
                      </Col>
                    </Row>
                    <br />
                    {questionList &&
                      questionList.length > 0 &&
                      questionList?.map((category, index) => {
                        return (
                          <Accordion
                            fluid
                            styled
                            className="accordion-style mb-3"
                          >
                            <Accordion.Title
                              active={activeIndex === 0}
                              index={index}
                              onClick={this.handleClick}
                            >
                              <Icon name="dropdown" />
                              {category.CategoryName}
                              <input
                                type="hidden"
                                value={category.Id}
                                name={"category" + category.Id}
                              />
                              <span
                                style={{ color: "red", paddingLeft: "3rem" }}
                              >
                                {questValidation.includes(category.Id)
                                  ? "Please Answer the questions"
                                  : ""}
                              </span>
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === index}>
                              {category?.questions &&
                                category?.questions?.map((quest, index2) => {
                                  return (
                                    <Card
                                      className={
                                        index2 % 2 == 0
                                          ? "rm-border bg-dark-color mb-3"
                                          : "rm-border bg-color mb-3"
                                      }
                                    >
                                      <Card.Body>
                                        <div>
                                          <Row>
                                            <Col md="7">
                                              <span className="pr-3 text-bold">
                                                <b>Q{index2 + 1}.</b>
                                              </span>
                                              {quest?.Question}
                                            </Col>
                                            <Col
                                              md="2"
                                              className="display-flex my-2"
                                            >
                                              <Form.Check
                                                value={1}
                                                label="Yes"
                                                type="radio"
                                                name={`answer_${quest.Id}`}
                                                disabled={
                                                  selectedDate != null
                                                    ? true
                                                    : false
                                                }
                                                checked={this.makeChecked(
                                                  surveyData,
                                                  quest,
                                                  1
                                                )}
                                                className="radio-style"
                                                id={`yes_${quest.Id}`}
                                                onChange={() =>
                                                  validateData(
                                                    category.Id,
                                                    quest.Id,
                                                    1
                                                  )
                                                }
                                              />{" "}
                                              &nbsp;&nbsp;
                                              <Form.Check
                                                value={0}
                                                label="No"
                                                type="radio"
                                                name={`answer_${quest.Id}`}
                                                disabled={
                                                  selectedDate != null
                                                    ? true
                                                    : false
                                                }
                                                checked={this.makeChecked(
                                                  surveyData,
                                                  quest,
                                                  0
                                                )}
                                                // onClick={this.focusTextInput}
                                                className="radio-style"
                                                onChange={() =>
                                                  validateData(
                                                    category.Id,
                                                    quest.Id,
                                                    0
                                                  )
                                                }
                                                id={`no_${quest.Id}`}
                                              />
                                            </Col>
                                            <Col md="3">
                                              {quest.Answer}
                                              <Form.Control
                                                as={"textarea"}
                                                rows={1}
                                                disabled={
                                                  selectedDate != null
                                                    ? true
                                                    : false
                                                }
                                                defaultValue={checkQuestionComment(
                                                  this.props.surveyData,
                                                  quest
                                                )}
                                                ref={this.textInput}
                                                placeholder="Why No ?"
                                                className={
                                                  this.showQuestionComment(
                                                    this.props.surveyData,
                                                    quest,
                                                    questionVaildation
                                                  )
                                                    ? "show"
                                                    : "hidden"
                                                }
                                                name={`comment_${quest.Id}`}
                                                id={`comment_${quest.Id}`}
                                              />
                                              {questionVaildation.includes(
                                                quest.Id
                                              ) ? (
                                                <p className="text-danger">
                                                  <b>Please enter comment</b>
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  );
                                })}
                            </Accordion.Content>
                          </Accordion>
                        );
                      })}

                    <div>
                      <label>
                        <b>Comment</b>
                      </label>

                      <Editor
                        apiKey="te1x9yenu0i0czruwey8ta1o7f185vvmasbcrkr5nl921ca1"
                        initialValue={editorState}
                        init={{
                          height: 200,
                          plugins: "link image code",
                          toolbar:
                            "undo redo | bold italic | alignleft aligncenter alignright | code",
                          branding:false  
                        }}
                        onEditorChange={onEditorStateChange}
                      />
                    </div>
                    {/* </Form.Group> */}
                    <br />
                    <Row>
                      <Col md={8} style={{ display: "flex" }}>
                        {(surveyData &&
                          surveyData[0]?.questions[0]?.CreatedAt && (
                            <p>
                              <b>Created At:</b>{" "}
                              {moment
                                .parseZone(
                                  surveyData[0]?.questions[0]?.CreatedAt
                                )
                                .format("MM-DD-YYYY hh:mm:ss A")}
                            </p>
                          )) ||
                          ""}{" "}
                        &nbsp;&nbsp;
                        {(surveyData &&
                          surveyData[0]?.questions[0]?.UpdatedAt && (
                            <p>
                              <strong>Updated At:</strong>{" "}
                              {moment
                                .parseZone(
                                  surveyData[0]?.questions[0]?.UpdatedAt
                                )
                                .format("MM-DD-YYYY hh:mm:ss a")}{" "}
                            </p>
                          )) ||
                          ""}
                      </Col>
                      <Col md={4}>
                        <Button
                          variant="dark"
                          onClick={() =>
                            this.showCommentBox(
                              questionList,
                              this.props.surveyData
                            )
                          }
                        >
                          &nbsp;&nbsp;Clear Form&nbsp;&nbsp;
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button
                          variant="dark"
                          type="submit"
                          disabled={!isDateAvl}
                        >
                          &nbsp;&nbsp;Save & Update&nbsp;&nbsp;
                        </Button>
                      </Col>
                    </Row>
                    <div style={{ display: "none" }}>
                      {" "}
                      {handleReset
                        ? ""
                        : setTimeout(
                            () =>
                              this.showCommentBox(
                                questionList,
                                this.props.surveyData
                              ),
                            0
                          )}
                    </div>
                    {/* {surveyData.length} */}
                  </form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </>
    );
  }
}

CameraPMForm = reduxForm({
  form: "camera_pm_inspection_form",
})(CameraPMForm);

export default CameraPMForm;
