import React, { Component } from "react";
import { Field, reduxForm, change } from "redux-form";
import DatePickerField from "../common/DatePickerField.jsx";
import "react-datepicker/dist/react-datepicker.css";
import Select2 from "../common/Select2.jsx";
import { Button } from "semantic-ui-react";
import moment from "moment";
class ListingFilterForm extends Component {
  render() {
    const {
      handleSubmit,
      employeesOptions,
      handleFilterEmployeeChange,
      handlePayDateChange,
      startDate,
      payDateData,
      employee
    } = this.props;
    const handleReset = () => {
      window.location.reload();
    };
    if (employee) {
      this.props.dispatch(change("leave_accruals_paydate_filter_form", "Employee", employee)); 
    }
    return (
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-md-12">
          <div style={{ marginBottom: "10px" }}>
            <h4>
              <strong>
                Pay Date :
                {payDateData &&
                  moment.parseZone(payDateData?.Pay_Date).format("L")}
              </strong>
            </h4>
          </div>
        </div>
        <Field
          id="startDate"
          name="startDate"
          label="Select PayDate"
          placeholder="Select PayDate"
          component={DatePickerField}
          wrapperClassName="col-md-4"
          isClearable={true}
          startDate={startDate}
          onChange={handlePayDateChange}
        />
        <Field
          id="Employee"
          name="Employee"
          label="Select Employee"
          placeholder="Select Employee"
          component={Select2}
          options={employeesOptions}
          wrapperClassName="col-md-4"
          isClearable={true}
          handleChange={handleFilterEmployeeChange}
        />

        <div className="col-md-4">
          <Button
            variant="primary"
            onClick={handleSubmit}
            className=""
            style={{ height: 40, marginTop: "22px" }}
          >
            {"Search"}
          </Button>
  
        </div>
      </form>
    );
  }
}

ListingFilterForm = reduxForm({
  form: "leave_accruals_paydate_filter_form",
})(ListingFilterForm);

export default ListingFilterForm;
