import { FETCH_CATEGORIES, FETCH_CATEGORIES_FROM_SURVEY, FETCH_SURVEY_DATE_LIST } from "../actions/camera_inspection.action"

export default function (state = [], action) {
    switch (action.type) {
    case FETCH_CATEGORIES:
      return [action.payload.data]
    }
    return state
}

export function CategoryFromSurvey(state = [], action) {
  switch (action.type) {
  case FETCH_CATEGORIES_FROM_SURVEY:
    return [action.payload.data]
  }
  return state
}

export function SurveyDateList(state = [], action) {
  switch (action.type) {
  case FETCH_SURVEY_DATE_LIST:
    return [action.payload.data]
  }
  return state
}