/**
 * list.js file
 */
import React from "react";
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import {fetchPolicies} from '../../actions';
import Select from 'react-select';

class Search extends React.Component{

	constructor(props){
		super(props);
		this.state = {
			selectedOption: null,
			selectedOptionID:null
		}
		this.handleChange = this.handleChange.bind(this);
		this.customFilter = this.customFilter.bind(this);
	}

	componentDidMount(){
		this.props.fetchPolicies();
	}

	handleChange(selectedOption) {

		this.setState({ selectedOption: selectedOption,
		                selectedOptionID: selectedOption.ID});

		if(selectedOption != null){
			//console.log(event);
			event.target.blur();
			var address = "/policy/" + selectedOption.ID;
			this.props.history.push(address);
		}
  }

	handleInputChange(event){
		if(event){
			this.props.onQuery(event);
		}else{
			this.props.onQuery('');
		}
	}

	//Add your search logic here.
  customFilter(option, searchText) {
    if (
			option.data.Subject.toLowerCase().includes(searchText.toLowerCase())||
      option.data.Purpose.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.Policy.toLowerCase().includes(searchText.toLowerCase())
    ) {
			this.props.onFilter(true);
      return true;
    } else {
			this.props.onFilter(false);
      return false;
    }
  }

	render(){
		const options = this.props.policies["0"];
		
		const selectStyles = {
			option: (provided, state) => ({
				...provided,
				textAlign: "left"
			}),
			placeholder: (provided, state) => ({
				...provided,
				fontSize: 16,
				fontWeight: "bold"
			}),
		}

		return(
			<div style={{minWidth: '300px'}}>
			<Select
		 	  value={this.state.selectedOption}
				styles={selectStyles}
			  onChange={this.handleChange}
				getOptionLabel={option =>`${option.Subject}`}
				getOptionValue={option => `${option}`}
				isOptionSelected={option => {
					this.state.selectedOptionID === option.ID ? true : false;
				}}
				options={options}
				isSearchable={true}
				filterOption={this.customFilter}
				onInputChange={this.handleInputChange.bind(this)}
				noOptionsMessage={() => "No policies contain your keyword search."}
				autoFocus={true}
		  	placeholder = "Select..."
				//menuIsOpen={this.state.menuOpen}
		  	/>
				</div>
			)
		}
}

function mapStateToProps(state){
		return { policies: state.policies };
}

export default connect(mapStateToProps, {fetchPolicies})(withRouter(Search));
