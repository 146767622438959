import React from 'react'
import Modal from 'react-responsive-modal';

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  componentDidMount() {
    document.title = "CCPD APP - Privacy Policy"
  }

  onCloseModal(){
    window.close();
  }

  render() {
    const styles = {
      fontFamily: "sans-serif",
      textAlign: "center"
    };

    return (
      <div style={styles}>
        <Modal open="true" onClose={this.onCloseModal}>
        <div>
          <p>Privacy Policy</p>
          <p>Thank you for visiting Camden County Site and reviewing our Privacy Notice. This statement addresses collection, use and security of and access to information that may be obtained through the use of Camden County Police Department Recruitment Form. This notice covers the following topics:</p>
          <ul>
              <li>Information Collected and How it is Used</li>
              <li>Personal Information and Choice</li>
              <li>Public Access to Information</li>
              <li>Cookies</li>
              <li>Security</li>
              <li>Disclaimer</li>
              <li>Contact Information</li>
          </ul>

          <br/>

          <p className="font-weight-bold">Information Collected and How it is Used</p>
          <p><strong>Information Collected and Stored Automatically</strong> The Internet domain (for example, "xcompany.com" if you use a private Internet access account, or "yourschool.edu" if you connect from a university's domain) and IP address (an IP address is a number that is automatically assigned to your computer whenever you are surfing the Web) from which you access Camden County’s PD’s site;</p>
          <ul>
              <li>The type of browser and operating system used to access the service;</li>
              <li>The date and time you access the service;</li>
              <li>The pages and services you visit;</li>
              <li>If you linked to the server from another web site, the address of that web site; and</li>
          </ul>
          <p>We use this information to help us make our services more useful to visitors - to learn about the number of visitors to our services and the types of technology our visitors use. We do not track or record information about individuals and their visits.</p>
          <p className="font-weight-bold">If You Send Us Personal Information</p>
          <p>If you choose to provide us with personal information -- as in a customer support e-mail, or by filling out a form with your personal information and submitting it to us through our services -- we use that information to respond to your message and to help us get you the information you have requested. We may share the information you give us with any State of New Jersey government agency, department, office or entity, or as otherwise required by law. Moreover, we do not give it to any private organizations. We do not collect information for commercial marketing.</p>
          <p className="font-weight-bold">Personal Information and Choice</p>
          <p>You may choose whether to provide personal information on-line.</p>
          <p>"Personal information" is information about a natural person that is readily identifiable to that specific individual. Personal information includes such things as an individual's name, address, and phone number. A domain name or Internet protocol address is not considered personal information.</p>
          <p>We collect no personal information about you unless you voluntarily provide it to us by sending us email, participating in a survey or completing an on-line form. You may choose not to contact us by email, participate in a survey, or provide any personal information using an online form.</p>
          <p className="font-weight-bold">Privacy Warning</p>
          <p>Email is not a secure medium. Personal information sent via the Internet can be intercepted. You may, therefore, wish to reconsider the advisability of sending messages that contain sensitive, personal information to this Internet address.</p>
          <p>We do plan on divulging information collected or information disclosed voluntarily, but may be required to do so pursuant to a request under state right to know laws such as the New Jersey Open Public Records Act, any common law right to know laws, other State or Federal laws or pursuant to court order.</p>
          <p>If you believe that your personal information is being used for a purpose other than what was intended when submitted, you may contact us as shown in the Contact Information Section of this statement.</p>
          <p className="font-weight-bold">Public Access to Information</p>
          <p>In the State of New Jersey, laws exist to ensure that government is open and that the public has a right to access appropriate records and information possessed by state government. At the same time, there are exceptions to the public's right to access public records that serve various needs including the privacy of individuals. Exceptions are provided by both state and federal laws.</p>
          <p>All the information collected through our services becomes public records that may be subject to inspection and copying by members of the public, unless an exemption in law exists.</p>
          <p className="font-weight-bold">Cookies</p>
          <p>We do not use cookies to keep track of users on the Camden County Recruitment Form site.</p>
          <p className="font-weight-bold">Security</p>
          <p>As developer and manager of these services, we have taken several steps to safeguard the integrity of its data and prevent unauthorized access to information maintained. These measures are designed and intended to prevent corruption of data, block unknown or unauthorized access to our systems and information, and to provide reasonable protection of private information in our possession.</p>
          <p className="font-weight-bold">Disclaimer</p>
          <p>Our services and materials may contain hypertext or links to other Internet computer sites which are not owned, operated, controlled or reviewed by Camden County Police Department. These links are provided solely as a courtesy and convenience to you, the visitor. When you link to one of these sites, you are no longer using our services and this Privacy Notice will not apply. When you link to another web site, you are subject to the privacy policy of that new site.</p>
          <p>We exercise no control over the organizations, views, accuracy, copyright or trademark compliance or the legality of the material contained on those servers and do not sponsor, endorse, or approve the information, content, products, materials, opinions or services contained on such external sites. The visitor proceeds to these external sites at their own risk. We specifically disclaim any and all liability from damages which may result from the accessing of a third party site which is linked to from our services, or from reliance upon any such information.</p>
          <p className="font-weight-bold">Contact Information</p>
          <p>You may contact us at:</p>
          <p className="font-weight-bold">P: (856) 757-7400 Email:&nbsp;</p>
          <p>&nbsp;</p>
        </div>
        </Modal>
      </div>
    )
  }
}

export default PrivacyPolicy;
