import React from "react";
import Modal from "react-responsive-modal";

class Disclaimer extends React.Component {
  constructor(props) {
    super(props);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  componentDidMount() {
    document.title = "CCPD APP - Disclaimer";
  }

  onCloseModal() {
    window.close();
  }

  render() {
    const styles = {
      fontFamily: "sans-serif",
      textAlign: "center",
    };

    return (
      <div style={styles}>
        <Modal open="true" onClose={this.onCloseModal}>
          <div>
            <p className="font-weight-bold">DISCLAIMER</p>
            <p>
              CCPD values your privacy and will not sell your data or use it for
              marketing purposes. We comply with all applicable laws, policies,
              and regulations relating to the collection of information from
              visitors to our website.
            </p>
            <p>
              This website, like many websites, does use cookies to gather
              certain information purely for analytics purposes. In other words,
              we do not track personal information such as your name, email, or
              location, but we do use Google Analytics to help us look at
              aggregate patterns, such as the average number of visitors per day
              and which policy was visited most often.{" "}
            </p>
            <p>
              You can learn more about how Google uses data when you visit
              partner sites or apps by visiting this link:{" "}
              <a
                href="https://policies.google.com/privacy?gl=US&hl=en"
                target="_blank"
              >
                <b>Privacy</b>
              </a>
            </p>
            <p>
              You can opt out from this function by using this link:{" "}
              <a
                href="https://chrome.google.com/webstore/detail/google-analytics-opt-out/fllaojicojecljbmefodhfapmkghcbnh?hl=en"
                target="_blank"
              >
                <b>Opt-Out</b>
              </a>
            </p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Disclaimer;
