import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';
import axios from "axios";
//import {createApplicant} from '../../actions';

class EmailForm extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount() {

  }

  renderRecaptchaField(field) {
    const { meta: { touched, error } } = field;
    return (
      <div align="center" className="form-group col-md-12">
        <ReCAPTCHA
          sitekey="6LdOFaEUAAAAALSdObLm8SPZVJHnC_eGX9db2EG-"
          onChange={field.input.onChange}
        />
        <div className="text-danger">{ touched ? error : '' }</div>
      </div>
    );
  }

  renderField(field) {
    const { input, label, type, textarea, required, meta: { touched, error, warning, invalid } } = field;
    const textareaType = <textarea {...input} placeholder={label}  type={type} className={`form-control ${touched && invalid ? 'has-danger' : ''}`} required={required}/>;
    const inputType = <input {...input} placeholder={label}  type={type} className={`form-control ${touched && invalid ? 'has-danger' : ''}`} required={required}/>;

    return (
      <div className="form-group col-md-12">
          <label><b>{label}</b></label>
          {textarea ? textareaType : inputType}
          {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    );
  }

  onSubmit(values) {
    console.log(values)
    // this.props.createApplicant(values, () =>{
    //   var title = "Thank you " + values.firstName + " " + values.lastName;
    //   Swal.fire({
    //     position: 'top-end',
    //     type: 'success',
    //     title: title,
    //     text: 'Your CCPD application has been saved',
    //     showConfirmButton: true
    //   }).then(function(){
    //     location.reload();
    //   });
    //
    // });

  }


  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <form className="d-flex"
      onSubmit={handleSubmit(this.onSubmit.bind(this))}>
      <div className="p-1 border border-info container" style={{maxWidth: '700px'}}>
      <div className="form-row" >
        <Field
          label="Subject"
          name="subject"
          type="text"
          required="required"
          component={this.renderField}
        />
        <Field
          label="Body"
          name="body"
          type="text"
          textarea={true}
          required="required"
          component={this.renderField}
        />
        <Field
          name="recaptcha"
          component={this.renderRecaptchaField}
        />
        </div>

        <div className="text-center">
          <button type="submit" className="btn btn-primary">Submit</button>
          <button type="button" className="btn btn-danger" disabled={pristine || submitting} onClick={reset}>Clear Values</button>
        </div>
      </div>
      </form>
    );
  }
}

function validate(values) {
  const { subject, body, recaptcha } = values;
  const errors = {};
  // Validate the inputs from 'values'
  if (!subject) {
    errors.subject = 'Please enter the subject.';
  }
  if (!body) {
    errors.body = 'Please enter the body.';
  }
  if (!recaptcha) {
    errors.recaptcha = 'reCAPTCHA required.';
  }

  // If errors is empty, the form is fine to submit
  // If errors has *any* properties, redux form assumes form is invalid
  return errors;
}


export default reduxForm({
  validate,
  form: "emailForm"
})(connect(null, {  })(EmailForm));
