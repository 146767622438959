import React from "react";
import {
  Segment,
  Divider,
  Header,
  Label,
  Icon,
  Table,
  Card,
  Image,
  Grid,
  Tab,
} from "semantic-ui-react";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchSubordinate, ROOT_URL } from "../../actions";
import HRResultDetailSub from "./hr_result_detail_sub.js";
import HRResultDetailRelation from "./hr_result_detail_relation.js";
import HRDepartmentInfoTable from "./HRDepartmentInfoTable.jsx";
import { formatTableDate } from "../../util";
import HRResultDetailSup from "./hr_result_detail_sup";

class HRResultDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      w: screen.width,
      openSupervisorModal: false,
    };
    this.handleResize = this.handleResize.bind(this);
    this.handleClickPhoto = this.handleClickPhoto.bind(this);
    this.handleClickSupervisor = this.handleClickSupervisor.bind(this);
  }

  handleResize() {
    this.setState({
      w: screen.width,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    const id = this.props.hr.EmployeeId;
    this.props.fetchSubordinate(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      const id = nextProps.hr.EmployeeId;
      this.props.fetchSubordinate(id);
    }
  }

  handleClickPhoto() {
    if (this.props.hrPhoto2) {
      Swal.fire({
        html:
          "<img src='" +
          this.props.hrPhoto2.Image +
          "' style='width:50%;height:50%'>",
      });
    }
  }

  handleClickSupervisor() {
    const superName = this.props.hr.Supervsior;
    if (superName) {
      const superBadge = this.props.hr.Supervsior.split("[")[1].split("]")[0];
      Swal.queue([
        {
          title: "Supervsior",
          confirmButtonText: "Show",
          text: superName,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return fetch(`${ROOT_URL}/hr/supervisor/${superBadge}`)
              .then((response) => response.json())
              .then((data) => {
                location.assign(`/hr/${data.EmployeeId}`);
              })
              .catch(() => {
                Swal.insertQueueStep({
                  icon: "error",
                  title: "Unable to get the supervisor info",
                });
              });
          },
        },
      ]);
    }
  }

  render() {
    const { hr, hrPhoto1, hrSupervisor, selectedEmployeeCommander } =
      this.props;
    const { openSupervisorModal } = this.state;

    const w = this.state.w;
    let mobile;
    if (w < 769) {
      mobile = true;
    } else {
      mobile = false;
    }

    const HireDate = hr.HireDate ? formatTableDate(hr.HireDate) : "-";

    return (
      <>
        <Segment secondary>
          <Grid columns={2} stackable textAlign="center" verticalAlign="middle">
            <div className="container-md hr-details-container">
              <Grid.Column className="hr-details-left">
                <Divider className="d-none d-md-block" horizontal>
                  <Header as="h1"></Header>
                </Divider>

                <Card>
                  {!hrPhoto1 && <Tab.Pane loading>Half-length photo</Tab.Pane>}
                  {hrPhoto1 && (
                    <Image
                      label={{
                        as: "a",
                        color: "black",
                        content: "More Photo",
                        ribbon: true,
                        icon: "user circle",
                        onClick: this.handleClickPhoto,
                      }}
                      src={`${hrPhoto1.Image}`}
                      wrapped
                      ui={false}
                    />
                  )}
                  <Card.Content textAlign="center">
                    <Card.Header>
                      {hr.FirstName} {hr.LastName}
                    </Card.Header>
                    <Card.Header>{hr.TitleName}</Card.Header>
                    <Card.Description>
                      {mobile && (
                        <>
                          <a href={`tel:+1${hr.CellPhone}`}>
                            <i className="material-icons">call</i>
                          </a>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                      )}
                      {mobile && (
                        <>
                          <a href={`sms:+1${hr.CellPhone}`}>
                            <i className="material-icons">textsms</i>
                          </a>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                      )}
                      <a href={`mailto:${hr.WorkEmail}`}>
                        <i className="material-icons">mail</i>
                      </a>
                    </Card.Description>
                  </Card.Content>
                  {hrSupervisor &&
                    hrSupervisor.status === 1 &&
                    hrSupervisor.data && (
                      <Card.Content extra textAlign="center">
                        <a
                          style={{ color: "blue" }}
                          // onClick={this.handleClickSupervisor}
                          onClick={() =>
                            this.setState({ openSupervisorModal: true })
                          }
                        >
                          <Icon name="sitemap" />
                          <b>Supervisor</b>
                          {/* <b>{hr.Supervsior}</b> */}
                        </a>
                        <br />
                        <br />
                        <Label pointing color="teal" attached="bottom">
                          Supervsior Info
                        </Label>
                      </Card.Content>
                    )}
                </Card>
                {hrSupervisor &&
                  hrSupervisor.status === 1 &&
                  hrSupervisor.data && (
                    <HRResultDetailSup
                      data={[hrSupervisor.data]}
                      open={openSupervisorModal}
                      hideModal={() =>
                        this.setState({ openSupervisorModal: false })
                      }
                    />
                  )}

                <HRResultDetailSub hrSubordinate={this.props.hrSubordinate} />
              </Grid.Column>

              <Grid.Column className="hr-details-right">
                <Divider horizontal>
                  <Header as="h4">
                    <Icon name="user" />
                    Profile
                  </Header>
                </Divider>

                <Table inverted color="teal">
                  <Table.Body>
                    <Table.Row>
                      <Table.HeaderCell>
                        <Icon name="phone" />
                      </Table.HeaderCell>
                      {mobile && (
                        <Table.Cell>
                          <a
                            style={{ color: "blue" }}
                            href={`tel:+1${hr.CellPhone}`}
                          >
                            <b>{hr.CellPhone}</b>
                          </a>
                        </Table.Cell>
                      )}
                      {!mobile && (
                        <Table.Cell>
                          <a style={{ color: "black" }}>
                            <b>{hr.CellPhone}</b>
                          </a>
                        </Table.Cell>
                      )}
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell>
                        <Icon name="mail" />
                      </Table.HeaderCell>
                      <Table.Cell>
                        <a
                          style={{ color: "blue" }}
                          href={`mailto:${hr.WorkEmail}`}
                        >
                          <b>{hr.WorkEmail}</b>
                        </a>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell>
                        <Icon name="home" />
                      </Table.HeaderCell>
                      {mobile && (
                        <Table.Cell>
                          <a
                            style={{ color: "blue" }}
                            href={`tel:+1${hr.HomeTelephone}`}
                          >
                            <b>{hr.HomeTelephone}</b>
                          </a>
                        </Table.Cell>
                      )}
                      {!mobile && (
                        <Table.Cell>
                          <a style={{ color: "black" }}>
                            <b>{hr.HomeTelephone}</b>
                          </a>
                        </Table.Cell>
                      )}
                    </Table.Row>
                    {hr.OtherEmail && (
                      <Table.Row>
                        <Table.HeaderCell>
                          <Icon name="home" />
                        </Table.HeaderCell>
                        <Table.Cell>
                          <a
                            style={{ color: "blue" }}
                            href={`mailto:${hr.OtherEmail}`}
                          >
                            <b>{hr.OtherEmail}</b>
                          </a>
                        </Table.Cell>
                      </Table.Row>
                    )}
                    <Table.Row>
                      <Table.HeaderCell>
                        <Icon name="time" />
                      </Table.HeaderCell>
                      <Table.Cell style={{ color: "black" }}>
                        Joined in {HireDate}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Divider horizontal>
                  <Header as="h4">
                    <Icon name="address card" />
                    Department
                  </Header>
                </Divider>

                <HRDepartmentInfoTable
                  data={hr}
                  commanderInfo={selectedEmployeeCommander}
                />

                <Divider horizontal>
                  <Header as="h4">
                    <Icon name="home" />
                    Relationship
                  </Header>
                </Divider>

                <HRResultDetailRelation hr={hr} />
              </Grid.Column>
            </div>
          </Grid>
        </Segment>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    hrSubordinate: state.hrSubordinate,
    hrSupervisor: state.hrSupervisor,
    selectedEmployeeCommander: state.hrCommander.selectedEmployeeCommander,
  };
}

export default connect(mapStateToProps, { fetchSubordinate })(
  withRouter(HRResultDetail)
);
