import React from "react";
//import Result from './result.js';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import {CSVLink, CSVDownload} from 'react-csv';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {withRouter} from "react-router-dom";

var rows = [];

class ApplyTotalDetails extends React.Component{

	constructor(props){
		super(props);
		this.state = {
			apply: props.apply
		}
    this.convert = this.convert.bind(this);
	}

	componentWillReceiveProps(nextProps){
    if (nextProps.apply !== this.props.apply){
			var apply = nextProps.apply;
      this.setState({apply: apply});

      apply.forEach(element => {
        var temp = [element.county,element.totalNumber];
        rows.push(temp);
      });

		}
	}

  convert(){
    //console.log(rows);
    var doc = new jsPDF();
    doc.autoTable({head: [['County', 'Total']], body: rows });
    doc.save('total_applications.pdf');
  }

	render(){

    const headers = [
			{label: 'County', key: "county"},
			{label: 'Total', key: "totalNumber"},
		];

    const { apply } = this.state;
    var csvData = [];
    if(apply){
      csvData = (apply).map(result => {
        return{
          county: result.county,
          totalNumber: result.totalNumber
        }
      })
    }

		return(
			<div>
      <span>
      Click &nbsp;
      <CSVLink data={csvData} headers={headers} filename="total_applications.csv" className="btn btn-sm btn-primary">
      CSV ↓
      </CSVLink> or <button className="btn btn-sm btn-primary" onClick={this.convert} >PDF ↓ </button> to download <span style={{color: 'red'}}> Total Application </span> data.
      </span>

		     <ReactTable
		     data={this.state.apply}
				 filterable
		     columns={[
		     	{ //Header: 'CCPD',
		     		columns: [
		     		{
		     			Header: 'County',
		     			headerStyle:{
		     				backgroundColor: '#dddddd',
		     			},
							id: "county",
							accessor: 'county'
		     		},
		     		{
		     			Header: 'Total Number',
		     			headerStyle:{
		     				backgroundColor: '#dddddd',
		     			},
							id: "totalNumber",
							accessor: 'totalNumber'
		     		}
		     		]
		     	}
		     	]}
		     	defaultPageSize={10}
					defaultFilterMethod = {(filter, row, column) => {
						const id = filter.pivotId || filter.id
						return row[id] !== undefined ? String(row[id]).toLowerCase().startsWith(filter.value.toLowerCase()) : true
					}}
		     	className="-striped -highlight"
		     	/>

			</div>

			)
	}
}

export default withRouter(ApplyTotalDetails);
