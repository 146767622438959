/**
 * app.jsx file
 */
import React from "react";
import {Link} from 'react-router-dom';
import {withRouter} from "react-router-dom";
import ScrollUpButton from "react-scroll-up-button";
import {Nav, Navbar} from 'react-bootstrap';

import Header from '../header.jsx';
import Footer from '../footer.jsx';
import ContactForm from './contactForm.js';

class Contact extends React.Component{

	constructor(props){
		super(props);
	}

	componentDidMount() {
		document.title = "CCPD APP - Contact"
	}

	render(){

		return(
			<div>

			<Header />

      <Navbar bg="secondary" expand="lg">
        <Navbar.Brand href="/contact">CCPD Community Contact Form</Navbar.Brand>
      </Navbar>

			<ContactForm />

			<Footer />
		  <ScrollUpButton ContainerClassName="ScrollUpButton__Container"/>
		  </div>
			)
	}
}


export default withRouter(Contact);
