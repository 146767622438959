import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import { Segment,Grid,Divider, Header, Icon} from 'semantic-ui-react'
import Swal from 'sweetalert2';
import { createTextMask } from 'redux-form-input-masks';
import axios from "axios";
import {createContact, sendEmails} from '../../actions';
import states from '../../../data/states.json';
import is from 'is_js';

const myCustomMaskDefinitions = {
  9: {
    regExp: /[0-9]/,
  },
  8: {
    regExp: /[1-9]/,
  },
};

const phoneMask = createTextMask({
  pattern: '(999) 999-9999',
  //maskDefinitions: myCustomMaskDefinitions
});

const zipMask = createTextMask({
  pattern: '99999',
});

const initialMask = createTextMask({
  pattern: 'A',
});

class ContactForm extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount() {
    this.handleInitialize();
  }

  handleInitialize() {
    const initData = {
      apartmentUnit: null,
      mobilePhone: null,
      emailAddress: null,
      city: 'Camden',
      state: 'New Jersey',
      emgApartmentUnit:null,
      emgMobilePhone: null,
      emgState: 'New Jersey',
      comments: null,
    }
    this.props.initialize(initData);
  }

  renderRecaptchaField(field) {
    const { meta: { touched, error } } = field;
    return (
      <div align="center" className="form-group col-md-12">
        <ReCAPTCHA
          sitekey="6LdOFaEUAAAAALSdObLm8SPZVJHnC_eGX9db2EG-"
          onChange={field.input.onChange}
        />
        <div className="text-danger">{ touched ? error : '' }</div>
      </div>
    );
  }

  renderField(field) {
    const { input, label, type, textarea, disabled, required, meta: { touched, error, warning, invalid } } = field;
    const textareaType = <textarea {...input} placeholder={label}  type={type} className={`form-control ${touched && invalid ? 'has-danger' : ''}`} required={required}/>;
    const inputType = <input {...input} placeholder={label}  type={type} disabled={disabled}
    className={`form-control ${touched && invalid ? 'has-danger' : ''}`} required={required}/>;

    return (
      <div className="form-group col-md-6">
          <label><b>{label}</b></label>
          {textarea ? textareaType : inputType}
          {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    );
  }

  renderSelect(field) {
    const { input, label, type, options, required, meta: { touched, error, warning, invalid } } = field;
    return (
      <div className="form-group col-md-6">
          <label><b>{label}</b></label>
          <select className="form-control" type={type} {...input} required={required}>
          <option value="New Jersey">New Jersey</option>
          {
            options.map(function(option) {
                return <option key={option.key}
                value={option.value} >{option.value} </option>;
              }
            )
          }
          </select>
          {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    );
  }

  onSubmit(values) {
    //console.log(values)
    var data = {};
    data.to = values.emailAddress + ',' + values.emgEmailAddress;

    this.props.createContact(values, () =>{
      var title = "Thank you " + values.firstName + " " + values.lastName;
      Swal.fire({
        type: 'success',
        title: title,
        html: 'Your information has been submitted. Click <font color="red">OK</font> to continue.',
        showConfirmButton: true
      }).then(function(){
        sendEmails(data, () =>{
		      location.assign('https://camdencountypd.org');
		    });
      });

    });

  }


  render() {
    const {handleSubmit, pristine, reset, submitting } = this.props;
    var ie = is.ie();

    return (
      <form className="d-flex"
      onSubmit={handleSubmit(this.onSubmit.bind(this))}>
      <div className="p-1 border border-info container" >

      <Segment>
      <Grid columns={2} relaxed='very' stackable>

      <Grid.Column>
      <br/>
      <Divider horizontal>
      <Header as='h4' color='violet'>
      <Icon name='address book' />
      Senior Contact Detail
      </Header>
      </Divider>
      <div className="form-row" >
        <Field
          label="First Name"
          name="firstName"
          type="text"
          required="required"
          component={this.renderField}
        />
        <Field
          label="Last Name"
          name="lastName"
          type="text"
          required="required"
          component={this.renderField}
        />
        <Field
          label="Street Address"
          name="streetAddress"
          type="text"
          required="required"
          component={this.renderField}
        />
        <Field
          label="Apartment/Unit #"
          name="apartmentUnit"
          type="text"
          component={this.renderField}
        />
        <Field
          label="City"
          name="city"
          type="text"
          required="required"
          disabled="disabled"
          component={this.renderField}
        />
        <Field
          label="State"
          name="state"
          type="text"
          required="required"
          disabled="disabled"
          component={this.renderField}
        />
        <Field
          label="Zip Code"
          name="zip"
          type="text"
          required="required"
          component={this.renderField}
          {...zipMask}
          />
        <Field
          label="Primary/Home Phone"
          name="homePhone"
          type="text"
          required="required"
          component={this.renderField}
          {...phoneMask}
          />
        <Field
          label="Mobile Phone"
          name="mobilePhone"
          type="text"
          component={this.renderField}
          {...phoneMask}
          />
        <Field
          label="Email"
          name="emailAddress"
          type="text"
          required="required"
          component={this.renderField}
          />
        <Field
          label="Comments"
          name="comments"
          type="text"
  				textarea = {true}
          component={this.renderField}
          />
        </div>
        </Grid.Column>

        <Grid.Column verticalAlign='middle'>
         <Divider horizontal>
         <Header as='h4' color='violet'>
         <Icon name='address card' />
         Senior Emergency Contact Detail
         </Header>
         </Divider>

        <div className="form-row" >
          <Field
            label="First Name"
            name="emgFirstName"
            type="text"
            required="required"
            component={this.renderField}
          />
          <Field
            label="Last Name"
            name="emgLastName"
            type="text"
            required="required"
            component={this.renderField}
          />
          <Field
            label="Street Address"
            name="emgStreetAddress"
            type="text"
            required="required"
            component={this.renderField}
          />
          <Field
            label="Apartment/Unit #"
            name="emgApartmentUnit"
            type="text"
            component={this.renderField}
          />
          <Field
            label="City"
            name="emgCity"
            type="text"
            required="required"
            component={this.renderField}
          />
          <Field
            label="State"
            name="emgState"
            type="text"
            options={states}
            //onChange={this.handleChange}
            required="required"
            component={this.renderSelect}
          />
          <Field
            label="Zip Code"
            name="emgZip"
            type="text"
            required="required"
            component={this.renderField}
            {...zipMask}
            />
          <Field
            label="Primary/Home Phone"
            name="emgHomePhone"
            type="text"
            required="required"
            component={this.renderField}
            {...phoneMask}
            />
          <Field
            label="Mobile Phone"
            name="emgMobilePhone"
            type="text"
            component={this.renderField}
            {...phoneMask}
            />
          <Field
            label="Email"
            name="emgEmailAddress"
            type="text"
            required="required"
            component={this.renderField}
            />
          <Field
            label="Relationship"
            name="emgRelationship"
            type="text"
            required="required"
            component={this.renderField}
            />
          </div>

          </Grid.Column>
          </Grid>

        </Segment>

        <div className="text-center">

        {
          !ie &&
        <Field
          name="recaptcha"
          component={this.renderRecaptchaField}
        />
        }
          <button type="submit" className="btn btn-primary">Submit</button>
          <button type="button" className="btn btn-danger" disabled={pristine || submitting} onClick={reset}>Clear Values</button>
        </div>
      </div>
      </form>
    );
  }
}

function validate(values) {
  const { firstName, emgFirstName, lastName, emgLastName,
    streetAddress, emgStreetAddress, city, emgCity, state, emgState,
    zip, emgZip, homePhone, emgHomePhone, emgRelationship,
    emailAddress, emgEmailAddress,recaptcha } = values;
  const errors = {};

  // Validate the inputs from 'values'
  if (!firstName) {
    errors.firstName = "Please enter your first name";
  }else if (firstName.length > 20 ) {
    errors.firstName = "20 character limit on on First name";
  }
  if (!emgFirstName) {
    errors.emgFirstName = "Please enter your emergency first name";
  }else if (emgFirstName.length > 20 ) {
    errors.emgFirstName = "20 character limit on on First name";
  }


  if (!lastName) {
    errors.lastName = "Please enter your last name";
  }else if (lastName.length > 20 ) {
    errors.lastName = "20 character limit on on Last name";
  }
  if (!emgLastName) {
    errors.emgLastName = "Please enter your emergency last name";
  }else if (emgLastName.length > 20 ) {
    errors.emgLastName = "20 character limit on on Last name";
  }

  if (!streetAddress) {
    errors.streetAddress = "Please enter your street address";
  }
  if (!emgStreetAddress) {
    errors.emgStreetAddress = "Please enter your emergency street address";
  }


  if (!city) {
    errors.city = "Please enter your city";
  }
  if (!emgCity) {
    errors.emgCity = "Please enter your emergency city";
  }

  if (!state) {
    errors.state = "Please enter your state";
  }
  if (!emgState) {
    errors.emgState = "Please enter your emergency state";
  }


  if (!zip) {
    errors.zip = 'Please enter your zip code.';
  }else if (!/^\d{5}$/i.test(zip)) {
    errors.zip = 'Invalid zip code, must be 5 digits'
  }
  if (!emgZip) {
    errors.emgZip = 'Please enter your emergency zip code.';
  }else if (!/^\d{5}$/i.test(emgZip)) {
    errors.emgZip = 'Invalid zip code, must be 5 digits'
  }

  if (!homePhone) {
    errors.homePhone = 'Please enter your primary/home phone.';
  }else if (isNaN(Number(homePhone))) {
    errors.homePhone = 'Must be a number'
  }else if (!/^([0-9]{10})$/i.test(homePhone)) {
    errors.homePhone = 'Invalid phone number, must be 10 digits'
  }else if(!/^([2-9][0-8][0-9][2-9][0-9]{6})$/i.test(homePhone)){
    errors.homePhone = 'Invalid area code or central office'
  }
  if (!emgHomePhone) {
    errors.emgHomePhone = 'Please enter your emergency primary/home phone.';
  }else if (isNaN(Number(emgHomePhone))) {
    errors.emgHomePhone = 'Must be a number'
  }else if (!/^([0-9]{10})$/i.test(emgHomePhone)) {
    errors.emgHomePhone = 'Invalid phone number, must be 10 digits'
  }else if(!/^([2-9][0-8][0-9][2-9][0-9]{6})$/i.test(emgHomePhone)){
    errors.emgHomePhone = 'Invalid area code or central office'
  }

  if (!emgRelationship) {
    errors.emgRelationship = "Please enter the emergency relationship";
  }

  if (!emailAddress) {
    errors.emailAddress = 'Please enter your email.';
  }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailAddress)) {
    errors.emailAddress = 'Invalid email address'
  }

  if (!emgEmailAddress) {
    errors.emgEmailAddress = 'Please enter your emergency email.';
  }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emgEmailAddress)) {
    errors.emgEmailAddress = 'Invalid email address'
  }

  if (!recaptcha) {
    errors.recaptcha = 'reCAPTCHA required.';
  }

  // If errors is empty, the form is fine to submit
  // If errors has *any* properties, redux form assumes form is invalid
  return errors;
}


export default reduxForm({
  validate,
  form: "contactForm"
})(connect(null, { createContact, sendEmails})(ContactForm));
