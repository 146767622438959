import React, { Component } from "react";
import { change, Field, reduxForm } from "redux-form";
import Select2 from "../common/Select2.jsx";
import { Button } from "react-bootstrap";
import auth from "../authentificate/auth.js";

class TemparoryListForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: auth.getLoggedUserDetailsFromLocal(),
    };
  }

  render() {
    const {
      handleSubmit,
      title,
      InactiveStatus,
      titleOptions,
      InActiveStatusOptions,
      handleFilterTitleChange,
      handleFilterInActiveStatusChange,
    } = this.props;

    if (title) {
      this.props.dispatch(change("temparory_report_filter_form", "Title", title)); 
    }
    if (InactiveStatus) {
      this.props.dispatch(change("temparory_report_filter_form", "InactiveStatus", InactiveStatus)); 
    }
    const handleReset = () => {
      window.location.reload();
    };
    return (
      <form className="row" onSubmit={handleSubmit}>
        <Field
          id="Title"
          name="Title"
          label="Title"
          placeholder="Select Title"
          component={Select2}
          options={titleOptions}
          wrapperClassName="col-md-4"
          isClearable={true}
          handleChange={handleFilterTitleChange}
        />
        <Field
          id="InactiveStatus"
          name="InactiveStatus"
          label="Inactive Status"
          placeholder="Inactive Status"
          component={Select2}
          options={InActiveStatusOptions}
          wrapperClassName="col-md-4"
          isClearable={true}
          handleChange={handleFilterInActiveStatusChange}
        />
        <div className="col-md-2 pt-4">
          <Button
            variant="primary"
            onClick={handleSubmit}
            style={{ height: 40, margin: "auto" }}
          >
            {"Search"}
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="secondary"
            onClick={() => handleReset()}
            style={{ height: 40, margin: "auto" }}
          >
            Clear All
          </Button>
        </div>
        <div className="col-md-2"></div>
      </form>
    );
  }
}

TemparoryListForm = reduxForm({
  form: "temparory_report_filter_form",
})(TemparoryListForm);

export default TemparoryListForm;
