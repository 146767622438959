import React from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from "react-router-dom";

// The Footer2
class Footer2 extends React.Component{
	constructor(props){
    super(props);
		this.state = {
			date: new Date()
		};
  }

	render(){
		var date = this.state.date.getFullYear();

		return(
			<footer className="footer font-small mdb-color darken-3 pt-4 font-weight-bold">
			<div className="footer-copyright text-center py-3">
			<div>&nbsp;&nbsp;&nbsp;© {date} Copyright:
			<a href="http://www.ozitus.org/" target="_blank" >Ozitus</a>
			<br/><Link to="/disclaimer" target="_blank">Disclaimer</Link>
			<br/>Powered by <a href="http://www.ozitus.org/" target="_blank" >Ozitus Inc</a></div>
			</div>
			</footer>
			)
	}
}

export default withRouter(Footer2);
