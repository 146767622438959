/**
 * email_index.jsx file
 */
import React from "react";
import {Link} from 'react-router-dom';
import {withRouter} from "react-router-dom";
import ScrollUpButton from "react-scroll-up-button";
import {Nav, Navbar} from 'react-bootstrap';

import Header from '../header.jsx';
import Footer from '../footer.jsx';
import EmailForm from './emailForm.js';

class EmailIndex extends React.Component{

	constructor(props){
		super(props);
	}

	componentDidMount() {
		document.title = "CCPD APP - Email"
	}

	render(){

		return(
			<div>
			<Header />

      <Navbar bg="secondary" expand="lg">
        <Navbar.Brand href="/apply">CCPD Apply Form</Navbar.Brand>
      </Navbar>

			<EmailForm />

			<Footer />
		  <ScrollUpButton ContainerClassName="ScrollUpButton__Container"/>
		  </div>
			)
	}
}


export default withRouter(EmailIndex);
