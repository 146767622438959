
import React, { Component } from "react";
import { Badge, Nav, Navbar } from "react-bootstrap";
import auth from "../authentificate/auth";
import { TablePagination } from "@material-ui/core";
import "./report.css";
import { ExportToCsv } from "export-to-csv";
import * as jsPDF from "jspdf";
import { connect, formValueSelector, change } from "react-redux";
import MaterialTable from "material-table";
import moment from "moment";
import TemparoryListForm from "./temporary.report.jsx";
import { fetchInActiveStatus, fetchEmployeeTitlev2, InactiveReports} from "../../actions";
import Header from "../header.jsx";
import { Link } from "react-router-dom";

class TemparoryInactiveReport extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      status:this.props.match.params && this.props.match.params?.status || null,
      statusLabel:null,
      title:null,
      titleLabel:null,
      data: [],
      page: 0,
      clearPage: false,
      rowperpage: 20,
      total: 0,
      orderBy: 0,
      orderDirection: "desc",
      user: auth.getLoggedUserDetailsFromLocal()
    };
  }

  componentDidMount() {
    this.props.getTitle(0)
    this.props.getInActiveStatus()
    
  }
  generateData = (amount) => {
    var result = [];

    for (var i = 0; i < this.state.data.length; i += 1) {
      const row = this.state.data[i];
      result.push({
        Title: row.TitleName || "",
        FirstName: row.FirstName,
        LastName: row.LastName || "",
        Bureau: row.BureauName || "",
        Division: row.DivisionName || "",
        Unit: row.UnitName && row.UnitName?.replace("$","") || "",
        InactiveStatus: row.Status

      });
    }
    return result;
  };

  exportPDF = () => {
    var doc = new jsPDF({ orientation: "landscape" });
    var col = [
      "Title",
      "First Name",
      "Last Name",
      "Bureau",
      "Division",
      "Unit",
      "Inactive Status",
    ];
    var rows = [];

    this.state.data.forEach((row) => {
      var temp = [
         row.TitleName || "",
         row.FirstName,
         row.LastName || "",
         row.BureauName || "",
         row.DivisionName || "",
         row.UnitName && row.UnitName?.replace("$","") || "",
         row.Status
      ]
      rows.push(temp);
    });

    doc.autoTable(col, rows, { startY: 10 });
    doc.save(moment(new Date().getTime()) + ".pdf");

  };

  downloadCSV = () => {
    const data = this.state.data;
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      filename: moment(new Date().getTime())+".csv",
      showLabels: true,
      showTitle: true,
      title: "Temparory Inactive Report",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const mapped = data.map((row) => {

      return {
        Title: row.TitleName || "",
        FirstName: row.FirstName,
        LastName: row.LastName || "",
        Bureau: row.BureauName || "",
        Division: row.DivisionName || "",
        Unit: row.UnitName && row.UnitName?.replace("$","") || "",
        InactiveStatus: row.Status
      };
    });
    const exporter = new ExportToCsv(options);
    exporter.generateCsv(mapped);
  };


  getTableColumns = () => {
    const columns = [
      {
        title: "Title",
        dbColumn: "CCPD_Employees.Title",
        field: "CCPD_Employees_Title",
        dataType: "string",
        render: ({ TitleName}) => {
          return <span>{`${TitleName || ''}`}</span>;
        },
      },
      {
        title: "First Name",
        dbColumn: "CCPD_Employees.FirstName",
        field: "CCPD_Employees_FirstName",
        dataType: "string",
        render: ({ FirstName}) => {
          return <span>{`${FirstName}`}</span>;
        },
      },
      {
        title: "Last Name",
        dbColumn: "CCPD_Employees.LastName",
        field: "CCPD_Employees_LastName",
        dataType: "string",
        render: ({ LastName}) => {
          return <span>{`${LastName || ""}`}</span>;
        },
      },
      {
        title: "Bureau",
        dbColumn: "CCPD_Employees.BureauName",
        field: "CCPD_Employees_BureauName",
        dataType: "string",
        render: ({ BureauName}) => {
          return <span>{`${BureauName || "" }`}</span>;
        },
      },
      {
        title: "Division",
        dbColumn: "CCPD_Employees.DivisionName",
        field: "CCPD_Employees_DivisionName",
        dataType: "string",
        render: ({ DivisionName}) => {
          return <span>{`${DivisionName || ""}`}</span>;
        },
      },
      {
        title: "Unit",
        dbColumn: "CCPD_Employees.UnitName",
        field: "CCPD_Employees_UnitName",
        dataType: "string",
        render: ({ UnitName}) => {
          return <span>{`${UnitName && UnitName?.replace("$","") || ""}  `}</span>;
        },
      },
      {
        title: "Inactive Status",
        dbColumn: "CCPD_Employees.Status",
        field: "CCPD_Employees_Status",
        dataType: "string",
        render: ({ Status}) => {
          return <Badge variant="success">{`${Status}`}</Badge>;
        },
      },
    ];
    return columns;
  };

  getHiddenTableColumns = () => {
    const columns = [];
    return columns;
  };


  handleFilterTitleChange = (valueObj, action) => {
    if(!valueObj){
    this.setState({ title: null , titleLabel:{value:null, label: null}});
    }else
    this.setState({ title: valueObj && valueObj.value , titleLabel:{value:valueObj.value, label: valueObj.label}});
  };


  handleFilterInActiveStatusChange = (valueObj, action) => {
    if(!valueObj){
      this.setState({ status: null , statusLabel:{value:null, label:null}});
    }else
    this.setState({ status: valueObj.value , statusLabel:{value:valueObj.value, label:valueObj.label}});
  };

  tableTitle = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <i
          className="fa fa-arrow-left btn"
          onClick={() => this.props.history.goBack()}
        ></i>
        <h5 style={{ textTransform: "capitalize", marginBottom:"22px" }}>Temparory Inactive Report List</h5>
      </div>
    );
  };

  loadPreviousPage = (data) => {
    const page = this.state.page - 1;
    this.setState({ page }, () => {
      this.tableRef.current.onQueryChange();
    });
  };
  loadNextPage = (data) => {
    const page = this.state.page + 1;
    this.setState({ page }, () => {
      this.tableRef.current.onQueryChange();
    });
  };
  handleSubmit = (data) => {
    this.setState({ page: 0 });
    this.tableRef.current.onQueryChange();
  };

  render() {
    let {
      titleOptions,
      InActiveStatusOptions
    } = this.props;

    return (
      <> 
      <Header />
        <Navbar bg="secondary" expand="lg">
          <Navbar.Brand href="/hr">
            <b>CCPD Temporary Inactive</b>
          </Navbar.Brand>
          <Navbar />
          <Nav className="mr-auto nav-link-wrap">
            <Link to="/hr" className="ml-2 mr-2">
              <span style={{ color: "black" }}>
                <b>
                  <u>Home</u>
                </b>
              </span>
            </Link>
          </Nav>
        </Navbar>
      <br/>
        <TemparoryListForm
          onSubmit={this.handleSubmit}
          titleOptions={titleOptions}
          InActiveStatusOptions={InActiveStatusOptions}
          handleFilterInActiveStatusChange={this.handleFilterInActiveStatusChange}
          handleFilterTitleChange={this.handleFilterTitleChange}
          InactiveStatus={this.state.statusLabel}
          title = {this.state.titleLabel}
        />

        <MaterialTable
          title={this.tableTitle()}
          tableRef={this.tableRef}
          columns={this.getTableColumns()}
          data={(query) =>
           
            new Promise((resolve, reject) => {
              const payload = {
                status: this.state.status,
                title: this.state.title,
                page: this.state.page,
                rowperpage: this.state.rowperpage,
                orderBy: this.state.orderBy,
                orderDirection: this.state.orderDirection,
                searchText: query?.search
              };
             
              InactiveReports(payload)
                .then((res) => {
                  const { data, total } = res;
                  this.setState(
                    { data: data.totalData, total: data.total },
                    () => {}
                  );
                  resolve({
                    data: data.data,
                    page: this.state.page,
                    totalCount: data.total,
                  });
                })
                .catch((err) => {
                  reject({ data: [], page: 0, totalCount: 0 });
                });
            })
          }
        
          options={{
            pageSize:
              this.state.total < this.state.rowperpage
                ? this.state.total
                : this.state.rowperpage,
            pageSizeOptions: [20, 40, 60, 80, 100],
            paginationPosition: "both",
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: "#01579b",
              color: "#FFF",
            },
            detailPanelColumnAlignment: "left",
            exportButton: true,
            exportAllData: true,
            exportCsv: (data, columns) => this.downloadCSV(),
            exportPdf: (data, columns) => this.exportPDF(),
            debounceInterval: 700,
            search:true
          }}
          onOrderChange={(orderBy, orderDirection) => {
            this.setState({ orderBy, orderDirection });
          }}

          components={{
            Pagination: (props) => {
              return (
                <div style={{ float: "right" }}>
                  <TablePagination
                    count={this.state.total}
                    rowsPerPage={this.state.rowperpage}
                    rowsPerPageOptions={[20, 40, 60, 80, 100]}
                    page={this.state.page}
                    backIconButtonProps={{
                      "aria-label": "Previous Page",
                      onClick: this.loadPreviousPage,
                    }}
                    nextIconButtonProps={{
                      "aria-label": "Next Page",
                      onClick: this.loadNextPage,
                    }}
                    onChangeRowsPerPage={(value) =>
                      this.setState({ rowperpage: value.target.value }, () => {
                        this.tableRef.current.onQueryChange();
                      })
                    }
                  />
                </div>
              );
            },
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    titleOptions: state.hrTitlev2[0],
    InActiveStatusOptions: state.InactiveStatus
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTitle: (status) => dispatch(fetchEmployeeTitlev2(status)),
    getInActiveStatus: () => dispatch(fetchInActiveStatus()),
    changeFieldValue: (form, field, value) => dispatch(change(form, field, value))
  };
};

TemparoryInactiveReport = connect(mapStateToProps, mapDispatchToProps)(TemparoryInactiveReport);

export default TemparoryInactiveReport;
