import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import Swal from "sweetalert2";
import { getEmployees, ROOT_URL } from "../../actions";
import { DEFAULT_PAGE_SIZE } from "../../util/constants";
import "./style.css"
//Results is used to combine app component and result component
class HRResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      w: screen.width,
    };
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({ w: screen.width });
  };

  handleClickInfo = ({ CCPD_Employees_EmployeeId }) => {
    const { history } = this.props;
    history.push(`/hr/${CCPD_Employees_EmployeeId}`);
  };

  handleClickPhoto = (rowData, pNumber, pText) => {
    Swal.queue([
      {
        title:
          rowData.CCPD_Employees_LastName_CCPD_Employees_FirstName_CCPD_Employees_Title_CCPD_Employees_BadgeNumber,
        confirmButtonText: "Confirm",
        text: `${pText} photo`,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(
            `${ROOT_URL}/hr/${pNumber}/${rowData.CCPD_Employees_EmployeeId}`
          )
            .then((response) => response.json())
            .then((data) =>
              Swal.fire({
                html: "<img src='" + data.Image + "' style='width:50%;'>",
              })
            )
            .catch(() => {
              Swal.insertQueueStep({
                icon: "error",
                title: `Unable to get the ${pText} photo`,
              });
            });
        },
      },
    ]);
  };

  formatResultToLookup = (result) => {
    const response = {};
    if (result) {
      result.forEach((r) => {
        if (r.label && r.label.indexOf("$") < 0) {
          const val = r.label.replace("$", "");
          response[val] = val;
        }
      });
    }
    return response;
  };

  getTableColumns = () => {
    const { bureaus, divisions, units } = this.props;
    const bureauLookup = this.formatResultToLookup(bureaus);
    const divisionLookup = this.formatResultToLookup(divisions);
    const unitLookup = this.formatResultToLookup(units);
    const columns = [
      {
        title: "Profile Pic",
        dbColumn: "CCPD_Employees.UserName",
        field: "CCPD_Employees_UserName",
        dataType: "string",
        render: ({ CCPD_Employees_UserName}) => {
          const url = process.env.REACT_APP_CCPD_API_URL+"/profile/"+CCPD_Employees_UserName+"_thumb.png"
          console.log(CCPD_Employees_UserName, url)
          return <div className="img-cricle"><img alt="Image Not Founddd dddddddd" className="img" src={CCPD_Employees_UserName ? url : "https://www.seekpng.com/png/detail/41-410093_circled-user-icon-user-profile-icon-png.png"} /></div>;
          // CCPD_Employees_EmployeeId[0]
        },
      },
      {
        title: "Employee Name",
        dbColumn:
          "CCPD_Employees.LastName|CCPD_Employees.FirstName|Title_3.Title|CCPD_Employees.BadgeNumber",
        field:
          "CCPD_Employees_LastName_CCPD_Employees_FirstName_Title_3_Title_CCPD_Employees_BadgeNumber",
        concatMask: "x, x, x [x]",
        dataType: "string",
        defaultSort: "asc",
        render: ({
          CCPD_Employees_EmployeeId,
          CCPD_Employees_LastName_CCPD_Employees_FirstName_Title_3_Title_CCPD_Employees_BadgeNumber,
        }) => {
          if (
            CCPD_Employees_LastName_CCPD_Employees_FirstName_Title_3_Title_CCPD_Employees_BadgeNumber
          ) {
            return (
              <Link to={`/hr/${CCPD_Employees_EmployeeId}`}>
                {CCPD_Employees_LastName_CCPD_Employees_FirstName_Title_3_Title_CCPD_Employees_BadgeNumber !=
                ", ,  []"
                  ? CCPD_Employees_LastName_CCPD_Employees_FirstName_Title_3_Title_CCPD_Employees_BadgeNumber.indexOf(
                      "[]"
                    ) > 0
                    ? CCPD_Employees_LastName_CCPD_Employees_FirstName_Title_3_Title_CCPD_Employees_BadgeNumber.replace(
                        "[]",
                        ""
                      )
                    : CCPD_Employees_LastName_CCPD_Employees_FirstName_Title_3_Title_CCPD_Employees_BadgeNumber
                  : ""}
              </Link>
            );
          }
          return null;
        },
        cellStyle: {
          backgroundColor: "lightBlue",
          textDecoration: "underline",
          textDecorationColor: "blue",
          //color: '#FFF'
        },
      },
      {
        title: "Bureau",
        dbColumn: "Department_0.Title",
        field: "Department_0_Title",
        dataType: "string",
        lookup: bureauLookup,
        compareExactValue: true,
        render: ({ Department_0_Title }) => {
          if (Department_0_Title && Department_0_Title.indexOf("$") < 0) {
            return <span>{Department_0_Title}</span>;
          }
          return null;
        },
      },
      {
        title: "Division",
        dbColumn: "Department_1.Title",
        field: "Department_1_Title",
        dataType: "string",
        lookup: divisionLookup,
        compareExactValue: true,
        render: ({ Department_1_Title }) => {
          if (Department_1_Title && Department_1_Title.indexOf("$") < 0) {
            return <span>{Department_1_Title}</span>;
          }
          return null;
        },
      },
      {
        title: "Unit",
        dbColumn: "Department_2.Title",
        field: "Department_2_Title",
        dataType: "string",
        lookup: unitLookup,
        compareExactValue: true,
        render: ({ Department_2_Title }) => {
          if (Department_2_Title && Department_2_Title.indexOf("$") < 0) {
            return <span>{Department_2_Title}</span>;
          }
          return null;
        },
      },
    ];
    return columns;
  };

  getHiddenTableColumns = () => {
    const columns = [
      {
        dbColumn: "CCPD_Employees.EmployeeId",
        dataType: "int",
        skipFromFilter: true,
      },
    ];
    return columns;
  };

  fetchTableData = (query) => {
    const columns = [
      ...this.getTableColumns(),
      ...this.getHiddenTableColumns(),
    ];
    const joins = [
      {
        type: "LEFT",
        table: "Department",
        refTable: "CCPD_Employees",
        id: "Id",
        refId: "Bureau",
      },
      {
        type: "LEFT",
        table: "Department",
        refTable: "CCPD_Employees",
        id: "Id",
        refId: "Division2",
      },
      {
        type: "LEFT",
        table: "Department",
        refTable: "CCPD_Employees",
        id: "Id",
        refId: "Unit2",
      },
      {
        type: "LEFT",
        table: "Title",
        refTable: "CCPD_Employees",
        id: "ID",
        refId: "Title2",
      },
      {
        type: "LEFT",
        table: "CCPD_EmployeesImages",
        refTable: "CCPD_Employees",
        id: "EmployeeId",
        refId: "EmployeeId",
      },
    ];
    const payload = {
      ...query,
      table: "CCPD_Employees",
      primaryKey: "EmployeeId",
      fields: columns,
      joins,
      additionalWhere: [
        {
          dbColumn: "CCPD_Employees.Status",
          value:
            "NOT IN ('TERMINATED', 'RETIRED', 'RESIGNED', 'CONTRACT EXPIRED', 'DECEASED')",
        },
      ],
    };
    return new Promise((resolve, reject) => {
      getEmployees(payload)
        .then((res) => {
          const { data } = res;
          resolve({ ...data });
        })
        .catch((err) => {
          reject({ data: [], page: 0, totalCount: 0 });
        });
    });
  };

  render() {
    const { w } = this.state;
    const mobileViewAttributes = {};
    const webViewAttributes = {};
    const mobile = w <= 768;
    if (mobile) {
      mobileViewAttributes.detailPanel = [
        {
          icon: "chevron_right",
          tooltip: "More",
          iconProps: { color: "secondary" },
          render: (rowData) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  width: "50%",
                  padding: "10px 20px",
                }}
              >
                <button style={{ background: "transparent", border: 0 }}>
                  <i className="material-icons">info</i>
                </button>
                <button style={{ background: "transparent", border: 0 }}>
                  <i className="material-icons">face</i>
                </button>
                <button style={{ background: "transparent", border: 0 }}>
                  <i className="material-icons">account_circle</i>
                </button>
              </div>
            );
          },
        },
      ];
    } else {
      webViewAttributes.actions = [
        {
          icon: "info",
          tooltip: "Show Info",
          onClick: (event, rowData) => this.handleClickInfo(rowData),
        },
        {
          icon: "face",
          tooltip: "half-length photo",
          onClick: (event, rowData) =>
            this.handleClickPhoto(rowData, "photo1", "half-length"),
        },
        {
          icon: "account_circle",
          tooltip: "full-length photo",
          onClick: (event, rowData) =>
            this.handleClickPhoto(rowData, "photo2", "full-length"),
        },
      ];
    }
    return (
      <>
        <MaterialTable
          title="HRMS"
          tableRef={this.tableRef}
          columns={this.getTableColumns()}
          data={this.fetchTableData}
          options={{
            pageSize: DEFAULT_PAGE_SIZE,
            pageSizeOptions: [20, 50, 100],
            paginationPosition: "both",
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: "#01579b",
              color: "#FFF",
            },
            filtering: true,
            detailPanelColumnAlignment: "left",
            searchText: "",
            initialPage: 0,
            debounceInterval: 700,
          }}
          {...webViewAttributes}
          {...mobileViewAttributes}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bureaus: state.hrBureaus[0],
    divisions: state.hrDivisions[0],
    units: state.hrUnits[0],
  };
};

HRResults = connect(mapStateToProps)(HRResults);

export default withRouter(HRResults);
