import _ from "lodash";
import {
  FETCH_EMPLOYEES,
  FETCH_EMPLOYEE,
  RESET_EMPLOYEE_STATE,
} from "../actions";

export default function (state = [], action) {
  switch (action.type) {
    case FETCH_EMPLOYEES:
      return action.payload.data;
    case FETCH_EMPLOYEE:
      return [action.payload.data];
    case RESET_EMPLOYEE_STATE:
      return [];
  }
  return state;
}
