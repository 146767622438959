import React from "react";
import {withRouter} from 'react-router-dom';
import Auth from './auth.js';

const AuthButton = withRouter(
  ({ history }) =>
  Auth.isAuthenticated() == "true" ? (
    <div className="justify-content-end form-inline">
    <span className="control-label">Welcome! {Auth.whatUsername()}</span>&nbsp;
    <button className="btn btn-sm btn-secondary input-group-btn" id="logout"
    onClick={() => {
      Auth.logout(() => history.push("/authentificate"));
    }}>
    Sign out
    </button>
    </div>) : (
    <div> </div>
  )
);

export default AuthButton;
