import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "./sidepanel.css";
import auth from "../authentificate/auth";
import { Image } from "semantic-ui-react";
import ccpd from "../../../images/ccpd.png";
import home from "../../../images/home.png";
import Swal from "sweetalert2";
import rightarrow from "../../../images/right-arrow.png";
import dashboard from "../../../images/dashboard1.png";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import camerainspectionuser from "../../../images/camera-inspection-user.png"
class Sidepanel extends Component {
  constructor(props) {
    super(props);
    this.state = { v: false, cp: localStorage.getItem("currentPage") };
  }

  toggleSidebar() {
    if (!this.state.v) {
      document.getElementById("replace").classList.add("w-97");
      document.getElementById("replace").classList.remove("w-85");
      document.getElementById("remove-w").classList.remove("w-15");
    } else {
      document.getElementById("replace").classList.add("w-85");
      document.getElementById("remove-w").classList.add("w-15");
      document.getElementById("replace").classList.remove("w-97");
    }
    this.setState({ v: !this.state.v });
  }
  handleClickCard = (Card) => {
    if (Card == "Home") {
      this.setState({ cp: Card, v: false });
      document.getElementById("replace").classList.add("w-85");
      document.getElementById("remove-w").classList.add("w-15");
      document.getElementById("replace").classList.remove("w-97");
    } else if (Card != "Home") {
      this.setState({ cp: Card, v: true });
      document.getElementById("replace").classList.add("w-97");
      document.getElementById("replace").classList.remove("w-85");
      document.getElementById("remove-w").classList.remove("w-15");
    } else {
      this.setState({ cp: Card, v: true });
      document.getElementById("replace").classList.add("w-85");
      document.getElementById("remove-w").classList.add("w-15");
      document.getElementById("replace").classList.remove("w-97");
    }

    localStorage.setItem("currentPage", Card);
  };

  getTooltipContent = (message, props) => {
    return <Tooltip {...props}>{message}</Tooltip>;
  };

  handleDashboardSelect() {
    Swal.fire({
      title: "Select a report",
      input: "select",
      inputOptions: {
        1: "Accrued Leave Balances",
        2: "Temporary Inactive Record",
      },
      inputPlaceholder: "Select a report",
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          const ROOT_URL =
            "http://ccpd-sqlsrv1/Reports/Pages/Report.aspx?ItemPath=";
          switch (Number(value)) {
            case 1:
              window.open(`/leave-accruals-pay-date`,"_self");
              Swal.close();
              break;
            case 2:
              window.open(`/temparory-inactive-report`,"_self");
              Swal.close();
              break;
            default:
              resolve("You need to select a report");
          }
        });
      },
    });
  }

  render() {
    const { cp, v } = this.state;

    return (
      <>
        <div
          className={`sidepanel desk-view  ${
            this.state.v ? "sidenavClosed" : "sidebar-menu"
          }`}
        >
          <ul className="pt-4">
            <li>
              <Link onClick={() => this.toggleSidebar()} to={"#"}>
                <div
                  className="collapse-btn"
                  style={this.state.v ? { left: "47%" } : { left: "89%" }}
                >
                  <Image src={rightarrow} className="colImg" />
                </div>
              </Link>
            </li>

            <li
              style={
                this.state.v
                  ? { position: "relative", top: "-7px" }
                  : {
                      position: "relative",
                      top: "-20px",
                      borderBottom: "1px solid rgb(126 120 120)",
                    }
              }
            >
              <Link to="/">
                <Image
                  src={ccpd}
                  style={
                    this.state.v
                      ? {
                          width: "70px",
                          height: "33px",
                          paddingTop: "3px",
                          paddingLeft: "0px",
                          paddingRight: "5px",
                          top: "10%",
                        }
                      : { width: "auto", height: "auto" }
                  }
                  roundedCircle
                />
              </Link>
            </li>
            <OverlayTrigger
              placement="right"
              overlay={this.getTooltipContent("Employee Profile")}
            >
              <li>
                <Link to="/hr" onClick={() => this.handleClickCard("Employee Profile")}>
                  <Image src={home} roundedCircle className="resize" />
                  &nbsp;&nbsp;
                  <span className={this.state.v ? "sidebar-text" : ""}>
                    Employee Profile
                  </span>
                </Link>
              </li>
            </OverlayTrigger>
            <OverlayTrigger
              placement="right"
              overlay={this.getTooltipContent("Dashboard")}
            >
              <li key="3">
                <Link to="/dashboard-reports" >
                  <Image src={dashboard} roundedCircle className="resize" />
                  &nbsp;&nbsp;
                  <span className={this.state.v ? "sidebar-text" : ""}>
                    Dashboard 
                  </span>
                </Link>
              </li>
            </OverlayTrigger>
            <OverlayTrigger
              placement="right"
              overlay={this.getTooltipContent("Camera Inspection Survey")}
            >
              <li key="3">
                <Link to="/camera-inspection-survey" >
                  <Image src={camerainspectionuser} roundedCircle className="resize" />
                  &nbsp;&nbsp;
                  <span className={this.state.v ? "sidebar-text" : ""}>
                    Camera Inspection Survey
                  </span>
                </Link>
              </li>
            </OverlayTrigger>
          </ul>
        </div>
      </>
    );
  }
}

export default withRouter(Sidepanel);
