/**
 * app.jsx file
 */
import React from "react";
import {connect} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import ScrollUpButton from "react-scroll-up-button";
import ReactTable from "react-table";
import Header from '../header.jsx';
import Footer from '../footer.jsx';

import {fetchPoliciesOptions} from '../../actions';

class PolicyListTable extends React.Component{
  constructor(props){
		super(props);
    this.onClickBack = this.onClickBack.bind(this);
	}

	componentDidMount(){
		document.title = "CCPD APP - Policy"
		this.props.fetchPoliciesOptions();
	}

	onClickBack(){
		this.props.history.goBack();
  }

	render(){
    const options = _.values(this.props.policiesOptions);
		return(
			<div>
			<Header />

      <ReactTable
		     data={options}
         showPagination={false}
				 filterable
		     columns={[
		     	{ //Header: 'CCPD',
		     		columns: [
		     		{
		     			Header: 'Subject',
		     			headerStyle:{
		     				backgroundColor: '#dddddd',
		     			},
							id: "label",
							accessor: 'label',
              style: {
		     				cursor: "pointer",
                textAlign: 'center',
		     				color: 'blue'
		     			}
		     		},
            {
		     			Header: 'ID',
							id: "_id",
							accessor: '_id',
              show: false
		     		}]
		     	}
		     	]}
		     	defaultPageSize={10}
					defaultFilterMethod = {(filter, row, column) => {
						const id = filter.pivotId || filter.id
						return row[id] !== undefined ? String(row[id]).toLowerCase().startsWith(filter.value.toLowerCase()) : true
					}}
					getTdProps = {(state, rowInfo, column, instance) => {
					 return {
					   onClick: (e) => {
							 //console.log(rowInfo);
							 if(typeof rowInfo !== 'undefined'){
								 var name = rowInfo.row[column.id];
                 var id = rowInfo.row._id;
								 if(name == rowInfo.row.label){
						       this.props.history.push(`/policy/${id}`);
						     }
							 }
					   }
					 };
					}}
		     	className="-striped -highlight"
		     	/>

      <button type="button" className="btn btn-secondary" onClick={this.onClickBack}>Back</button>
			<Footer />
		  <ScrollUpButton ContainerClassName="ScrollUpButton__Container"/>
		  </div>
			)
	}
}

function mapStateToProps(state){
		return { policiesOptions: state.policiesOptions };
}

export default connect(mapStateToProps, {fetchPoliciesOptions})(withRouter(PolicyListTable));
