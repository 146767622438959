import React from 'react';
import Select from 'react-select';

const Select2 = ({ input, label, type, placeholder, disabled, options, isMulti, isClearable, requiredIdentifier, meta: { touched, error }, wrapperClassName, labelClassName, inputClassName, errorClassName, handleChange, closeMenuOnSelect, customStyles, classNamePrefix }) => {
  return (
    <div className={`form-group ${wrapperClassName ? wrapperClassName : ''}`}>
      {label &&
        <>
          <label className={`f-weight-bold ${labelClassName ? labelClassName : ''}`}>{label}</label>
          {requiredIdentifier &&
            <span className="font-weight-bolder text-danger">&nbsp;*</span>
          }
        </>
      }
      <Select
        className={`${inputClassName ? inputClassName : ''}`}
        classNamePrefix={`${classNamePrefix ? classNamePrefix : ''}`}
        placeholder={placeholder ? placeholder : label}
        options={options ? options : []}
        type={type}
        isDisabled={disabled ? true : false}
        isMulti={isMulti ? isMulti : false}
        isClearable={isClearable ? isClearable : false}
        closeMenuOnSelect={typeof closeMenuOnSelect !== 'undefined' ? closeMenuOnSelect : true}
        {...input}
        onChange={(value, triggeredAction) => {
          input.onChange(value)
          if (handleChange) {
            handleChange(value, triggeredAction);
          }
        }}
        onBlur={() => input.onChange(input.value)}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }), ...customStyles }}
      />
      {touched && error && <span className={`text-danger ${errorClassName ? errorClassName : ''}`}>{error}</span>}
    </div>
  );
};

export default Select2;