import React from "react";
import { Nav, Navbar, Container, Row, Card, Col, Table } from "react-bootstrap";
// import Accordion from 'react-bootstrap/Accordion';
import { Accordion, Icon } from "semantic-ui-react";
import Header from "../header.jsx";
import { Link } from "react-router-dom";
import "../common/sidepanel.css";
import { connect } from "react-redux";
import {
  fetchInactiveStatusCount,
  fetchStaffSummaryReport,
  fetchTempInactiveStatusReport,
} from "../../actions/index.js";
export default class Listing extends React.Component {
  componentDidMount() {
    this.props.fetchTempInactiveStatusReport();
  }
  state = { activeIndex: 0 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    if(index == 3){
      this.props.fetchStaffSummaryReport(1);
    }else if(index == 6){
      this.props.fetchStaffSummaryReport(0);
    }
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };
  render() {
    const { activeIndex } = this.state;
    let count = 0;
    let scount = 0;
    return (
      <>
        <Header />
        <Navbar bg="secondary" expand="lg">
          <Navbar.Brand href="/dashboard-reports/Listing">
            <b>Dashboard</b>
          </Navbar.Brand>
          <Navbar />
          {/* <Nav className="nav-link-wrap">
            <Link to="/" className="ml-2 mr-2">
              <span style={{ color: "black" }}>
                <b>
                  <u>Home</u>
                </b>
              </span>
            </Link>
          </Nav>
          <Nav className="nav-link-wrap">
            <Link to="/dashboard-reports/Listing" className="ml-2 mr-2">
              <span style={{ color: "black" }}>
                <b>
                  <u>Links</u>
                </b>
              </span>
            </Link>
          </Nav> */}
        </Navbar>
        <br />
        <Container fluid className="py-2" style={{height:"100vh"}}>
          <Row className="">
            <Col md="12" lg="12">
              <Accordion styled>
                <Accordion.Title
                  active={activeIndex === 2}
                  index={2}
                  onClick={this.handleClick}
                  className="bg-dark-color"
                  
                >
                  {"1. "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Link style={{color:"black"}}
                    to="/dashboard-reports/staffcount"
                    
                  >
                    Staff Count
                  </Link>
                </Accordion.Title>
                <Accordion.Title
                  active={activeIndex === 3}
                  index={3}
                  onClick={this.handleClick}
                  className="bg-color"
                >
                  {"2. "}
                  <Icon name="dropdown" />
                  <a href="javascript:void(0)" style={{color:"black", textDecoration:"none"}}>
                   Staff details by Sworn Officers ‘Position Title’
                  </a>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 3}>
                  <Table striped bordered hover>
                    <thead>
                      <tr><th colSpan={2} style={{textAlign:"center"}}>TOTAL SWORN STAFFING</th></tr>
                      <tr className="bg-dark-color">
                        <th>Position Title </th>
                        <th>Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.staffSummary &&
                        this.props.staffSummary.map((o,index) => {
                          count += o.u3
                         return (   <>
                          <tr key={o.job_title} className={index%2 == 1?"bg-dark-color":"bg-color"}>
                            <td>
                              {index+1}. &nbsp;&nbsp;&nbsp;
                              <Link
                                to={`/dashboard-reports/staffSummaryEmployeeListing/${o.job_title}/SWORN OFFICERS`}
                                className="text-decor"
                              >
                                {o.job_title}
                              </Link>
                            </td>
                            <td className="text-decor">{o.u3}</td>
                          </tr>
                        </>)
                        }
                          
                        )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th style={{textAlign:"right"}}>Total</th>
                        <th>{count}</th>
                      </tr>
                    </tfoot>
                  </Table>
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 6}
                  index={6}
                  onClick={this.handleClick}
                  className="bg-dark-color"
                >
                  {"3. "}
                  <Icon name="dropdown" />
                  <a href="javascript:void(0)" style={{color:"black", textDecoration:"none"}}>
                  Staff details by Non-Sworn Officers ‘Position Title’
                  </a>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 6}>
                  <Table striped bordered hover>
                  <thead>
                      <tr><th colSpan={2} style={{textAlign:"center"}}>TOTAL NON SWORN STAFFING</th></tr>
                      <tr className="bg-dark-color">
                        <th>Position Title </th>
                        <th>Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.staffSummary &&
                        this.props.staffSummary.map((o,index) => {
                          scount = o.Grp0 !=="SWORN OFFICERS" ? scount + o.u3 : scount
                           return(
                            <>
                                { o.Grp0 !=="SWORN OFFICERS" && <tr key={o.TTitle} className={index%2 == 1?"bg-dark-color":"bg-color"}>
                                <td>
                                  {index+1}. &nbsp;&nbsp;&nbsp;
                                  <Link
                                    to={`/dashboard-reports/staffSummaryEmployeeListing/${o.TTitle}/NON SWORN OFFICERS`}
                                    className="text-decor"
                                  >
                                    {o.TTitle}
                                  </Link>
                                </td>
                                <td className="text-decor">{o.u3}</td>
                              </tr>}
                            </>
                          )
                         })
                        }
                    </tbody>
                    <tfoot>
                      <tr>
                        <th style={{textAlign:"right"}}>Total</th>
                        <th>{scount}</th>
                      </tr>
                    </tfoot>
                  </Table>
                </Accordion.Content>
                {/* <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={this.handleClick}
                  className="bg-dark-color"
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a
                    href="http://ccpd-sqlsrv1/Reports/Pages/Report.aspx?ItemPath=%2fHRMS%2fStaffing+List"
                    target={"_blank"}
                  >
                    Staffing List Report
                  </a>
                </Accordion.Title> */}
                <Accordion.Title
                  active={activeIndex === 5}
                  index={5}
                  onClick={this.handleClick}
                  className="bg-color"
                >
                  {"4. "}
                  <Icon name="dropdown" />
                  <a href="javascript:void(0)" style={{color:"black", textDecoration:"none"}}>
                    Staff details by ‘Temporary Leave’
                  </a>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 5}>
                  <Table striped bordered hover>
                    <thead>
                      <tr className="bg-color">
                        <th>Title </th>
                        <th>Sworn</th>
                        <th>Non Sworn</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.tempinactiveLeave &&
                        this.props.tempinactiveLeave.map((o, index) => (
                          <>
                            <tr key={o.ReasonDescription} className={index%2 == 0?"bg-dark-color":"bg-color"}>
                              <td>
                              {index+1}. &nbsp;&nbsp;&nbsp;
                                <Link
                                  to={`/dashboard-reports/tempInActiveLeaveEmployeesList/${o.ReasonDescription}`}
                                  className="text-decor"
                                >
                                  {o.ReasonDescription}
                                </Link>
                              </td>
                              <td className="text-decor">{o.u2}</td>
                              <td className="text-decor">{o.u3}</td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </Table>
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 4}
                  index={4}
                  onClick={this.handleClick}
                  className="bg-dark-color"
                >
                  {"5. "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Link to="/temparory-inactive-report" style={{color:"black"}}>
                    Staff details by ‘Resigned / Terminated
                  </Link>
                </Accordion.Title>

                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={this.handleClick}
                  className="bg-color"
                >
                  {"6. "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Link to="/leave-accruals-pay-date" style={{color:"black"}}>
                    Accrued Leave Balances
                  </Link>
                </Accordion.Title>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { dashboardReports } = state;

  return {
    tempinactiveLeave: dashboardReports.tempinactiveLeave,
    staffSummary: dashboardReports.staffSummary
    
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTempInactiveStatusReport: () =>
      dispatch(fetchTempInactiveStatusReport()),
    fetchStaffSummaryReport: (grp) => dispatch(fetchStaffSummaryReport(grp))
    
  };
};

Listing = connect(mapStateToProps, mapDispatchToProps)(Listing);
