import React, { Component } from 'react'
import AuthButton from '../authentificate/authButton.js'
import Sidepanel from './Sidepanel.jsx'
import './sidepanel.css'
import { withRouter } from 'react-router-dom'
import TopBar from './TopBar.js'
 class Template extends Component {
  render() {
   return (
      <div className='w-100 main'>
        <div className='w-15' id='remove-w'>
           <Sidepanel />
        </div>
        <div className='w-85 ' id='replace'>
           <div className='desk-view'><AuthButton /></div>
           <div className='mob-view'><TopBar /></div>
           <div className='content'>{this.props.children}</div>
           
        </div>
      </div>
    )
  }
}

export default withRouter(Template)