/**
 * app.jsx file
 */
import React from "react";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import ScrollUpButton from "react-scroll-up-button";
import {Nav, Navbar} from 'react-bootstrap';

import Header from '../header.jsx';
import Footer from '../footer.jsx';
import ApplyTotalDetails from './applyTotalDetails.js';
import {fetchTotalApplicants} from '../../actions';

class ApplyTotal extends React.Component{

	constructor(props){
		super(props);
	}

  componentDidMount(){
		document.title = "CCPD APP - Apply"
    this.props.fetchTotalApplicants();
	}

	render(){

		return(
			<div>
			<Header />

      <Navbar bg="secondary" expand="lg">
        <Navbar.Brand href="/apply">CCPD Apply Form</Navbar.Brand>
      </Navbar>

      <ApplyTotalDetails apply={this.props.apply}/>

			<Footer />
		  <ScrollUpButton ContainerClassName="ScrollUpButton__Container"/>
		  </div>
			)
	}
}

function mapStateToProps(state){
		return { apply: state.apply.undefined};
}

export default connect(mapStateToProps, {fetchTotalApplicants})(withRouter(ApplyTotal));
