import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import auth from "../authentificate/auth";
import ccpd from "../../../images/ccpd.png";
import menu from "../../../images/menu.png";
import { Image } from "semantic-ui-react";
import { Component } from "react";
import Swal from "sweetalert2";
import home from "../../../images/home.png";
import dashboard from "../../../images/dashboard1.png";
import camerainspectionuser from "../../../images/camera-inspection-user.png"

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = { vl: true, cp: localStorage.getItem("currentPage") };
  }
  handleClickCard = (Card) => {
    localStorage.setItem("currentPage", Card);
    this.setState({ vl: true });
  };
  openNav() {
    this.setState({ vl: !this.state.vl });
    console.log(this.state.vl);
  }

  handleDashboardSelect() {
    Swal.fire({
      title: "Select a report",
      input: "select",
      inputOptions: {
        1: "Accrued Leave Balances",
        2: "Temporary Inactive Record",
      },
      inputPlaceholder: "Select a report",
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          switch (Number(value)) {
            case 1:
              window.open(`/leave-accruals-pay-date`,"_self");
              Swal.close();
              break;
            case 2:
              window.open(`/temparory-inactive-report`,"_self");
              Swal.close();
              break;
            default:
              resolve("You need to select a report");
          }
        });
      },
    });
  }

  render() {
    return (
      <>
        <div className="bg-blue py-2">
          <Row>
            <Col xs="10">
              <div className="pl-4">
                <Image
                  src={ccpd}
                  style={{ width: "30px", height: "30px" }}
                  roundedCircle
                />
              </div>
            </Col>

            <Col xs="2">
              <div style={{ color: "#fff", textAlign: "right" }}>
                <a onClick={() => this.openNav()} href="#">
                  {" "}
                  <Image src={menu} style={{ width: "20px", height: "20px" }} />
                </a>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="mob-view bg-blue mt-1"
          style={this.state.vl ? { display: "none" } : { display: "block" }}
        >
          <ul className="pt-4 pl-4">
            <li>
              {auth.isAuthenticated() === "true" ? (
                <a className="text-white">
                  <span>Welcome! {auth.whatUsername()}</span>
                  &nbsp;
                  <button
                    className="btn btn-sm btn-secondary input-group-btn"
                    id="logout"
                    onClick={() => {
                      auth.logout(() => this.props.history.push("/authentificate"));
                    }}
                  >
                    {/* history.location.pathname */}
                    Sign out
                  </button>
                </a>
              ) : (
                <div> </div>
              )}
            </li>
            <li key={2}>
                <Link to="/hr">
                  <Image src={home} roundedCircle className="resize" />
                  &nbsp;&nbsp;
                  <span className={this.state.v ? "sidebar-text" : ""}>
                    Employee Profile
                  </span>
                </Link>
              </li>
              <li key="3">
                <Link to="/dashboard-reports" >
                  <Image src={dashboard} roundedCircle className="resize" />
                  &nbsp;&nbsp;
                  <span className={this.state.v ? "sidebar-text" : ""}>
                    Dashboard
                  </span>
                </Link>
              </li>
              <li key="3">
                <Link to="/camera-inspection-survey" >
                  <Image src={camerainspectionuser} roundedCircle className="resize" />
                  &nbsp;&nbsp;
                  <span className={this.state.v ? "sidebar-text" : ""}>
                    Camera Inspection Survey
                  </span>
                </Link>
              </li>
          </ul>
        </div>
      </>
    );
  }
}

export default withRouter(TopBar);
