import axios from "axios";
import { ROOT_URL } from ".";
export const FETCH_CATEGORIES = "fetch_categories";
export const FETCH_LOCATION = "fetch_location";
export const FETCH_QUESTIONS_BY_CATID = "fetch_questions_by_catId";
export const FETCH_QUESTIONS_BY_EMPID = "fetch_question_by_empid";
export const FETCH_CATEGORIES_FROM_SURVEY = "fetch_category_from_survey";
export const FETCH_LOCATION_FROM_SURVEY = "fetch_location_from_survey";
export const FETCH_SURVEY_DATE_LIST = "fetch_sruvey_date_list";
export function fetchCategories() {
  const request = axios.get(`${ROOT_URL}/camera-inspection/category-dropdown`);
  return {
    type: FETCH_CATEGORIES,
    payload: request,
  };
}

export function fetchCategoriesFromSurvey() {
  const request = axios.get(
    `${ROOT_URL}/camera-inspection/categoryListFromSurvey`
  );
  return {
    type: FETCH_CATEGORIES_FROM_SURVEY,
    payload: request,
  };
}
export async function fetchInspectionDays(locId, catId) {
  const request = await axios.get(
    `${ROOT_URL}/camera-inspection/getInspectionDays/${locId}/${catId}`
  );
  return request;
}
export function fetchLocation() {
  const request = axios.get(`${ROOT_URL}/camera-inspection/location-dropdown`);
  return {
    type: FETCH_LOCATION,
    payload: request,
  };
}
export function fetchSurveyDateList(username, locId, catId) {
  let url = `${ROOT_URL}/camera-inspection/surveyDateList/${username}`
  if(locId)
   url +=`/${locId}`
  if(catId)
   url +=`/${catId}` 
  const request = axios.get(url);
  return {
    type: FETCH_SURVEY_DATE_LIST,
    payload: request,
  };
}
export function fetchLocationFromSurvey() {
  const request = axios.get(
    `${ROOT_URL}/camera-inspection/locationListFromSurvey`
  );
  return {
    type: FETCH_LOCATION_FROM_SURVEY,
    payload: request,
  };
}
export function fetchAllQuestionsByCategory(id, EmployeeId, locationId) {
  const request = axios.get(
    `${ROOT_URL}/camera-inspection/fetchQuestionByCategoryId/${id}/${EmployeeId}/${locationId}`
  );
  return {
    type: FETCH_QUESTIONS_BY_CATID,
    payload: request,
  };
}

export async function saveSurveyData(payload) {
  const request = await axios.post(
    `${ROOT_URL}/camera-inspection/saveCameraInspectionSurveyData`,
    payload
  );
  return request;
}
export function loadSurveyDataByEmpId(id, locId = null, catId = null, date=null) {
  console.log("inside action", locId, catId, date)
  let url = `${ROOT_URL}/camera-inspection/loadCameraInspectionSurveyData/${id}`;
  if(locId && catId){
     url +=`/${date}/${locId}/${catId}`
  }
   console.log(url)
  const request = axios.get(url);
  return {
    type: FETCH_QUESTIONS_BY_EMPID,
    payload: request,
  };
}
