/**
 * index.jsx file
 * It's the access to the CADA App
 *
 */
//import es6Promise from 'es6-promise';
//es6Promise.polyfill();
//import * as OfflinePluginRuntime from 'offline-plugin/runtime';
//OfflinePluginRuntime.install();

import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from 'react-router-dom';

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import promise from "redux-promise";
import thunk from "redux-thunk";

import reducers from "./reducers";
import Routes from './components/routes.jsx';
import Bootstrap from 'bootstrap/dist/css/bootstrap.min.css';
import FontAwesome from 'font-awesome/css/font-awesome.min.css';
import 'semantic-ui-css/semantic.min.css';
import '../style/style.css';

const middlewares = [promise, thunk];

const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);
ReactDOM.render(
	<Provider store={createStoreWithMiddleware(reducers)}>
	<BrowserRouter>
	  <Routes />
	</BrowserRouter>
	</Provider>
	,document.getElementById("app")
	);
