import {
  EMPLOYEE_COMMANDER_GET_ERROR,
  EMPLOYEE_COMMANDER_GET_REQUEST,
  EMPLOYEE_COMMANDER_GET_SUCCESS,
} from "../actions/hr_commander";

const initialState = {
  selectedEmployeeCommanderLoading: false,
  selectedEmployeeCommander: null,
  selectedEmployeeCommanderErr: "",
};

const actionMap = {
  [EMPLOYEE_COMMANDER_GET_REQUEST]: (state, action) => {
    return {
      ...state,
      selectedEmployeeCommanderLoading: true,
      selectedEmployeeCommander: null,
      selectedEmployeeCommanderErr: "",
    };
  },
  [EMPLOYEE_COMMANDER_GET_SUCCESS]: (state, action) => {
    return {
      ...state,
      selectedEmployeeCommanderLoading: false,
      selectedEmployeeCommander: action.payload,
      selectedEmployeeCommanderErr: "",
    };
  },
  [EMPLOYEE_COMMANDER_GET_ERROR]: (state, action) => {
    return {
      ...state,
      selectedEmployeeCommanderLoading: false,
      selectedEmployeeCommander: null,
      selectedEmployeeCommanderErr: action.error,
    };
  },
};

export default (state = initialState, action) => {
  if (action && action.type) {
    const fn = actionMap[action.type];
    return fn ? fn(state, action) : state;
  }
  return state;
};
