import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Container, Table } from "react-bootstrap";
import "../common/sidepanel.css"
import {
  fetchInactiveStatusCount,
  fetchStaffSummaryReport,
  fetchTempInactiveStatusReport,
} from "../../actions";
import { Link } from "react-router-dom";
import Header from "../header.jsx";

export default class StaffCount extends Component {
  componentDidMount() {
    this.props.fetchTempInactiveStatusReport();
    this.props.fetchStaffSummaryReport(0);
    this.props.fetchInactiveStatusCount();
  }
  render() {
    let count = 0,
      scount = 0,
      totCount = 0,
      oc=0,
      sc = 0,
      nsc = 0;
    return (
      <>
      <Header/>
      <br/>
        <Container fluid >
          <Row>
            <Col md="6">
              {/* STAFF SUMMARY */}
              <Table bordered>
                <thead>
                  <tr>
                    <th colSpan={2} style={{textAlign:"center"}}>STAFF SUMMARY</th>
                  </tr>
                  <tr className="bg-dark-color">
                    <th >Position Title</th>
                    <th >Count</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.staffSummary &&
                    this.props.staffSummary.map((o, index) => {
                      scount = o.Grp0 !== "SWORN OFFICERS" ? scount + o.u3 : scount;
                      totCount += o.u3;
                      return (
                        <>
                          <tr
                            key={o.TTitle}
                            className={
                              index % 2 == 1 ? "bg-dark-color" : "bg-color"
                            }
                          >
                            <td>
                              {index + 1}. &nbsp;&nbsp;&nbsp;
                              <Link to={o.Grp0 === "SWORN OFFICERS"?`/dashboard-reports/staffSummaryEmployeeListing/${o.TTitle}/SWORN OFFICERS`:`/dashboard-reports/staffSummaryEmployeeListing/${o.TTitle}/NON SWORN OFFICERS`}>{o.TTitle}</Link>
                            </td>
                            <td>{o.u3}</td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
                <tfoot>
                  <tr>
                    <th>TOTAL NON-SWORN</th>
                    <th>{scount}</th>
                  </tr>
                  <tr>
                    <th>TOTAL STAFFING</th>
                    <th>{totCount}</th>
                  </tr>
                </tfoot>
              </Table>
              {/* TEMPORARY INACTIVE STATUS-LEAVE */}
              <br />
              <Table bordered>
                <thead>
                  <tr>
                    <th colSpan={3} style={{textAlign:"center"}}>TEMPORARY INACTIVE STATUS-LEAVE</th>
                  </tr>
                  <tr className="bg-dark-color">
                    <th>Title</th>
                    <th>Sworn</th>
                    <th>Non Sworn</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.tempinactiveLeave &&
                    this.props.tempinactiveLeave.map((o, index) => {
                      sc += o.u2;
                      nsc += o.u3;
                      return (
                        <>
                          <tr
                            key={o.ReasonDescription}
                            className={
                              index % 2 == 1 ? "bg-dark-color" : "bg-color"
                            }
                          >
                            <td > 
                              {index + 1}. &nbsp;&nbsp;&nbsp;
                              <Link style={{fontSize:"16px"}} to={`/dashboard-reports/tempInActiveLeaveEmployeesList/${o.ReasonDescription}`}>
                                {o.ReasonDescription}
                              </Link>
                            </td>
                            <td style={{fontSize:"16px"}}>{o.u2}</td>
                            <td style={{fontSize:"16px"}}>{o.u3}</td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
                <tfoot>
                  <tr>
                    <th>TOTAL</th>
                    <th>{sc}</th>
                    <th>{nsc}</th>
                  </tr>
                </tfoot>
              </Table>
              <Table bordered>
                <thead>
                  <tr>
                    <th colSpan={2} style={{textAlign:"center"}}>INACTIVE STATUS-OTHER</th>
                  </tr>
                  <tr className="bg-dark-color">
                    <th>Status</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.inactiveStatusCount &&
                    this.props.inactiveStatusCount.map((o, index) => {
                      oc += o.value
                      return (
                        <>
                          <tr
                            key={o.status}
                            className={
                              index % 2 == 1 ? "bg-dark-color" : "bg-color"
                            }
                          >
                            <td > 
                              {index + 1}. &nbsp;&nbsp;&nbsp;
                              <Link style={{fontSize:"16px"}} to={`/temparory-inactive-report/${o.status}`}>
                                {o.status}
                              </Link>
                            </td>
                            <td style={{fontSize:"16px"}}>{o.value}</td>
                        
                          </tr>
                        </>
                      );
                    })}
                </tbody>
                <tfoot>
                  <tr>
                    <th>TOTAL</th>
                    <th>{oc}</th>
                  </tr>
                </tfoot>
              </Table>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  const { dashboardReports } = state;
  return {
    tempinactiveLeave: dashboardReports.tempinactiveLeave,
    staffSummary: dashboardReports.staffSummary,
    inactiveStatusCount: dashboardReports.inactiveStatusCount
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTempInactiveStatusReport: () =>
      dispatch(fetchTempInactiveStatusReport()),
    fetchStaffSummaryReport: (grp) => dispatch(fetchStaffSummaryReport(grp)),
    fetchInactiveStatusCount:() => dispatch(fetchInactiveStatusCount())
  };
};

StaffCount = connect(mapStateToProps, mapDispatchToProps)(StaffCount);
