import { combineReducers } from "redux";
import {reducer as formReducer} from 'redux-form';

import HRReducer from "./reducer_hr";
import HRPhoto1Reducer from "./reducer_hr_photo1";
import HRPhoto2Reducer from "./reducer_hr_photo2";
import HRSupervisorReducer from "./reducer_hr_supervisor";
import HRSubordinateReducer from "./reducer_hr_subordinate";

import AuthReducer from "./reducer_auth";
import HRTitlev2Reducer from "./reducer_hr_titlev2";
import PoliciesOptionsReducer from "./reducer_policiesOptions";
import PoliciesReducer from "./reducer_policies";
import PolicyReducer from "./reducer_policy";
import ApplyReducer from "./reducer_apply";

import HRBureausReducer from "./reducer_hr_bureaus";
import HRDivisionsReducer from "./reducer_hr_divisions";
import HRUnitsReducer from "./reducer_hr_units";
import HRCommander from "./reducer_hr_commander";
import leaveAccural from "./leave_accural";
import InactiveStatus from "./inactive_status_reducer";
import HREmployeesReducer from "./reducer_hr_employees";
import dashboardReports from "./dashboardReports";
import  CategoryDropdown from "./camera_inspect.reducer";
import LocationReducer from "./camera_location.reducer"
import question_by_catIdReducer from "./question_by_catId.reducer"
import surveyReducer from "./survey.reducer";
import { SurveyDateList } from "./camera_inspect.reducer";
const rootReducer = combineReducers({
  dashboardReports,
  categoryList: CategoryDropdown,
  locationList: LocationReducer,
  hr: HRReducer,
  hrPhoto1: HRPhoto1Reducer,
  hrPhoto2: HRPhoto2Reducer,
  hrSupervisor: HRSupervisorReducer,
  hrSubordinate: HRSubordinateReducer,
  auth: AuthReducer,
  policiesOptions: PoliciesOptionsReducer,
  policies: PoliciesReducer,
  policy: PolicyReducer,
  apply: ApplyReducer,
  hrBureaus: HRBureausReducer,
  hrDivisions: HRDivisionsReducer,
  hrUnits: HRUnitsReducer,
  hrTitlev2: HRTitlev2Reducer,
  hrCommander: HRCommander,
  hrEmployees: HREmployeesReducer,
  InactiveStatus,
  leaveAccural,
  questionsByCat: question_by_catIdReducer,
  surveyData:surveyReducer,
  surveyDataList:SurveyDateList,
  form: formReducer
});

export default rootReducer;
