import React from "react";
import DatePicker from "react-datepicker";
import { STANDARD_DATE_FORMAT_1 } from "../../constants/app_constants";
import "./sidepanel.css"
const DatePickerField = (props) => {
  const {
    input,
    label,
    disabled,
    dateFormat,
    requiredIdentifier,
    meta: { touched, error },
    wrapperClassName,
    labelClassName,
    inputClassName,
    errorClassName,
    autoComplete,
    startDate,
    endDate,
    selected,
    minDate,
    onCalendarOpen,
    onCalendarClose,
  } = props;

  return (
    <div
      className={`form-group picker ${
        wrapperClassName ? wrapperClassName : ""
      }`}
    >
      {label && (
        <>
          <label
            className={`f-weight-bold ${labelClassName ? labelClassName : ""}`}
          >
            {label}
          </label>
          {requiredIdentifier && (
            <span className="font-weight-bolder text-danger">&nbsp;*</span>
          )}
        </>
      )}
      <DatePicker
        className={`form-control ${inputClassName ? inputClassName : ""} control-index`}
        selected={input.value}
        dateFormat={dateFormat ? dateFormat : STANDARD_DATE_FORMAT_1}
        disabled={disabled ? disabled : false}
        {...input}
        onChange={(value) => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        autoComplete={autoComplete ? autoComplete : `off`}
        startDate={startDate ? startDate : null}
        endDate={endDate ? endDate : null}
        minDate={minDate ? minDate : null}
        onCalendarOpen={onCalendarOpen ? onCalendarOpen : () => {}}
        onCalendarClose={onCalendarClose ? onCalendarClose : () => {}}
        
      />
      {touched && error && (
        <span className={`text-danger ${errorClassName ? errorClassName : ""}`}>
          {error}
        </span>
      )}
    </div>
  );
};

export default DatePickerField;
