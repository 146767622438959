import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Listing from "./Listing.jsx";
import StaffCount from "./StaffCount.jsx";
import StaffSummaryEmployeeListing from "./StaffSummaryEmployeeListing.jsx";
import TempInActiveLeaveEmployeesList from "./TempInActiveLeaveEmployeesList.jsx";

class Leaves extends Component {
  componentDidMount() {
    document.title = "CCPD APP - Dashboard Reports";
  }

  render() {
    const {
      match: { path },
    } = this.props;
    return (
      <>
        <Switch>
          <Route path={`${path}/Listing`} component={Listing} />
          <Route
            path={`${path}/staffSummaryEmployeeListing/:title/:grp?`}
            component={StaffSummaryEmployeeListing}
          />
          <Route
            path={`${path}/tempInActiveLeaveEmployeesList/:reason`}
            component={TempInActiveLeaveEmployeesList}
          />
           <Route
            path={`${path}/staffcount`}
            component={StaffCount}
          />
          <Redirect path={`${path}`} to={`${path}/Listing`} />
        </Switch>
      </>
    );
  }
}

export default Leaves;
