import React from "react";
import { withRouter } from "react-router-dom";
import ScrollUpButton from "react-scroll-up-button";
import { Nav, Navbar } from 'react-bootstrap';
import Header from '../header.jsx';
import Footer from '../footer.jsx';
import HRResults from './hr_results.js';
import HRResult from './hr_result.js';
import AuthButton from '../authentificate/authButton.js';
import Auth from '../authentificate/auth.js';
import { connect } from "react-redux";
import { fetchBureaus, fetchDivisions, fetchUnits } from "../../actions/index.js";

class HRIndex extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: ''
		};
	}

	componentDidMount() {
		document.title = "CCPD APP - HRMS";
		var id = this.props.match.params.id;
		if (id) {
			this.setState({ id: id });
		} else {
			this.setState({ id: '' });
		}
		this.props.fetchBureaus(true);
		this.props.fetchDivisions(true);
		this.props.fetchUnits(true);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.match.params.id !== this.props.match.params.id) {
			var id = nextProps.match.params.id;
			if (id) {
				this.setState({ id: id });
			} else {
				this.setState({ id: '' });
			}
		}
	}

	onClickBack = () => {
		this.props.history.goBack();
	}

	render() {
		var group = Auth.whatGroup();
		var permission = /HR|Captains|Chiefs|Lieutenants|RTTOIC|Sergeants|IT/.test(group);
		return (
			<div>
				{/* <AuthButton /> */}
				<Header />
				<Navbar bg="secondary" expand="lg">
					<Navbar.Brand href="/hr"><b>CCPD HR Mobile</b></Navbar.Brand>
					<Navbar />
					<Nav className="mr-auto">
						<Nav.Link href="/hr">
							<span style={{ color: 'black' }}><b><u>Home</u></b></span>
						</Nav.Link>
					</Nav>
				</Navbar>

				{permission && !this.state.id && <HRResults />}

				{permission && this.state.id &&
					<div>
						<HRResult />
						<button type="button" className="btn btn-secondary" onClick={this.onClickBack}>Back</button>
					</div>}

				{!permission &&
					<form className="row justify-content-center align-items-center h-100">
						<div className="p-1 border border-info" style={{ fontSize: 'large', color: 'red' }}>
							You Don't Have Permission to Access</div>
					</form>
				}

				<Footer />
				<ScrollUpButton ContainerClassName="ScrollUpButton__Container" />
			</div>
		)
	}
}

HRIndex = withRouter(HRIndex);

const mapDispatchToProps = (dispatch) => {
	return {
		fetchBureaus: (includeInActive) => dispatch(fetchBureaus(includeInActive)),
		fetchDivisions: (includeInActive) =>
			dispatch(fetchDivisions(includeInActive)),
		fetchUnits: (includeInActive) => dispatch(fetchUnits(includeInActive)),
	};
};

HRIndex = connect(null, mapDispatchToProps)(HRIndex);

export default HRIndex;
