import React, { Component } from "react";
import ListingFilterFormPayDate from "./ListingFilterFormPayDate.jsx";

import { TablePagination } from "@material-ui/core";
import {
  fetchEmployeesDropdown,
  fetchPayDate,
  getEmployeeAccuralById,
  LeaveAccrualsFilterWise
} from "../../actions/index";
import { change, initialize } from "redux-form";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import Details from "./Details.jsx";
import moment from "moment";
import Header from "../header.jsx";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../common/sidepanel.css"
class Listing extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      Bureau: 0,
      employeeLabel:null,
      Division: 0,
      Unit: 0,
      Employee: 0,
      data: [],
      startDate: null,
      page: 0,
      rowperpage: 20,
      total: 0,
      orderBy: 0,
      orderDirection: "desc",
    };
  }

  componentDidMount() {
    this.props.fetchEmployeesDropdown();
    this.props.fetchPayDate();
  }

  getTableColumns = () => {
    const columns = [
      {
        title: "Employee Name",
        dbColumn: "CCPD_Employees.EmployeeName",
        field: "CCPD_Employees_EmployeeName",
        dataType: "string",
        render: ({ EmployeeName }) => {
          return <span>{EmployeeName}</span>;
        },
      },
      {
        title: "Pay Date",
        dataType: "date",
        render: ({ Pay_Date }) => {
          return (
            <span>{Pay_Date && moment.parseZone(Pay_Date).format("L")}</span>
          );
        },
      },
      {
        title: "Accruals Total",
        dataType: "string",
        render: ({ Total }) => {
          return <span>{Total ? Total : 0}</span>;
        },
      },
      {
        title: "Requested Hours",
        dataType: "string",
        render: ({ Requested }) => {
          return <span>{Requested ? Requested : 0}</span>;
        },
      },
      {
        title: "Net balance",
        dataType: "string",
        render: ({ Total, Requested }) => {
          return <span>{(Total - Requested).toFixed(2)}</span>;
        },
      },
    ];
    return columns;
  };

  getHiddenTableColumns = () => {
    const columns = [];
    return columns;
  };

  handleFilterEmployeeChange = (valueObj, action) => {
    if(!valueObj){
      this.setState({ Employee: 0 , employeeLabel:{value:null, label:null}});
    }else
      this.setState({ Employee: valueObj.value, employeeLabel:{value: valueObj.value, label: valueObj.label} });
  };

  tableTitle = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <i
          className="fa fa-arrow-left btn"
          onClick={() => this.props.history.goBack()}
        ></i>
        <h5 style={{ textTransform: "capitalize", marginBottom:"22px" }}>Accrued Leave Balances</h5>
      </div>
    );
  };

  loadPreviousPage = (data) => {
    const page = this.state.page - 1;

    this.setState({ page }, () => {
      console.log(page);
      this.tableRef.current.onQueryChange();
    });
  };
  loadNextPage = (data) => {
    // console.log(this.state.data.length)
    const page = this.state.page + 1;
    console.log(page);
    this.setState({ page }, () => {
      this.tableRef.current.onQueryChange();
    });
  };

  handleSubmit = (data) => {
    this.setState({ page: 0 });
    this.setState({
      startDate:
        data?.startDate && moment(data?.startDate).format("YYYY-MM-DD"),
    });
    this.setState({
      Employee: data?.Employee?.value,
    });
    this.tableRef.current.onQueryChange();
  };

  render() {
    const { data, employees } = this.props;

    const employeesOptions =
      employees &&
      employees.map((item) => ({
        ...item,
        value: item.value,
        label:
          item.label.indexOf("[]") > 0
            ? item.label?.replace("[]", "")
            : item.label,
      }));
    return (
      <>
        <Header />
        <Navbar bg="secondary" expand="lg">
          <Navbar.Brand href="/hr">
            <b>CCPD Leave Accural</b>
          </Navbar.Brand>
          <Navbar />
          <Nav className="mr-auto nav-link-wrap">
            <Link to="/hr" className="ml-2 mr-2">
              <span style={{ color: "black" }}>
                <b>
                  <u>Home</u>
                </b>
              </span>
            </Link>
          </Nav>
        </Navbar>
        <ListingFilterFormPayDate
          onSubmit={this.handleSubmit}
          employeesOptions={employeesOptions}
          handleFilterEmployeeChange={this.handleFilterEmployeeChange}
          startDate={this.state.startDate}
          payDateData={this.props.payDateData}
          employee = {this.state.employeeLabel}
        />

        <MaterialTable
          title={this.tableTitle()}
          tableRef={this.tableRef}
          columns={this.getTableColumns()}
          data={(query) =>
            new Promise((resolve, reject) => {
              const payload = {
                Employee: this.state.Employee,
                PayDate: this.state.startDate,
                page: this.state.page,
                rowperpage: this.state.rowperpage,
                orderBy: this.state.orderBy,
                orderDirection: this.state.orderDirection,
              };
              LeaveAccrualsFilterWise(payload)
                .then((res) => {
                  const { data, total } = res;
                  this.setState(
                    { data: data.data, total: data.total },
                    () => {}
                  );
                  resolve({
                    data: data.data,
                    page: this.state.page,
                    totalCount: data.total,
                  });
                })
                .catch((err) => {
                  reject({ data: [], page: 0, totalCount: 0 });
                });
            })
          }
          onOrderChange={(orderBy, orderDirection) => {
            this.setState({ orderBy, orderDirection });
            console.log(`Changing order to ${orderBy}:${orderDirection}`);
          }}
          options={{
            pageSize: this.state.rowperpage,
            pageSizeOptions: [20, 50, 100],
            paginationPosition: "both",
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: "#01579b",
              color: "#FFF",
            },
            detailPanelType: "single",
            // filtering: true,
            // sorting: true,
            detailPanelColumnAlignment: "left",
            initialPage: 0,
            exportButton: false,
            exportAllData: false,
            debounceInterval: 700,
            search: false,
          }}
          detailPanel={(data) => {
            return (
              <div style={{ paddingLeft: 50 }}>
                <Details EmployeeId={data.EmployeeId} />
              </div>
            );
          }}
          components={{
            Pagination: (props) => {
              return (
                <div style={{ float: "right" }}>
                  <TablePagination
                    // {...props}
                    count={this.state.total}
                    rowsPerPage={this.state.rowperpage}
                    rowsPerPageOptions={[20, 50, 100]}
                    page={this.state.page}
                    backIconButtonProps={{
                      "aria-label": "Previous Page",
                      onClick: this.loadPreviousPage,
                    }}
                    nextIconButtonProps={{
                      "aria-label": "Next Page",
                      onClick: this.loadNextPage,
                    }}
                    onChangeRowsPerPage={(value) =>
                      this.setState({ rowperpage: value.target.value }, () => {
                        this.tableRef.current.onQueryChange();
                      })
                    }
                  />
                </div>
              );
            },
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { leaveAccural } = state;
  return {
    employees: state.hrEmployees[0],
    employeeAccurals: leaveAccural.employeeAccurals,
    payDateData: leaveAccural?.payDate?.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initialize: (form, formData) => dispatch(initialize(form, formData)),
    getLeavesByDate: (payload) => dispatch(getLeavesByDate(payload)),
    fetchEmployeesDropdown: () => dispatch(fetchEmployeesDropdown()),
    fetchPayDate: () => dispatch(fetchPayDate()),
    getEmployeeAccuralById: (employeId) =>
      dispatch(getEmployeeAccuralById(employeId)),
    changeFieldValue: (form, field, value) =>
      dispatch(change(form, field, value)),
  };
};

Listing = connect(mapStateToProps, mapDispatchToProps)(Listing);

export default Listing;
