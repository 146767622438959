import axios from "axios";
import { ROOT_URL } from ".";
const HR_URL = `${ROOT_URL}/hr`;

const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const ERROR = "ERROR";
const GET = "GET";

export const EMPLOYEE_COMMANDER_GET_REQUEST = `${HR_URL}/${GET}/${REQUEST}`;
export const EMPLOYEE_COMMANDER_GET_SUCCESS = `${HR_URL}/${GET}/${SUCCESS}`;
export const EMPLOYEE_COMMANDER_GET_ERROR = `${HR_URL}/${GET}/${ERROR}`;

export function getEmployeeCommanderByEmployeeId(id) {
  return (dispatch) => {
    dispatch({
      type: EMPLOYEE_COMMANDER_GET_REQUEST,
    });
    axios
      .get(`${HR_URL}/getEmployeeCommanderByEmployeeId/${id}`)
      .then((res) => {
        dispatch({
          type: EMPLOYEE_COMMANDER_GET_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: EMPLOYEE_COMMANDER_GET_ERROR,
          error:
            err && err.message
              ? err.message
              : "Something went wrong! please try again later.",
        });
      });
  };
}
