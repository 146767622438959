import React from "react";
import { withRouter } from "react-router-dom";
import Popup from "reactjs-popup";
import MaterialTable from "material-table";

class HRResultDetailSup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      w: screen.width,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({
      w: screen.width,
    });
  }

  render() {
    const { data, open, hideModal } = this.props;
    const subLength = data.length;

    const w = this.state.w;
    let minWidth;
    if (w < 768) {
      minWidth = "310px";
    } else {
      minWidth = "620px";
    }

    const contentStyle = {
      minWidth: minWidth,
      width: "70%",
    };

    return (
      <div>
        {subLength > 0 && (
          <div>
            <Popup
              open={open}
              onClose={hideModal}
              closeOnDocumentClick
              contentStyle={contentStyle}
            >
              <div>
                <MaterialTable
                  title="Supervisor"
                  columns={[
                    {
                      title: "Employee",
                      field: "Name",
                      cellStyle: {
                        backgroundColor: "lightBlue",
                        textDecoration: "underline",
                        textDecorationColor: "blue",
                      },
                      render: ({ Name }) => {
                        if (Name && Name.indexOf("[]") > 0) {
                          return <span>{Name.replace("[]", "")}</span>;
                        }
                        return Name;
                      },
                    },
                    {
                      title: "Department",
                      field: "Title",
                      disableClick: true,
                      render: ({ Title }) => {
                        if (Title && Title.indexOf("$") < 0) {
                          return <span>{Title}</span>;
                        }
                        return null;
                      },
                    },
                    {
                      title: "Level",
                      field: "LevelName",
                      disableClick: true,
                    },
                  ]}
                  onRowClick={(event, rowData) => {
                    location.assign(`/hr/${rowData.EmployeeId}`);
                  }}
                  data={data}
                  options={{
                    pageSize: data.length < 5 ? data.length : 5,
                    pageSizeOptions: [],
                    actionsColumnIndex: -1,
                    headerStyle: {
                      backgroundColor: "#01579b",
                      color: "#FFF",
                    },
                  }}
                />
              </div>
            </Popup>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(HRResultDetailSup);
