
import _ from "lodash";
import { FETCH_SUBORDINATE} from "../actions";

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_SUBORDINATE:
      return action.payload.data;
  }
  return state;
}
