import _ from "lodash";
import {FETCH_POLICY} from "../actions";

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_POLICY:
      //console.log(action);
      return [action.payload.data, ...state];
  }
  return state;
}
