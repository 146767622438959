import _ from 'lodash'
import { FETCH_EMPLOYEE_INACTIVE_STATUS } from "../actions";

export default function (state = [], action) {
    switch (action.type) {
    case FETCH_EMPLOYEE_INACTIVE_STATUS:
      return action.payload.data
    }
    return state
  }
