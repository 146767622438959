import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Spinner } from "react-bootstrap";
//Results is used to combine app component and result component
import {
  fetchEmployee,
  fetchEmployeePhoto1,
  fetchEmployeePhoto2,
  fetchEmployeeSupervisor,
  resetEmployee,
  resetEmployeeBothPhotos,
} from "../../actions";
import HRResultDetail from "./hr_result_detail.js";
import { getEmployeeCommanderByEmployeeId } from "../../actions/hr_commander";

class HRResult extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    var id = this.props.match.params.id;
    this.props.fetchEmployee(id);
    this.props.fetchEmployeeSupervisor(id);
    this.props.fetchEmployeePhoto1(id);
    this.props.fetchEmployeePhoto2(id);
    this.props.getEmployeeCommanderByEmployeeId(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      var id = nextProps.match.params.id;
      this.props.fetchEmployee(id);
      this.props.fetchEmployeeSupervisor(id);
      this.props.fetchEmployeePhoto1(id);
      this.props.fetchEmployeePhoto2(id);
      this.props.getEmployeeCommanderByEmployeeId(id);
    }
  }

  render() {
    if (!this.props.hr[0]) {
      return (
        <div>
          <center>
            <Button variant="outline-dark" size="lg" disabled>
              <Spinner
                as="span"
                animation="grow"
                role="status"
                aria-hidden="true"
              />
              Loading...
            </Button>
          </center>
        </div>
      );
    }

    return (
      <HRResultDetail
        hr={this.props.hr[0]}
        hrPhoto1={this.props.hrPhoto1[0]}
        hrPhoto2={this.props.hrPhoto2[0]}
      />
    );
  }

  componentWillUnmount() {
    this.props.resetEmployee();
    this.props.resetEmployeeBothPhotos();
  }
}

function mapStateToProps(state) {
  return {
    hr: state.hr,
    hrPhoto1: state.hrPhoto1,
    hrPhoto2: state.hrPhoto2,
  };
}

export default connect(mapStateToProps, {
  fetchEmployee,
  fetchEmployeePhoto1,
  fetchEmployeePhoto2,
  fetchEmployeeSupervisor,
  getEmployeeCommanderByEmployeeId,
  resetEmployee,
  resetEmployeeBothPhotos,
})(withRouter(HRResult));
