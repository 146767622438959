import {
  FETCH_EMPLOYEES_DROPDOWN,
  RESET_FETCH_EMPLOYEES_DROPDOWN,
} from "../actions";

export default function (state = [], action) {
  switch (action.type) {
    case FETCH_EMPLOYEES_DROPDOWN:
      return [action.payload.data];
    case RESET_FETCH_EMPLOYEES_DROPDOWN:
      return [action.payload];
  }
  return state;
}

