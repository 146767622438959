import _ from "lodash";
import {
  FETCH_EMPLOYEE_PHOTO_TWO,
  RESET_EMPLOYEE_BOTH_PHOTOS,
} from "../actions";

export default function (state = [], action) {
  switch (action.type) {
    case FETCH_EMPLOYEE_PHOTO_TWO:
      return [action.payload.data];
    case RESET_EMPLOYEE_BOTH_PHOTOS:
      return [];
  }
  return state;
}
