/**
 * app.jsx file
 */
import React from "react";
import {connect} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import ScrollUpButton from "react-scroll-up-button";
import Header from '../header.jsx';
import Footer2 from '../footer2.jsx';
import Swal from 'sweetalert2';

import AppDefault from './app_default.js';
import List from './list.js';
import Policies from './policies.js';
import {fetchPolicy} from '../../actions';

class App extends React.Component{

	constructor(props){
		super(props);
		this.state ={
			id: ''
		}
		this.onClickBack = this.onClickBack.bind(this);
	}

	componentDidMount(){
		document.title = "CCPD APP - Policy"
		var id = this.props.match.params.id;
		if(typeof id === 'undefined'){
			this.setState({id: ''});
		}else{
			this.props.fetchPolicy(id);
			this.setState({id: id});
		}

		Swal.fire({
			html: '<div>' +
			  '<p class="font-weight-bold">DISCLAIMER</p>'+
				'<p class="disclaimer">CCPD values your privacy and will not sell your data or use it for marketing purposes. We comply with all applicable laws, policies, and regulations relating to the collection of information from visitors to our website.</p>'+
				'<p class="disclaimer">This website, like many websites, does use cookies to gather certain information purely for analytics purposes. In other words, we do not track personal information such as your name, email, or location, but we do use Google Analytics to help us look at aggregate patterns, such as the average number of visitors per day and which policy was visited most often. </p>'+
				'<p class="disclaimer">You can learn more about how Google uses data when you visit partner sites or apps by visiting this link:  <a href="https://policies.google.com/privacy?gl=US&hl=en" target="_blank" ><b>Privacy</b></a></p>'+
				'<p class="disclaimer">You can opt out from this function by using this link:  <a href="https://chrome.google.com/webstore/detail/google-analytics-opt-out/fllaojicojecljbmefodhfapmkghcbnh?hl=en" target="_blank" ><b>Opt-Out</b></a></p>'+
			  '</div>',
			width: 600,
			allowOutsideClick: false
		})
	}

	componentWillReceiveProps(nextProps){
		var id = nextProps.match.params.id;
		var currentid = this.props.match.params.id;
		if (id !== currentid) {
			if(typeof id === 'undefined'){
				this.setState({id: ''});
			}else{
				this.props.fetchPolicy(id);
				this.setState({id: id});
			}
		}

	}

	onClickBack(){
		this.props.history.goBack();
  }

	render(){

		return(
			<div>
			<Header />

			{this.state.id && <List />}

			{!this.state.id && <AppDefault />}

			{this.state.id &&
				<div>
				<Policies policy={this.props.policy} />
				<button type="button" className="btn btn-secondary" onClick={this.onClickBack}>Back</button>
				</div>
			}

			<Footer2 />
		  <ScrollUpButton ContainerClassName="ScrollUpButton__Container"/>
		  </div>
			)
	}
}

function mapStateToProps(state){
		return { policy: state.policy[0] };
}

export default connect(mapStateToProps, {fetchPolicy})(withRouter(App));
