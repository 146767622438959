import React, { Component } from "react";
import { connect } from "react-redux";
import { getEmployeeAccuralById } from "../../actions/index.js";
import moment from "moment";
class Details extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }
  componentDidMount() {
    this.props.getEmployeeAccuralById(this.props.EmployeeId);
  }
  render() {
    return (
      <div style={{ padding: 10 }}>
        <table id="detail" style={{ margin: "auto" }}>
          <tr>
            <th>Type</th>
            <th>Pay Date</th>
            <th>Previous Balance</th>
            <th>Current Balance</th>
            <th>Accrual Total</th>
            <th>Requested</th>
            <th>Net Balance</th>
          </tr>
          {this.props.employeeAccurals.length > 0 ? (
            this.props.employeeAccurals.map((o) => (
              <tr>
                <td>{o.AccrualType}</td>
                <td>
                  {o.Pay_Date && moment.parseZone(o.Pay_Date).format("L")}
                </td>
                <td>{o.PreviousBalance ? o.PreviousBalance : 0}</td>
                <td>{o.CurrentBalance ? o.CurrentBalance : 0}</td>
                <td>{o.AccuralTotal ? o.AccuralTotal : 0}</td>
                <td>{o.AccuralRequested ? o.AccuralRequested : 0}</td>
                <td>{(o.AccuralTotal - o.AccuralRequested).toFixed(2)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colspan="7" align="center">
                No Employee Accurals Found
              </td>
            </tr>
          )}
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { leaveAccural } = state;
  return {
    employeeAccurals: leaveAccural.employeeAccurals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeeAccuralById: (employeId) =>
      dispatch(getEmployeeAccuralById(employeId)),
  };
};

Details = connect(mapStateToProps, mapDispatchToProps)(Details);
export default Details;
