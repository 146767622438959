
import { FETCH_EMPLOYEE_TITLEV2 } from '../actions'

export default function (state = [], action) {
  switch (action.type) {
  case FETCH_EMPLOYEE_TITLEV2:
    return [action.payload.data]
  }
  return state
}
