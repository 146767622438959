import axios from "axios";

export const AUTH_USER = "auth_user";
export const STAFF_SUMMARY_REPORT = "staffSummaryReport";
export const TEMP_INACTIVE_REPORT = "tempInactiveReport";
export const REPORT_EMOLOYEE_LIST = "reportEmployeeListing";

export const FETCH_EMPLOYEES = "fetch_employees";
export const FETCH_EMPLOYEE = "fetch_employee";
export const RESET_EMPLOYEE_STATE = "reset_employee";
export const FETCH_EMPLOYEE_PHOTO_ONE = "fetch_employee_photo_one";
export const FETCH_EMPLOYEE_PHOTO_TWO = "fetch_employee_photo_two";
export const RESET_EMPLOYEE_BOTH_PHOTOS = "reset_employees_both_photos";
export const FETCH_SUPERVISOR = "fetch_supervisor";
export const FETCH_SUBORDINATE = "fetch_subordinate";
export const FETCH_EMPLOYEES_DROPDOWN = "fetch_employees_dropdown";
export const FETCH_EMPLOYEE_TITLEV2 = "fetch_employee_titlev2";
export const FETCH_EMPLOYEE_STATUS = "fetch_employee_status";
export const FETCH_EMPLOYEE_INACTIVE_STATUS = "fetch_employee_inactive_status";

export const FETCH_POLICIES_OPTIONS = "fetch_policies_options";
export const FETCH_POLICIES = "fetch_policies";
export const FETCH_POLICY = "fetch_policy";

export const FETCH_APPLICANTS = "fetch_applicants";
export const FETCH_TOTAL_APPLICANTS = "fetch_total_applicants";
export const FETCH_APPLICANT = "fetch_applicant";
export const CREATE_APPLICANT = "create_applicant";
//export const DELETE_APPLICANT = 'delete_applicant';

export const CREATE_CONTACT = "create_contact";
export const SEND_EMAILS = "send_emails";

const LEAVE_ACCURAL = "LEAVE_ACCURAL";
const EMPLOYEE_SHIFT_TIME = "EMPLOYEE_SHIFT_TIME";
const EMPLOYEE_ACCURAL_BY_ID = "EMPLOYEE_ACCURAL_BY_ID";

const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const ERROR = "ERROR";
const GET = "GET";
export const FETCH_BUREAUS = "fetch_bureaus";
export const FETCH_DIVISIONS = "fetch_divisions";
export const FETCH_UNITS = "fetch_units";
export const ROOT_URL =
  process.env.REACT_APP_CCPD_API_URL || "http://localhost:3000";
const LEAVE_ACCURAL_URL = `${ROOT_URL}/leave-accural`;
const LEAVE_URL = `${ROOT_URL}/leave`;

export const LEAVE_ACCURAL_URL_GET_REQUEST = `${LEAVE_ACCURAL}/${GET}/${REQUEST}`;
export const LEAVE_ACCURAL_URL_GET_SUCCESS = `${LEAVE_ACCURAL}/${GET}/${SUCCESS}`;
export const LEAVE_ACCURAL_URL_GET_ERROR = `${LEAVE_ACCURAL}/${GET}/${ERROR}`;

export const EMPLOYEE_SHIFT_TIME_GET_REQUEST = `${EMPLOYEE_SHIFT_TIME}/${GET}/${REQUEST}`;
export const EMPLOYEE_SHIFT_TIME_GET_SUCCESS = `${EMPLOYEE_SHIFT_TIME}/${GET}/${SUCCESS}`;
export const EMPLOYEE_SHIFT_TIME_GET_ERROR = `${EMPLOYEE_SHIFT_TIME}/${GET}/${ERROR}`;

export const EMPLOYEE_ACCURAL_BY_ID_GET_REQUEST = `${EMPLOYEE_ACCURAL_BY_ID}/${GET}/${REQUEST}`;
export const EMPLOYEE_ACCURAL_BY_ID_GET_SUCCESS = `${EMPLOYEE_ACCURAL_BY_ID}/${GET}/${SUCCESS}`;
export const EMPLOYEE_ACCURAL_BY_ID_GET_ERROR = `${EMPLOYEE_ACCURAL_BY_ID}/${GET}/${ERROR}`;
export const FETCH_INACTIVE_STATUS_COUNT = `fetch_inactive_count`
//HR
export function authUser(values) {
  const request = axios.post(`${ROOT_URL}/login`, values).then((response) => {
    return response.data;
  });

  return {
    type: AUTH_USER,
    payload: request,
  };
}

export function authUser2(values) {
  const request = axios
    .post(`${ROOT_URL}/test-login`, values)
    .then((response) => {
      return response.data;
    });

  return {
    type: AUTH_USER,
    payload: request,
  };
}

export function fetchEmployees() {
  const request = axios.get(`${ROOT_URL}/hr`);

  return {
    type: FETCH_EMPLOYEES,
    payload: request,
  };
}
export function fetchEmployeeTitlev2(status=1) {
  const request = axios.get(`${ROOT_URL}/hr/titlev2/${status}`);
  return {
    type: FETCH_EMPLOYEE_TITLEV2,
    payload: request,
  };
}
export function fetchEmployeesDropdown() {
  const request = axios.get(`${ROOT_URL}/hr/getEmployeeForDropdown`);
  return {
    type: FETCH_EMPLOYEES_DROPDOWN,
    payload: request,
  };
}
export function fetchInActiveStatus() {
  const request = axios.get(`${ROOT_URL}/hr/inActiveStatus`);

  return {
    type: FETCH_EMPLOYEE_INACTIVE_STATUS,
    payload: request,
  };
}
export function InactiveReports(payload) {
  return axios.post(`${ROOT_URL}/hr/inActiveEmployee`, payload);
}

export function getEmployees(payload) {
  return axios.post(`${ROOT_URL}/hr/filter`, payload);
}

export function resetEmployee() {
  return {
    type: RESET_EMPLOYEE_STATE,
  };
}
export function LeaveAccrualsFilterWise(payload) {
  return axios.post(`${LEAVE_URL}/LeaveAccrualsFilterWise`, payload);
}
export function tempInActiveLeaveEmployeesList(payload) {
  return axios.post(
    `${ROOT_URL}/dashboard-reports/tempInActiveLeaveEmployeesList`,
    payload
  );
}
export function fetchInactiveStatusCount() {
  const result =  axios.get(`${ROOT_URL}/dashboard-reports/otherInactiveStatus`);
  return {
    type: FETCH_INACTIVE_STATUS_COUNT,
    payload: result,
  };
}
export function getEmployeeAccuralById(id) {
  return (dispatch) => {
    dispatch({
      type: EMPLOYEE_ACCURAL_BY_ID_GET_REQUEST,
    });
    axios
      .get(`${LEAVE_ACCURAL_URL}/by-employee-id/${id}`)
      .then((res) => {
        dispatch({
          type: EMPLOYEE_ACCURAL_BY_ID_GET_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: EMPLOYEE_ACCURAL_BY_ID_GET_ERROR,
          error:
            err && err.message
              ? err.message
              : "Something went wrong! please try again later.",
        });
      });
  };
}
export function fetchEmployee(id) {
  const request = axios.get(`${ROOT_URL}/hr/one/${id}`);

  return {
    type: FETCH_EMPLOYEE,
    payload: request,
  };
}

export function fetchPayDate() {
  const request = axios.get(`${ROOT_URL}/leave-accural/getPayDate`);

  return {
    type: GET_PAY_DATE,
    payload: request,
  };
}
export const GET_PAY_DATE = "getPayDate";
export function fetchEmployeeSupervisor(id) {
  const request = axios.get(`${ROOT_URL}/hr/supervisorsByEmployeeId/${id}`);

  return {
    type: FETCH_SUPERVISOR,
    payload: request,
  };
}

export function fetchEmployeePhoto1(id) {
  const request = axios.get(`${ROOT_URL}/hr/photo1/${id}`);

  return {
    type: FETCH_EMPLOYEE_PHOTO_ONE,
    payload: request,
  };
}

export function fetchEmployeePhoto2(id) {
  const request = axios.get(`${ROOT_URL}/hr/photo2/${id}`);

  return {
    type: FETCH_EMPLOYEE_PHOTO_TWO,
    payload: request,
  };
}

export function resetEmployeeBothPhotos() {
  return {
    type: RESET_EMPLOYEE_BOTH_PHOTOS
  };
}

export function fetchSubordinate(name) {
  const request = axios.get(`${ROOT_URL}/hr/subordinate/${name}`);

  return {
    type: FETCH_SUBORDINATE,
    payload: request,
  };
}

//Policy
export function fetchPoliciesOptions() {
  const request = axios.get(`${ROOT_URL}/policyOptions`);

  return {
    type: FETCH_POLICIES_OPTIONS,
    payload: request,
  };
}

export function fetchPolicies() {
  const request = axios.get(`${ROOT_URL}/policy`);

  return {
    type: FETCH_POLICIES,
    payload: request,
  };
}

export function fetchPolicy(id) {
  const request = axios.get(`${ROOT_URL}/policy/${id}`);

  return {
    type: FETCH_POLICY,
    payload: request,
  };
}

//Applicants
export function fetchApplicants() {
  const request = axios.get(`${ROOT_URL}/apply`);

  return {
    type: FETCH_APPLICANTS,
    payload: request,
  };
}

//Total Applicants
export function fetchTotalApplicants() {
  const request = axios.get(`${ROOT_URL}/apply/total`);

  return {
    type: FETCH_TOTAL_APPLICANTS,
    payload: request,
  };
}

export function fetchApplicant(id) {
  const request = axios.get(`${ROOT_URL}/apply/${id}`);

  return {
    type: FETCH_APPLICANT,
    payload: request,
  };
}

export function createApplicant(values, callback) {
  const request = axios
    .post(`${ROOT_URL}/apply`, values)
    .then(() => callback());

  return {
    type: CREATE_APPLICANT,
    payload: request,
  };
}

//Contacts
export function createContact(values, callback) {
  const request = axios
    .post(`${ROOT_URL}/contact`, values)
    .then(() => callback());

  return {
    type: CREATE_CONTACT,
    payload: request,
  };
}

export function sendEmails(values, callback) {
  //console.log(data);
  const request = axios
    .post(`${ROOT_URL}/email`, values)
    .then(() => callback())
    .catch((error) => {
      // Error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //console.log(error.response);
        //console.log(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        //console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        //console.log('Error', error.message);
      }
      //console.log(error.config);
    });

  return {
    type: SEND_EMAILS,
    payload: request,
  };
}

export function fetchBureaus(includeInActive = false) {
  let uri = `${ROOT_URL}/hr/bureaus`;
  if (includeInActive) {
    uri += "/true";
  }
  const request = axios.get(uri);
  return {
    type: FETCH_BUREAUS,
    payload: request,
  };
}

export function fetchDivisions(includeInActive = false) {
  let uri = `${ROOT_URL}/hr/divisions`;
  if (includeInActive) {
    uri += "/true";
  }
  const request = axios.get(uri);
  return {
    type: FETCH_DIVISIONS,
    payload: request,
  };
}

export function fetchUnits(includeInActive = false) {
  let uri = `${ROOT_URL}/hr/units`;
  if (includeInActive) {
    uri += "/true";
  }
  const request = axios.get(uri);
  return {
    type: FETCH_UNITS,
    payload: request,
  };
}
export function fetchTempInactiveStatusReport() {
  const request = axios.get(`${ROOT_URL}/dashboard-reports/tempInactiveStatus`);
  return {
    type: TEMP_INACTIVE_REPORT,
    payload: request,
  };
}
export function fetchStaffSummaryReport(grp=0) {
  const request = axios.get(`${ROOT_URL}/dashboard-reports/staffSummary/${grp}`);
  return {
    type: STAFF_SUMMARY_REPORT,
    payload: request,
  };
}

export function fetchStaffSummaryEmployeeListing(title) {
  const request = axios.post(
    `${ROOT_URL}/dashboard-reports/staffSummaryEmployeeList`,
    title
  );
  return {
    type: REPORT_EMOLOYEE_LIST,
    payload: request,
  };
}

export function StaffSummaryEmployeeListing(payload) {
  return axios.post(
    `${ROOT_URL}/dashboard-reports/staffSummaryEmployeeList`,
    payload
  );
}
