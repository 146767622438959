import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';
import { createTextMask } from 'redux-form-input-masks';
import axios from "axios";
import {createApplicant} from '../../actions';
import states from '../../../data/states.json';
import ethnicities from '../../../data/ethnicities.json';
import counties from '../../../data/counties.json';
import reference from '../../../data/reference.json';
import is from 'is_js';

const myCustomMaskDefinitions = {
  9: {
    regExp: /[0-9]/,
  },
  8: {
    regExp: /[1-9]/,
  },
};

const phoneMask = createTextMask({
  pattern: '(999) 999-9999',
  //maskDefinitions: myCustomMaskDefinitions
});

const zipMask = createTextMask({
  pattern: '99999',
});

const initialMask = createTextMask({
  pattern: 'A',
});

class ApplyForm extends Component {
  constructor(props){
    super(props);
    this.state= {
      nj: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.handleInitialize();
  }

  handleInitialize() {
    const initData = {
      middleInitial: null,
      apartmentUnit: null,
      county: null,
      homePhone: null,
      reference: null
    }
    this.props.initialize(initData);
  }

  handleChange(event) {
    if(event.target.value == "New Jersey"){
      this.setState({nj: true})
    }else{
      this.setState({nj: false})
    }
  }

  renderRecaptchaField(field) {
    const { meta: { touched, error } } = field;
    return (
      <div align="center" className="form-group col-md-12">
        <ReCAPTCHA
          sitekey="6LdOFaEUAAAAALSdObLm8SPZVJHnC_eGX9db2EG-"
          onChange={field.input.onChange}
        />
        <div className="text-danger">{ touched ? error : '' }</div>
      </div>
    );
  }

  renderField(field) {
    const { input, label, type, textarea, required, meta: { touched, error, warning, invalid } } = field;
    const textareaType = <textarea {...input} placeholder={label}  type={type} className={`form-control ${touched && invalid ? 'has-danger' : ''}`} required={required}/>;
    const inputType = <input {...input} placeholder={label}  type={type} className={`form-control ${touched && invalid ? 'has-danger' : ''}`} required={required}/>;

    return (
      <div className="form-group col-md-6">
          <label><b>{label}</b></label>
          {textarea ? textareaType : inputType}
          {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    );
  }

  renderSelect(field) {
    const { input, label, type, options, required, meta: { touched, error, warning, invalid } } = field;
    return (
      <div className="form-group col-md-6">
          <label><b>{label}</b></label>
          <select className="form-control" type={type} {...input} required={required}>
          <option value="">{label}</option>
          {
            options.map(function(option) {
              return <option key={option.key}
              value={option.value}>{option.value}</option>;
            })
          }
          </select>
          {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    );
  }

  onSubmit(values) {
    //console.log(values)
    this.props.createApplicant(values, () =>{
      var title = "Thank you " + values.firstName + " " + values.lastName;
      Swal.fire({
        position: 'top-end',
        type: 'success',
        title: title,
        text: 'Your CCPD application has been saved',
        showConfirmButton: true
      }).then(function(){
        location.reload();
      });

    });

  }


  render() {
    const {handleSubmit, pristine, reset, submitting } = this.props;
    var ie = is.ie();

    return (
      <form className="d-flex"
      onSubmit={handleSubmit(this.onSubmit.bind(this))}>
      <div className="p-1 border border-info container" style={{maxWidth: '700px'}}>
      <div className="form-row" >
        <Field
          label="First Name"
          name="firstName"
          type="text"
          required="required"
          component={this.renderField}
        />
        <Field
          label="Middle Initial"
          name="middleInitial"
          type="text"
          component={this.renderField}
          {...initialMask}
        />
        <Field
          label="Last Name"
          name="lastName"
          type="text"
          required="required"
          component={this.renderField}
        />
        <Field
          label="Age"
          name="age"
          type="number"
          required="required"
          component={this.renderField}
        />
        <Field
          label="Ethnicity"
          name="ethnicity"
          type="text"
          options={ethnicities}
          required="required"
          component={this.renderSelect}
        />
        <Field
          label="Street Address"
          name="streetAddress"
          type="text"
          required="required"
          component={this.renderField}
        />
        <Field
          label="Apartment/Unit #"
          name="apartmentUnit"
          type="text"
          component={this.renderField}
        />
        <Field
          label="City"
          name="city"
          type="text"
          required="required"
          component={this.renderField}
        />
        <Field
          label="State"
          name="state"
          type="text"
          options={states}
          onChange={this.handleChange}
          required="required"
          component={this.renderSelect}
        />
        {
          this.state.nj &&
          <Field
            label="County"
            name="county"
            type="text"
            options={counties}
            required="required"
            component={this.renderSelect}
          />
        }
        <Field
          label="Zip Code"
          name="zip"
          type="text"
          required="required"
          component={this.renderField}
          {...zipMask}
          />
        <Field
          label="Mobile Phone"
          name="mobilePhone"
          type="text"
          required="required"
          component={this.renderField}
          {...phoneMask}
          />
        <Field
          label="Email"
          name="emailAddress"
          type="text"
          required="required"
          component={this.renderField}
          />
        <Field
          label="Reference"
          name="reference"
          type="text"
          options={reference}
          required="required"
          component={this.renderSelect}
          />
        {
          !ie &&
        <Field
          name="recaptcha"
          component={this.renderRecaptchaField}
        />
        }
        </div>

        <div className="text-center">
          <button type="submit" className="btn btn-primary">Submit</button>
          <button type="button" className="btn btn-danger" disabled={pristine || submitting} onClick={reset}>Clear Values</button>
        </div>
      </div>
      </form>
    );
  }
}

function validate(values) {
  const { firstName, lastName, age, ethnicity, streetAddress, city, state, county, zip, mobilePhone, emailAddress, reference, recaptcha } = values;
  const errors = {};

  // Validate the inputs from 'values'
  if (!firstName) {
    errors.firstName = "Please enter your first name";
  }else if (firstName.length > 20 ) {
    errors.firstName = "20 character limit on on First name";
  }

  if (!lastName) {
    errors.lastName = "Please enter your last name";
  }else if (lastName.length > 20 ) {
    errors.lastName = "20 character limit on on Last name";
  }

  if (!age) {
    errors.age = 'Please enter your age.';
  }else if (isNaN(Number(age))) {
    errors.age = 'Must be a number'
  }else if (Number(age) < 18) {
    errors.age = 'Sorry, you must be at least 18 years old'
  }else if (Number(age) > 99) {
    errors.age = 'Sorry, you must enter age no more than 99 years old'
  }

  if (!ethnicity) {
    errors.ethnicity = "Please enter your ethnicity";
  }

  if (!streetAddress) {
    errors.streetAddress = "Please enter your street address";
  }

  if (!city) {
    errors.city = "Please enter your city";
  }

  if (!state) {
    errors.state = "Please enter your state";
  }

  if (!county) {
    errors.county = "Please enter your county";
  }

  if (!zip) {
    errors.zip = 'Please enter your zip code.';
  }else if (!/^\d{5}$/i.test(zip)) {
    errors.zip = 'Invalid zip code, must be 5 digits'
  }

  if (!mobilePhone) {
    errors.mobilePhone = 'Please enter your mobile phone.';
  }else if (isNaN(Number(mobilePhone))) {
    errors.mobilePhone = 'Must be a number'
  }else if (!/^([0-9]{10})$/i.test(mobilePhone)) {
    errors.mobilePhone = 'Invalid phone number, must be 10 digits'
  }else if(!/^([2-9][0-8][0-9][2-9][0-9]{6})$/i.test(mobilePhone)){
    errors.mobilePhone = 'Invalid area code or central office'
  }

  if (!emailAddress) {
    errors.emailAddress = 'Please enter your email.';
  }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailAddress)) {
    errors.emailAddress = 'Invalid email address'
  }

  if (!reference) {
    errors.reference = 'Please choose the reference.';
  }

  if (!recaptcha) {
    errors.recaptcha = 'reCAPTCHA required.';
  }

  // If errors is empty, the form is fine to submit
  // If errors has *any* properties, redux form assumes form is invalid
  return errors;
}


export default reduxForm({
  validate,
  form: "applyForm"
})(connect(null, { createApplicant })(ApplyForm));
