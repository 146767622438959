
import _ from 'lodash'
import { FETCH_SUPERVISOR } from '../actions'

export default function (state = {}, action) {
  switch (action.type) {
  case FETCH_SUPERVISOR:
    return action.payload.data
  }
  return state
}
