import React from 'react';
import { Link } from 'react-router-dom';
import {withRouter} from "react-router-dom";
import ccpd from '../../images/ccpd.gif';
// The Header creates links that can be used to navigate
// between routes.
class Header extends React.Component{
	constructor(props){
    super(props);
		this.state = {
			w: screen.width
		};
		this.handleResize = this.handleResize.bind(this);
  }

	componentDidMount() {
    window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

	handleResize(){
		this.setState({
	    w: screen.width
	  });
	}

	render(){
		var w = this.state.w;
		var tablet;
		if(w>425 && w < 768){
			tablet = true;
		}else{
			tablet = false;
		}

		return(
			<header>

			<nav className="navbar justify-content-between p-0">
			<div className="images col-md-6 col-lg-8">
			<img className="embed-responsive-item pr-3" align="left" src={ccpd} alt="headerCCPD"/>
      <span className="headerFont">Be a Guardian, Save Lives!</span>
			<br/>
			<span className="headerFont"><b>SERVICE BEFORE SELF</b></span>
			</div>
			<div className="col-md-6 col-lg-4">
			{!tablet && <span className="headerFont d-flex justify-content-end"><b>Camden County Police Department</b></span>}
			{tablet && <span className="headerFont d-flex justify-content-start">
			<span style={{width: 90}}></span>
			<b>Camden County Police Department</b></span>}
			</div>
			</nav>

			</header>
			)
	}
}

export default withRouter(Header);
