import React from "react";
import { Route, Redirect } from "react-router-dom";
import Auth from './authentificate/auth.js';
import Template from "./common/Template.jsx";

export function ProtectedRoute({ component: Component, ...rest }) {
  //console.log(Auth.isAuthenticated());
  return (
    <Route
      {...rest}
      render={props =>
        Auth.isAuthenticated() == "true" ? (
          <Template ><Component {...props} /> </Template>
        ) : (
          <Redirect
            to={{
              pathname: "/authentificate",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}
