/**
 * login.jsx file
 */
import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import ScrollUpButton from "react-scroll-up-button";
import Header from "../header.jsx";
import Footer from "../footer.jsx";
import Swal from "sweetalert2";
import Auth from "./auth.js";
import { authUser, authUser2 } from "../../actions";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToReferrer: false,
    };
  }

  componentDidMount() {
    document.title = "CCPD APP - Login";
    if (Auth.isAuthenticated() == "true") {
      this.setState({ redirectToReferrer: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth !== this.props.auth) {
      const auth = nextProps.auth;

      if (auth.message === "You've successfully logged in.") {
        Auth.login(
          () => {
            this.setState(() => ({
              redirectToReferrer: true,
            }));
          },
          auth.username,
          auth.type,
          auth.group,
          auth.userdata
        );
      } else if (auth.message === "Invalid username/password") {
        Swal.fire({
          type: "error",
          title: "Invalid username/password",
          text: "Please enter a valid username & password",
        }).then(function () {
          location.reload();
        });
      } else {
        Swal.fire({
          type: "error",
          title: "Network error",
          text: "Unable to login. Please contact your Administrator.",
        }).then(function () {
          location.reload();
        });
      }
    }
  }

  renderField(field) {
    const {
      input,
      label,
      type,
      textarea,
      required,
      meta: { touched, error, warning, invalid },
    } = field;
    const textareaType = (
      <textarea
        {...input}
        placeholder={label}
        type={type}
        className={`form-control ${touched && invalid ? "has-danger" : ""}`}
        required={required}
      />
    );
    const inputType = (
      <input
        {...input}
        placeholder={label}
        type={type}
        className={`form-control ${touched && invalid ? "has-danger" : ""}`}
        required={required}
      />
    );

    return (
      <div className="form-group col-md-12">
        <label>
          <b>{label}</b>
        </label>
        {textarea ? textareaType : inputType}
        {touched &&
          ((error && <span className="text-danger">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    );
  }

  async onSubmit(values) {
    await this.props.authUser2(values);
    window.location.reload()
  }

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;
    const { from } = this.props.location.state || { from: { pathname: "/hr" } };
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer === true) {
      return <Redirect to={from} />;
    }

    return (
      <div>
        <Header />
        <div className="card text-center">
          <div className="card-header text-danger">
            <span>You are not logged in.</span>
            <br />
            <span>Please enter your username and password.</span>
          </div>
        </div>
        <form
          className="row justify-content-center align-items-center"
          onSubmit={handleSubmit(this.onSubmit.bind(this))}
        >
          <div className="m-3 p-3 border border-info rounded">
            <div className="form-row">
              <Field
                label="Username"
                name="username"
                type="text"
                component={this.renderField}
              />
              <Field
                label="Password"
                name="password"
                type="password"
                component={this.renderField}
              />
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-primary mr-2">
                Log In
              </button>
              <button
                type="button"
                className="btn btn-danger"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </button>
            </div>
          </div>
        </form>

        <Footer />
        <ScrollUpButton ContainerClassName="ScrollUpButton__Container" />
      </div>
    );
  }
}

function validate(values) {
  const { username, password } = values;
  const errors = {};

  // Validate the inputs from 'values'
  if (!username) {
    errors.username = "Please enter your Username";
  }

  if (!password) {
    errors.password = "Please enter your Password";
  }

  // If errors is empty, the form is fine to submit
  // If errors has *any* properties, redux form assumes form is invalid
  return errors;
}

function mapStateToProps(state) {
  return { auth: state.auth[0] };
}

export default reduxForm({
  validate,
  form: "loginForm",
})(connect(mapStateToProps, { authUser2 })(Login));
