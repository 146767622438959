import React from "react";
import {Link} from 'react-router-dom';
import {Icon,Table} from 'semantic-ui-react';
import Popup from "reactjs-popup";
import MaterialTable from 'material-table';
import {withRouter} from "react-router-dom";

class HRResultDetailRelation extends React.Component{

	constructor(props){
		super(props);
		this.state = {
			w: screen.width
    };
		this.handleResize = this.handleResize.bind(this);
	}

	componentDidMount(){
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

	handleResize(){
		this.setState({
	    w: screen.width
	  });
	}

	render(){
    var {hr} = this.props;

		var w = this.state.w;
		var mobile;
		if(w<768){
			mobile = true;
		}else{
			mobile = false;
		}

		return(
			<div>

			{!mobile &&
			<Table inverted color='teal'>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell/>
						<Table.HeaderCell width={7}>Emergency Contact 1</Table.HeaderCell>
						<Table.HeaderCell width={7}>Emergency Contact 2</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					<Table.Row>
						<Table.HeaderCell><Icon name='user'/></Table.HeaderCell>
						<Table.Cell style={{color: 'black'}}>{hr.EmgContactName1} - {hr.EmgRelationShip1}</Table.Cell>
						<Table.Cell style={{color: 'black'}}>{hr.EmgContactName2} - {hr.EmgRelationShip2}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.HeaderCell><Icon name='phone'/></Table.HeaderCell>
						<Table.Cell><a style={{color: 'black'}} ><b>{hr.EmgPhone11}</b></a></Table.Cell>
						<Table.Cell><a style={{color: 'black'}} ><b>{hr.EmgPhone21}</b></a></Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.HeaderCell><Icon name='home'/></Table.HeaderCell>
						<Table.Cell><a style={{color: 'black'}} ><b>{hr.EmgPhone12}</b></a></Table.Cell>
						<Table.Cell><a style={{color: 'black'}} ><b>{hr.EmgPhone22}</b></a></Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
		  }

			{mobile &&

		  <div>
			<Table inverted color='teal'>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell/>
						<Table.HeaderCell width={7}>Emergency Contact 1</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					<Table.Row>
						<Table.HeaderCell><Icon name='user'/></Table.HeaderCell>
						<Table.Cell style={{color: 'black'}}>{hr.EmgContactName1} - {hr.EmgRelationShip1}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.HeaderCell><Icon name='phone'/></Table.HeaderCell>
						<Table.Cell><a style={{color: 'blue'}} href={`tel:+1${hr.EmgPhone11}`}><b>{hr.EmgPhone11}</b></a></Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.HeaderCell><Icon name='home'/></Table.HeaderCell>
						<Table.Cell><a style={{color: 'blue'}} href={`tel:+1${hr.EmgPhone12}`}><b>{hr.EmgPhone12}</b></a></Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>

			<Table inverted color='teal'>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell/>
						<Table.HeaderCell width={7}>Emergency Contact 2</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					<Table.Row>
						<Table.HeaderCell><Icon name='user'/></Table.HeaderCell>
						<Table.Cell style={{color: 'black'}}>{hr.EmgContactName2} - {hr.EmgRelationShip2}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.HeaderCell><Icon name='phone'/></Table.HeaderCell>
						<Table.Cell><a style={{color: 'blue'}} href={`tel:+1${hr.EmgPhone21}`}><b>{hr.EmgPhone21}</b></a></Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.HeaderCell><Icon name='home'/></Table.HeaderCell>
						<Table.Cell><a style={{color: 'blue'}} href={`tel:+1${hr.EmgPhone22}`}><b>{hr.EmgPhone22}</b></a></Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
			</div>
		  }

      </div>
			)
	}
}

export default withRouter(HRResultDetailRelation);
