class Auth {
  constructor() {
    //this.authenticated = false;
    //this.username = '';
    //this.type = '';
  }
  getLoggedUserDetailsFromLocal(rawStringJSON = false) {
    if (rawStringJSON) {
      return localStorage.getItem("user");
    }
    const dataJson = localStorage.getItem("user");
    try {
      return JSON.parse(dataJson);
    } catch (err) {
      return null;
    }
  }
  login(cb, username, type, group, userdata) {
    //this.authenticated = true;
    localStorage.setItem("authenticated", "true");
    cb();
    //this.username = username;
    //this.type = type;
    localStorage.setItem("username", username);
    localStorage.setItem("user",JSON.stringify(userdata))
    localStorage.setItem("type", type);
    localStorage.setItem("group", group);
    localStorage.setItem("timestamp", new Date().getTime())
  }

  setDate(date) {
    localStorage.setItem("date", date);
  }

  getDate() {
    return localStorage.getItem("date");
  }

  logout(cb) {
    //this.authenticated = false;
    //localStorage.setItem("authenticated", "false");
    localStorage.clear();
    cb();
  }

  logoutAuto() {
    localStorage.clear();
    location.reload();
  }

  resetTimestamp(){
    localStorage.setItem("timestamp", new Date().getTime());
  }

  isAuthenticated() {
    //return this.authenticated;
    return localStorage.getItem("authenticated");
  }

  whatUsername() {
    return localStorage.getItem("username");
  }

  whatType() {
    return localStorage.getItem("type");
  }

  whatGroup() {
    return localStorage.getItem("group");
  }

  whatTime() {
    return localStorage.getItem("timestamp");
  }

}

export default new Auth();
