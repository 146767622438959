/**
 * routes.jsx file
 * This allows you to configure routes that show only the components
 * you specify on the page depending on the route.
 *
 */
import React from "react";
import { Route, Switch } from 'react-router-dom';

import App from './policy/app.jsx';
import Apply from './apply/apply.jsx';
import Contact from './contact/contact.jsx';
import EmailIndex from './email/email_index.jsx';
import ApplyTotal from './apply/applyTotal.js';
import Disclaimer from './disclaimer.js';
import PrivacyPolicy from './privacyPolicy.js';
import PolicyListTable from './policy/policy_list_table.js';
import HRIndex from './hr/hr_index.jsx';
import Login from './authentificate/login.jsx';
import { ProtectedRoute } from "./protected.route.jsx";
import TemparoryInactiveReport from "./inactive-report/index.jsx";
import ListingByPayDate from "./leave_accruals/ListingByPayDate.jsx";
import DashboardReports from "./dashboardReports/DashboardReports.jsx";
import CameraPMInspection from "./camera_inspection/camera-pm-list/index.js";
import Login2 from "./authentificate/login2.js";
const Routes = () => (
  <>
    <Switch>
      <Route path="/policy/:id" component={App} />
      <Route path="/policy" component={App} />
      <Route path="/policyListTable" component={PolicyListTable} />
      <Route path="/disclaimer" component={Disclaimer} />
      <Route path="/privacyPolicy" component={PrivacyPolicy} />
      <Route path="/apply/total" component={ApplyTotal} />
      <Route path="/apply" component={Apply} />
      <Route path="/contact" component={Contact} />
      <Route path="/email" component={EmailIndex} />
      <ProtectedRoute path="/camera-inspection-survey" component={CameraPMInspection} />
      
      <ProtectedRoute path="/hr/:id" component={HRIndex} />
      <ProtectedRoute path="/hr" component={HRIndex} />
      <ProtectedRoute path="/temparory-inactive-report/:status?" component={TemparoryInactiveReport} />
      <ProtectedRoute path="/leave-accruals-pay-date" component={ListingByPayDate} />
      <ProtectedRoute path="/dashboard-reports" component={DashboardReports} />
     
      <Route exact path="/authentificate" component={Login} />
      <Route exact path="/test-login" component={Login2} />
      <Route path="/" component={Apply} />
    </Switch>
    {process.env.NODE_ENV !== 'production' &&
      <div style={{
        background: 'red',
        width: '150px',
        height: '60px',
        position: 'fixed',
        top: '0',
        left: '-46px',
        transform: 'rotate(315deg)',
        fontSize: '24px',
        textAlign: 'center',
        color: '#fff',
        fontWeight: 'bold',
        lineHeight: 1,
        zIndex: 999999,
        padding: "5px 14px"
      }}>Dev Mobile</div>
    }
  </>
)

export default Routes;
