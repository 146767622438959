/**
 * app.jsx file
 */
import React from "react";
import {Link} from 'react-router-dom';
import {withRouter} from "react-router-dom";
import ScrollUpButton from "react-scroll-up-button";
import {Nav, Navbar} from 'react-bootstrap';

import Header from '../header.jsx';
import Footer from '../footer.jsx';
import ApplyForm from './applyForm.js';

class Apply extends React.Component{

	constructor(props){
		super(props);
	}

	componentDidMount() {
		document.title = "CCPD APP - Apply"
    var origin = window.location.origin;
		//console.log(origin)

		switch(origin) {
    case "https://policy.camdenpd.com":
      window.location.replace('https://policy.camdenpd.com/policy');
      break;
    case "https://seniorcontact.camdenpd.com":
      window.location.replace('https://seniorcontact.camdenpd.com/contact');
      break;
		case "https://hrmobile.camdenpd.com":
	    window.location.replace('https://hrmobile.camdenpd.com/hr');
	    break;
	  default:

		}

  }

	render(){

		return(
			<div>

			<Header />

      <Navbar bg="secondary" expand="lg">
        <Navbar.Brand href="/apply">CCPD Apply Form</Navbar.Brand>
      </Navbar>

			<ApplyForm />

			<Footer />
		  <ScrollUpButton ContainerClassName="ScrollUpButton__Container"/>
		  </div>
			)
	}
}


export default withRouter(Apply);
