
import _ from "lodash";
import { AUTH_USER } from "../actions";

export default function(state = [], action) {
  switch (action.type) {
    case AUTH_USER:
      //console.log(action);
      return [action.payload, ...state];
  }
  return state;
}
