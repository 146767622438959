import React from "react";
import { Link } from "react-router-dom";
import {
  Menu,
  Segment,
  Divider,
  Header,
  Label,
  Icon,
  Table,
  Card,
  Image,
  Grid,
  Tab,
} from "semantic-ui-react";
import Popup from "reactjs-popup";
import MaterialTable from "material-table";
import { withRouter } from "react-router-dom";

class HRResultDetailSub extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      w: screen.width,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
  }

  handleResize() {
    this.setState({
      w: screen.width,
    });
  }

  render() {
    var { hrSubordinate } = this.props;
    var subLength = hrSubordinate.length;

    var w = this.state.w;
    var minWidth;
    if (w < 768) {
      minWidth = "310px";
    } else {
      minWidth = "620px";
    }

    const contentStyle = {
      minWidth: minWidth,
      width: "80%",
      height: "90%",
      overflowX: "auto",
    };

    return (
      <div>
        {subLength > 0 && (
          <div>
            <Card>
              <Card.Content extra textAlign="center">
                <a style={{ color: "blue" }} onClick={this.openModal}>
                  <Icon name="user" />
                  <b>{subLength} Subordinates</b>
                </a>
              </Card.Content>
            </Card>

            <Popup
              open={this.state.open}
              closeOnDocumentClick
              onClose={this.closeModal}
              contentStyle={contentStyle}
            >
              <div>
                <MaterialTable
                  title="Subordinates"
                  columns={[
                    {
                      title: "Employee",
                      field: "EmployeeName",
                      cellStyle: {
                        backgroundColor: "lightBlue",
                        textDecoration: "underline",
                        textDecorationColor: "blue",
                        // color: '#FFF'
                      },
                      render: ({ EmployeeName }) => {
                        if (EmployeeName && EmployeeName.indexOf("[]") > 0) {
                          return <span>{EmployeeName.replace("[]", "")}</span>;
                        }
                        return EmployeeName;
                      },
                    },
                    {
                      title: "Bureau",
                      field: "Bureau",
                      disableClick: true,
                      render: ({ Bureau }) => {
                        if (Bureau && Bureau.indexOf("$") < 0) {
                          return <span>{Bureau}</span>;
                        }
                        return null;
                      },
                    },
                    {
                      title: "Division",
                      field: "Division",
                      disableClick: true,
                      render: ({ Division }) => {
                        if (Division && Division.indexOf("$") < 0) {
                          return <span>{Division}</span>;
                        }
                        return null;
                      },
                    },
                    {
                      title: "Unit",
                      field: "Unit",
                      disableClick: true,
                      render: ({ Unit }) => {
                        if (Unit && Unit.indexOf("$") < 0) {
                          return <span>{Unit}</span>;
                        }
                        return null;
                      },
                    },
                  ]}
                  onRowClick={(event, rowData) => {
                    location.assign(`/hr/${rowData.EmployeeId}`);
                  }}
                  data={hrSubordinate}
                  options={{
                    pageSize: 10,
                    pageSizeOptions: [10, 15, 20],
                    actionsColumnIndex: -1,
                    headerStyle: {
                      backgroundColor: "#01579b",
                      color: "#FFF",
                    },
                  }}
                />
              </div>
            </Popup>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(HRResultDetailSub);
