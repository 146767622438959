/**
 * app_default.js file
 */
import React from "react";
import Search from './search.js';
import {Nav} from 'react-bootstrap';
import ccpdDefault from '../../../images/ccpdPolicy.png';
import ccpdMobile from '../../../images/ccpdPolicyMobile.png';

class AppDefault extends React.Component{

	constructor(props){
		super(props);
		this.state = {
			w: screen.width,
			query: '',
			result: true
		};
		this.handleResize = this.handleResize.bind(this);
	}

	componentDidMount() {
    window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

	handleResize(){
		this.setState({
	    w: screen.width
	  });
	}

	handleQuery(query){
		this.setState({ query });
	}

	handleFilter(result){
		this.setState({ result });
	}

	render(){

		var w = this.state.w;
		var ccpd;
		if(w<=425){
			ccpd = ccpdMobile;
		}else{
			ccpd = ccpdDefault;
		}
		var {result, query} = this.state;

		var keywords;
		if(result && query !=''){
			keywords = 'These are the policies confirming keyword: ' +this.state.query;
		}else{
			keywords = null;
		}

		return(
			<div className="border" style={{backgroundColor: 'black'}}>

			  <div className="d-flex justify-content-end">
		      <Nav>
			    <Nav.Link href="/policyListTable">
			      <span className="headerIndex" style={{color:'white'}}><b><u>Table of Contents</u></b></span>
			      </Nav.Link>
		      </Nav>
		    </div>

				<center>

				<h1 className="text-white">Welcome to CCPD Policy Website</h1>
				<img className="embed-responsive-item" align="center" src={ccpd} alt="policyCCPD"/>
				<br/><br/>
				<div className="p-1 container-fluid" style={{maxWidth: '600px'}}>

				  <div className="row">
				  <div className="col-sm-12 text-white">{keywords}</div>
					</div>
					<div className="row">
				  <div className="col-sm-3">
					<h4><span className="badge badge-pill badge-light headerIndex">Policy Search</span></h4>
					</div>
				  <div className="col-sm-9">
					<Search onQuery={this.handleQuery.bind(this)} onFilter={this.handleFilter.bind(this)}/>
					</div>
					</div>

				</div>
				<br/>
				<div className="d-none d-md-block">
				<br/>
				</div>

				</center>
		  </div>
			)
	}
}


export default (AppDefault);
