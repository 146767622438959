import _ from "lodash";
import {FETCH_POLICIES_OPTIONS} from "../actions";

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_POLICIES_OPTIONS:
      return _.mapKeys(action.payload.data, '_id');
  }
  return state;
}
