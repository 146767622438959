/**
 * policies.js file
 */
import React from "react";
import { withRouter} from "react-router-dom";

//import { Document } from 'react-pdf';
//import 'react-pdf/dist/Page/AnnotationLayer.css';
const Highlight = ({ search = '', children = '' })=>{

	const escapeRegExp = (str = '') => (
		str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
	);

	const patt = new RegExp(`(${escapeRegExp(search)})`, 'i');
	const parts = String(children).split(patt);

	if (search) {
		return parts.map((part, index) => (
			patt.test(part) ? <mark className="bg-warning" key={index}><b>{part}</b></mark> : part
		));
	} else {
		return children;
	}
};

class Policies extends React.Component{

	constructor(props){
		super(props);
		this.state={
			policy: props.policy,
			search: ''
		}
		this.handleChange = this.handleChange.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
	}


	componentWillReceiveProps(nextProps){
    var id = nextProps.match.params.id;
		var idCurrent = this.props.match.params.id;
		if (id !== idCurrent){
			this.setState({search: ''})
		}
	}

	handleChange(event) {
    this.setState({search: event.target.value});
  }

	handleSearchTermSubmit(e) {
		event.preventDefault();
		//this.setState({search: this.textInput.value})
		find(this.state.search);
	}

	handleKeyPress(e){
		if (e.keyCode == 13) {
      e.target.blur();
		}
	}

	render(){

    if(!this.props.policy){
      return(
        <div>
        Loading...
        </div>
      )
    }

		var policyArray = this.props.policy[0].Policy.split("<br/>");

		var sample = "https://policy.camdenpd.com:8443/pdf/"+ this.props.policy[0].DocumentLocation;

    return (
        <div className="card p-3">

				<div className="card-header">
				<b>{this.props.policy[0].Subject}</b>
				<form className="form-inline float-sm-right" onSubmit={this.handleSearchTermSubmit.bind(this)}>
          <input type="text" ref={(input) => this.textInput = input}
					value={this.state.search} onChange={this.handleChange} onKeyPress={this.handleKeyPress} />
          <button type="submit" className="btn btn-secondary btn-sm">Keyword Search</button>
        </form>
				</div>
				<div className="card-body row">
				<div className="col-sm-4 border">
				<h5 className="card-title">Volume &nbsp;&nbsp;
				<span className="card-text" style={{fontWeight: 'normal'}}><Highlight search={this.state.search}>{this.props.policy[0].Volume}</Highlight></span></h5>
				</div>
				<div className="col-sm-4 border">
				<h5 className="card-title">Chapter &nbsp;&nbsp;
				<span className="card-text" style={{fontWeight: 'normal'}}><Highlight search={this.state.search}>{this.props.policy[0].Chapter}</Highlight></span></h5>
				</div>
				<div className="col-sm-4 border">
				<h5 className="card-title">Total Pages &nbsp;&nbsp;
				<span className="card-text" style={{fontWeight: 'normal'}}><Highlight search={this.state.search}>{this.props.policy[0].TotalPages}</Highlight></span></h5>
				</div>
				{
				this.props.policy[0].FAQSummary &&
				<div className="col-sm-12 border">
				<h5 className="card-title"></h5>
				<a className="btn btn-secondary" href="https://policy.camdenpd.com:8443/pdf/CCPD-BWC-Summary.pdf" target="_blank" >Synopsis</a>
				</div>
			  }
				<div className="col-sm-12 border">
				<h5 className="card-title">Purpose</h5>
				<p className="card-text"><Highlight search={this.state.search}>{this.props.policy[0].Purpose}</Highlight></p>
				</div>
				<div className="col-sm-12 border">
				<h5 className="card-title">Policy</h5>
				<div className="card-text">
				{policyArray.map((policy, index) => (
        <p key={index}><Highlight search={this.state.search}>{policy}</Highlight></p>
			  ))}
				</div>
				</div>
				<div className="col-sm-6 border">
				<h5 className="card-title">Effective Date &nbsp;&nbsp;
				<span className="card-text" style={{fontWeight: 'normal'}}><Highlight search={this.state.search}>{this.props.policy[0].EffectiveDateFormat}</Highlight></span></h5>
				</div>
				<div className="col-sm-6 border">
				<h5 className="card-title">Revision Date &nbsp;&nbsp;
				<span className="card-text" style={{fontWeight: 'normal'}}><Highlight search={this.state.search}>{this.props.policy[0].RevisionDate}</Highlight></span></h5>
				</div>
				<a className="btn btn-secondary" href={sample} target="_blank" >Full Details of {this.props.policy[0].Subject}</a>
				</div>

        </div>
    );
	}
}

export default withRouter(Policies);
