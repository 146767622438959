import React, { Component } from "react";
import { Table } from "semantic-ui-react";

class HRDepartmentInfoTable extends Component {
  render() {
    const { data, commanderInfo } = this.props;
    return (
      <>
        <Table inverted color="teal">
          <Table.Body>
            <Table.Row>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.Cell style={{ color: "black" }}>
                {data.TitleName ? data.TitleName : ""}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Bureau</Table.HeaderCell>
              <Table.Cell style={{ color: "black" }}>
                {data.BureauName && data.BureauName.indexOf("$") < 0
                  ? data.BureauName
                  : ""}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Division</Table.HeaderCell>
              <Table.Cell style={{ color: "black" }}>
                {data.DivisionName && data.DivisionName.indexOf("$") < 0
                  ? data.DivisionName
                  : ""}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Unit</Table.HeaderCell>
              <Table.Cell style={{ color: "black" }}>
                {data.UnitName && data.UnitName.indexOf("$") < 0
                  ? data.UnitName
                  : ""}
              </Table.Cell>
            </Table.Row>
            {/* <Table.Row>
              <Table.HeaderCell>Commander</Table.HeaderCell>
              <Table.Cell style={{ color: 'black' }}>{commanderInfo && commanderInfo.Name ? commanderInfo.Name : '-'}</Table.Cell>
            </Table.Row> */}
          </Table.Body>
        </Table>
      </>
    );
  }
}

export default HRDepartmentInfoTable;
